import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {Button, ButtonProps} from 'primereact/button';
interface ButtonUploadProps extends Omit<ButtonProps,"ref">  {
  onFileSelection: (files:File[])=>void
}

export default function ButtonUpload(props:ButtonUploadProps) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (typeof props.onFileSelection === 'function') {
      props.onFileSelection(files);
    }
  }, [files]);

  const onFileChange:React.ChangeEventHandler<HTMLInputElement> =( event) => {
    setFiles([...event.target.files??[]]);
  };

  const onButtonClick:MouseEventHandler<HTMLButtonElement> = (event) => {
    inputFileRef.current?.click();
  };

  return (
    <>
      <input type="file" ref={inputFileRef} onChange={onFileChange} style={{ display: 'none' }} />
      <Button {...props} onClick={onButtonClick} />
    </>
  )
}
