import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import { useTranslation } from 'react-i18next';
import api from '../../../core/api';
import LoaderOverlay from '../../shared/LoaderOverlay/LoaderOverlay';
import { Chip } from 'primereact/chip';
import { Card } from 'primereact/card';
import styles from './ProfilePage.module.scss';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column';
import  numeral from 'numeral';
import * as _ from 'lodash';
import { Chart } from 'primereact/chart';
import {
  AGE_RANGES,
  DEFAULT_SUMMARY_ERROR,
  DEFAULT_SUMMARY_SUCCESS,
  PRICE_PLATFORMS,
  PRICE_TYPES,
} from '../../../core/constants';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import config from '../../../core/config';
import { getEditingDecorator, getPastDays, isPublisherOrInfluencer, getDate, getDataAccessBadge, isAdmin } from '../../../core/utils';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import MapChart from '../../shared/MapChart/MapChart';
import { useHistory } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { confirmPopup } from 'primereact/confirmpopup';
import { useAppSelector } from "../../../redux/hooks";
import { ToastMessageType } from 'primereact/toast';
import { FollowersForCountry, Profile, ProfilePrice, ESocialPlatform, EPriceType } from '../../../monaco-client';
import { number } from 'yup/lib/locale';


const emptyPrice:ProfilePrice = {
  platform: ESocialPlatform.Other,
  type: EPriceType.Like,
  price: 0,
  units: 1,
  active: true,
};

type ProfilePageProp ={
  toast: (toast: ToastMessageType) => void
}
export default function ProfilePage({ toast }:ProfilePageProp) {
  const { id } = useParams<{id:string}>();
  const history = useHistory();
  const roles = useAppSelector(state=>state.data.roles);
  const [profile, setProfile] = useState<Profile|null>(null);
  const [profilePicker, setProfilePicker] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingPricingItem, setEditingPricingItem] = useState(null);
  const [profileStatistics, setProfileStatistics] = useState<{
    gender: {
      male: number,
      female: number,
      other: number
    },
    ageRange: number[],
  }>({
    gender: {
      male: 0,
      female: 0,
      other: 0
    },
    ageRange: [],
  });
  const user = useAppSelector(state=>state.auth.user)
  const { t } = useTranslation();
  const [loaderOverlay, setLoaderOverlay] = useState(false);
  const [followersActivityValue, setFollowersActivityValue] = useState<Map<string, number>>(new Map());
  const [reachValue, setReachValue] = useState<Map<string, number>>(new Map());
  const [engagementValue, setEngagementValue] = useState<Map<string, number>>(new Map());
  const [likesValue, setLikesValue] = useState<Map<string, number>>(new Map());
  const [commentsValue, setCommentsValue] = useState<Map<string, number>>(new Map());
  const [impressionsValue, setImpressionsValue] = useState<Map<string, number>>(new Map());
  const [socialProfileValue, setSocialProfileValue] = useState<FollowersForCountry[]>([]);
  const [followersByCity, setFollowerByCity] = useState<{ [key: string]: number; }>({});
  const [viewsValue, setViewsValue] = useState<Map<string, number>>(new Map());
  const [sharesValue, setSharesValue] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    api.dataController.getProfileById(id).then(response => {
      setProfile(response.data);
    });

  }, []);

  useEffect(() => {

    if (!profile) {
      return;
    }

    getHistoryData();
    getSocialProfile()

    const updatedStatistics = {
      ...profileStatistics
    };

    if (profile.followersByGender) {
      const { MALE, FEMALE, OTHER } = profile.followersByGender;
      const total = MALE + FEMALE + OTHER;

      if (total > 0) {
        updatedStatistics.gender.male = Math.floor(MALE * 100 / total);
        updatedStatistics.gender.female = Math.floor(FEMALE * 100 / total);
        updatedStatistics.gender.other = Math.floor(OTHER * 100 / total);
      }
    }

    if (profile.followersByAgeRange) {
      const ageRanges = AGE_RANGES.map(ar=>ar.value.toString());
      let total = 0;

      ageRanges.forEach(ageRange => {
        if (!profile.followersByAgeRange?.hasOwnProperty(ageRange)) {
          return;
        }

        total += profile.followersByAgeRange[ageRange];
      });

      ageRanges.forEach(ageRange => {
        if (!profile.followersByAgeRange?.hasOwnProperty(ageRange)) {
          return;
        }

        const value = profile.followersByAgeRange[ageRange];
        const valuePercentage = total === 0 ? 0 : Math.floor(value * 100 / total);
        updatedStatistics.ageRange?.push(valuePercentage);
      });
    }

    setProfileStatistics(updatedStatistics);
  }, [profile]);

  if (!profile) {
    return (
      <StandardLayout>
        <div className="p-p-6">
          <LoaderOverlay visible={true} />
        </div>
      </StandardLayout>
    )
  }

  const toggleEditingState = () => {
    if (editing === true) {
      api.socialController.saveProfile(profile).then(response => {
        toast({
          severity: 'success',
          summary: DEFAULT_SUMMARY_SUCCESS(t),
          detail: t('The social property has been updated with no errors')
        });

        setEditing(false);
      }).catch(error => {
        console.error(error);
        toast({
          severity: 'error',
          summary: DEFAULT_SUMMARY_ERROR(t),
          detail: t('We could not update the social property with new data')
        });
      });
    } else {
      setEditing(true);
    }
  }


  const handleImportUploadError = () => {
    toast({
      severity: 'error',
      summary: DEFAULT_SUMMARY_ERROR(t),
      detail: t('We weren\'t able to upload the data file, please contact the administrator')
    });
  }

  const handleImportUpload = () => {
    toast({
      severity: 'success',
      summary: DEFAULT_SUMMARY_SUCCESS(t),
      detail: t('Data was successfully upload and updated the profile')
    });
  }

  const handleEditChange = (key:string, value:any) => {
    setProfile({
      ...profile,
      [key]: value
    });
  };

  const handlePriceChange = (price:ProfilePrice, key:string, value:any) => {
    const prices = profile.prices || [];
    const index = prices.indexOf(price);

    if (index > -1) {
      setProfile({
        ...profile,
        prices: [
          ...prices.slice(0, index),
          {
            ...prices[index],
            [key]: value
          },
          ...prices.slice(index + 1)
        ]
      });
    }
  };

  const inputTextEditor = (props:any, field:string) => {
    return <InputText className="w-100" type="text" value={props.rowData[field]} onChange={e => handlePriceChange(props.rowData, field, e.target.value)} />;
  };

  const inputNumberEditor = (props:any, field:string) => {
    return <InputNumber className="w-100" value={props.rowData[field]} onChange={e => handlePriceChange(props.rowData, field, e.value)} />;
  };

  const inputSwitchEditor = (props:any, field:string) => {
    return <InputSwitch checked={props.rowData[field] === true} onChange={e => handlePriceChange(props.rowData, field, e.value)} />
  };

  const dropdownEditor = (props:any, field:string) => {
    let options;

    switch (field) {
      case 'platform':
        options = [...PRICE_PLATFORMS];

        break;

      case 'type':
        options = [...PRICE_TYPES];

        break;

      default:
    }

    return <Dropdown className="w-100" value={props.rowData[field]} onChange={e => handlePriceChange(props.rowData, field, e.value)} options={options} optionLabel="label" optionValue="value" />
  };

  const handleDisconnection = () => {
    profile.id && api.profileUpdateController.disconnect(profile.id).then(response => {
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('Abbiamo scollegato il profilo dalla piattaforma')
      });

      history.push('/profiles');
    });
  };

  const handleDisable = () => {
    profile.id && api.profileUpdateController.disable(profile.id).then(response => {
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('Abbiamo disabilitato il profilo dalla piattaforma')
      });

      history.push('/profiles');
    });
  };

  const handleRefreshProfile = () => {
    setLoaderOverlay(true);
    api.profileUpdateController.update(id).then(response => {
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('Refresh profile successfull')
      });
      api.dataController.getProfileById(id).then(response => {
        setProfile(response.data);
        setLoaderOverlay(false);
      }).catch( r =>{
        setLoaderOverlay(false);
      });
      history.push('/profile/' + profile.id);      
    }).catch(r=>{
      setLoaderOverlay(false);
    });
  };

  const handlePriceDelete = (rowData:ProfilePrice) => {
    const prices = profile.prices || [];
    const index = prices.indexOf(rowData);

    if (index > -1) {
      setProfile({
        ...profile,
        prices: [
          ...prices.slice(0, index),
          ...prices.slice(index + 1)
        ]
      });
    }
  }

  const pricingActionBodyTemplate = (rowData:ProfilePrice) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => handlePriceDelete(rowData)} />
      </React.Fragment>
    );
  };

  const handlePriceAdd = () => {
    setProfile({
      ...profile,
      prices: [
        ...(profile.prices??[]),
        { ...emptyPrice }
      ]
    });
  }

  const hadleBackArrow = () => {
    localStorage.setItem('isBack', 'true');
    history.push('/profiles')
  }

  const modalDisconnect:React.MouseEventHandler<HTMLButtonElement> = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure to disconnect profile?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDisconnection()
    });
  }

  const modalDisable:React.MouseEventHandler<HTMLButtonElement> = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure to disable profile?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDisable()
    });
  }

  const modalRefresh:React.MouseEventHandler<HTMLButtonElement> = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure to refresh profile?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-warning',
      accept: () => handleRefreshProfile()
    });
  }

  const toMap = (obj: { [key: string]: number;} | undefined) => {
    return new Map(Object.entries(obj ?? {}).sort((a,b)=>a[0].localeCompare(b[0])));
  }

  //GRAPHS
  const getHistoryData = () => {
    api.profileHistoryController.history(id, getDate(28, 'YYYY-MM-DD'), getDate(0, 'YYYY-MM-DD')).then(response => {
      setFollowersActivityValue(toMap(response.data.followers));
      setReachValue(toMap(response.data.reach));
      setEngagementValue(toMap(response.data.engagement));
      setLikesValue(toMap(response.data.likes));
      setViewsValue(toMap(response.data.views));
      setCommentsValue(toMap(response.data.comments));
      setImpressionsValue(toMap(response.data.impressions));
      setSharesValue(toMap(response.data.shares));
      
    });
  }

  const getSocialProfile = () => {
    profile.id &&  api.profileUpdateController.getProfile(profile.id).then(response => {
      setSocialProfileValue(response.data.followersByCountry??[]);
      setFollowerByCity(response.data.followersByCity??{});     
    });
  }

  const getLocationChartData = () => {
    let map = new Map([...Object.entries(followersByCity)].sort((a, b) => b[1] - a[1]));

    let keys = Array.from(map.keys()).slice(0,5);
    let values = Array.from(map.values()).slice(0,5);

    return {
      datasets: [
        {
          data: values,
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FF6384",
            "#ffe563",
            "#FFA726"
          ],
          label: t('Locations')
        }
      ],
      labels: keys
    }
  }

  return (
    <StandardLayout>
      <div className="p-p-6">
      <div className= {styles.changeColorArrow + " pi pi-arrow-left"} style={{'fontSize': '2em', 'color':'#66b9a7', 'cursor': 'pointer'}} onClick = {hadleBackArrow}></div>
        <div className="p-d-flex p-flex-row p-align-center">
          <div className="p-mr-3">
            <h1 className="page-title p-mr-2 p-d-flex p-flex-row p-align-center">
              <i className={`fab fa-${profile.social?.toLowerCase()} text-color-${profile.social?.toLowerCase()} p-mr-2`} />
              <strong>{profile.name}</strong>
              <Chip className="p-ml-3" label={numeral(profile.followers).format('0,0') + ' ' + t('Followers')} icon="fas fa-users" />
            </h1>
            <h3 className="p-m-0 p-p-0 p-text-normal p-opacity-50">{profile.identification}
              <span className="p-text-error p-ml-3 p-text-bold">
                <Badge severity={getDataAccessBadge(profile.dataAccess)} /></span>
            </h3>
          </div>
        </div>
        <div className="p-d-flex p-flex-row-reverse">
        {isAdmin(user) && (
            <>
              <Button label={t('Delete')} icon="fas fa-trash" className="p-ml-1 p-button-danger" onClick={modalDisable} />
              <Button label={t('Disconnect')} icon="fas fa-unlink" className="p-ml-1 p-button-danger" onClick={modalDisconnect} />
              {profile.id && <Button label={t('Refresh social')} icon="fas fa-undo" className="p-ml-1" onClick={modalRefresh} />}
              <Button label={editing ? t('Save') : t('Edit')} icon="far fa-edit" className="p-ml-1" onClick={toggleEditingState} />
              {/* <FileUpload mode="basic" name="file" url={config.apiURL + '/brief/profile/' + profile.id + '/import/daily'} chooseLabel={t('Import Data')} className="p-ml-1" accept="text/csv" onBeforeSend={handleBeforeImportUpload} onError={handleImportUploadError} onUpload={handleImportUpload} /> */}
            </>
          )}
          {isPublisherOrInfluencer(user) && (
            <>
              <Button label={t('Delete')} icon="fas fa-trash" className="p-ml-1 p-button-danger" onClick={modalDisable} />
              <Button label={t('Disconnect')} icon="fas fa-unlink" className="p-ml-1 p-button-danger" onClick={modalDisconnect} />
            </>
          )}
        </div>

        <div className="p-grid p-mt-3">
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-chart-pie" />
                {getEditingDecorator(editing, profile, handleEditChange, 'reach', <span className="p-mt-3">{numeral(profile.reach).format('0.0a')} {t('Reach')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-user" />
                {getEditingDecorator(editing, profile, handleEditChange, 'engagement', <span className="p-mt-3">{numeral((profile?.engagement??0) / 100).format('0.0%')} {t('Engagement')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-thumbs-up" />
                {getEditingDecorator(editing, profile, handleEditChange, 'likes', <span className="p-mt-3">{numeral(profile.likes).format('0.0a')} {t('Likes')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-comments" />
                {getEditingDecorator(editing, profile, handleEditChange, 'comments', <span className="p-mt-3">{numeral(profile.comments).format('0.0a')} {t('Comments')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-eye" />
                {getEditingDecorator(editing, profile, handleEditChange, 'views', <span className="p-mt-3">{numeral(profile.views).format('0.0a')} {t('Views')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-mouse-pointer" />
                {getEditingDecorator(editing, profile, handleEditChange, 'clicks', <span className="p-mt-3">{numeral(/*TODO: verify if exist or remove profile?.clicks*/0).format('0.0a')} {t('Clicks')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fas fa-icons" />
                {getEditingDecorator(editing, profile, handleEditChange, 'impressions', <span className="p-mt-3">{numeral(profile.impressions).format('0.0a')} {t('Impressions')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
              <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                <i className="fas fa-share" />
                {getEditingDecorator(editing, profile, handleEditChange, 'shares', <span className="p-mt-3">{numeral(profile.shares).format('0.0a')} {t('Shares')}</span>, 'number', {
                  inputProps: {
                    className: 'p-mt-3'
                  }
                })}
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Followers Activity')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('Below you can see a chart which shows the followers progress of the page in the last 28 days')}</small>
                <Chart type="line" style={{ width: '100%' }} className="p-mt-5" data={{
                  //labels: getPastDays(28, 'DD/MM'),
                  labels:[...followersActivityValue.keys()] ,
                  datasets: [
                    // {
                    //   label: t('Followers'),
                    //   data: getPastDays(28, 'YYYY-MM-DD').map(l=>followersActivityValue.get(l)),
                    //   fill: false,
                    //   borderColor: '#23a88a'
                    // },
                    {
                      label: t('Followers'),
                      data: [...followersActivityValue.values()],
                      fill: false,
                      borderColor: '#23a88a'
                    },
                  ]
                }} options={{
                  width: '100%'
                }} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Reach')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('Below you can see a chart which shows the reach and impressions progress of the page in the last 28 days')}</small>
                <Chart type="line" style={{ width: '100%' }} className="p-mt-5" data={{
                  labels: getPastDays(28, 'DD/MM'),
                  datasets: [
                    {
                      label: t('Reach'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>reachValue.get(l)),
                      fill: false,
                      borderColor: '#FF6384'
                    },
                    {
                      label: t('Impressions'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>impressionsValue.get(l)),
                      fill: false,
                      borderColor: '#2349a8'
                    }
                  ]
                }} options={{
                  width: '100%'
                }} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Views')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('Below you can see a chart which shows the video views progress of the page in the last 28 days')}</small>
                <Chart type="line" style={{ width: '100%' }} className="p-mt-5" data={{
                  labels: getPastDays(28, 'DD/MM'),
                  datasets: [
                    {
                      label: t('Views'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>viewsValue.get(l)),
                      fill: false,
                      borderColor: '#FFA726'
                    }
                  ]
                }} options={{
                  width: '100%'
                }} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Engagement')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('Below you can see a chart which shows the engagement % progress of the page in the last 28 days')}</small>
                <Chart type="line" style={{ width: '100%' }} className="p-mt-5" 
                data={{
                  labels: getPastDays(28, 'DD/MM'),
                  datasets: [
                    {
                      label: t('Engagement'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>engagementValue.get(l)),
                      fill: false,
                      borderColor: '#a82396'
                    }
                  ]
                }} options={{
                  width: '100%'
                }} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-top'} style={{ flexGrow: 1 }} title={t('Live Performance')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('Below you can see a chart which shows the live parameters progress of the page in the last 28 days')}</small>
                <Chart type="line" style={{ width: '100%' }} className="p-mt-5" 
                data={{
                  labels: getPastDays(28, 'DD/MM'),
                  datasets: [
                    {
                      label: t('Likes'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>likesValue.get(l)),
                      fill: false,
                      borderColor: '#23a88a'
                    },
                    {
                      label: t('Comments'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>commentsValue.get(l)),
                      fill: false,
                      borderColor: '#a82396'
                    },
                    {
                      label: t('Shares'),
                      data: getPastDays(28, 'YYYY-MM-DD').map(l=>sharesValue.get(l)),
                      fill: false,
                      borderColor: '#FF6384'
                    }
                  ]
                }} options={{
                  width: '100%'
                }} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Audience Location')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('See where your audience comes from and likes your page')}</small>
                <MapChart className="p-mt-5" socialProfile={socialProfileValue} />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Audience Gender')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('See what your audience\'s gender is and how they recognise themselves (in percentage)')}</small>
                <Chart type="pie" data={{
                  labels: [t('Male'), t('Female'), t('Other')],
                  datasets: [
                    {
                      data: [profileStatistics.gender.male, profileStatistics.gender.female, profileStatistics.gender.other],
                      backgroundColor: [
                        "#2349a8",
                        "#a82396",
                        "#23a88a"
                      ],
                      hoverBackgroundColor: [
                        "#395bb1",
                        "#b139a1",
                        "#39b196"
                      ]
                    }
                  ]
                }} className="p-mt-5" />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
            <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Audience Age Range')}>
              <div className="p-d-flex p-flex-column p-justify-center">
                <small>{t('See what your audience\'s age range is (in percentage)')}</small>
                <Chart type="bar" data={{
                  labels: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
                  datasets: [
                    {
                      label: t('Age Range'),
                      data: profileStatistics.ageRange,
                      backgroundColor: "#23a88a"
                    }
                  ]
                }} className="p-mt-5" />
              </div>
            </Card>
          </div>
          <div className="p-col-6 p-d-flex p-flex-column">
          <Card className={'p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }} title={t('Popular Locations')}>
              <small>{t('Below you can see a chart which shows from where your audience comes from')}</small>
              <div className="p-mt-auto p-mb-auto">
                <Chart type="polarArea" data={getLocationChartData()} />
              </div>
            </Card>
          </div>
          {editing && (
            <div className="p-col-12">
              <div className="p-d-flex p-flex-row p-align-center">
                <h2 className="p-text-secondary p-text-normal">{t('Gestione Prezzi')}</h2>
                {isAdmin(user) && (
                  <>
                    <Button label={t('Add Price')} icon="fas fa-plus" className="p-ml-auto" onClick={handlePriceAdd} />
                  </>
                )}
              </div>
              <Card className="p-table-card">
                <DataTable value={profile.prices || []} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                  <Column field="platform" header={t('Platform')} filter sortable editor={props => dropdownEditor(props, 'platform')} />
                  <Column field="type" header={t('Type')} filter sortable editor={props => dropdownEditor(props, 'type')} />
                  <Column field="price" header={t('Price')} filter sortable editor={props => inputNumberEditor(props, 'price')} />
                  <Column field="units" header={t('Units')} filter sortable editor={props => inputNumberEditor(props, 'units')} />
                  <Column field="active" header={t('Active')} filter sortable editor={props => inputSwitchEditor(props, 'active')} body={rowData => rowData.active === true ? t('Active') : t('Suspended')} />
                  <Column field="packageName" header={t('Package Name')} filter sortable editor={props => inputTextEditor(props, 'packageName')} />
                  <Column body={pricingActionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
                </DataTable>
              </Card>
            </div>
          )}
        </div>
      </div>
      <LoaderOverlay visible={loaderOverlay} />
    </StandardLayout>
  )
}
