import React, { ChangeEvent, useEffect, useState } from 'react';
import api from '../../../core/api';
import { Button } from 'primereact/button';
import { Campaign, Tag } from '../../../monaco-client';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';


export function Tags({setCurrentCampaign,currentCampaign}:{setCurrentCampaign:React.Dispatch<React.SetStateAction<Campaign>>,currentCampaign:Campaign}) {
    const { t } = useTranslation();
    const [tags, setTags] = useState<Tag[]>([]);
    const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
    const [tagSearch, setTagSearch] = useState("");

    useEffect(() => {
      if (tags.length === 0) {
        api.dataController.getTags().then(response => {
          setTags(response.data);
          setFilteredTags(response.data);
        });
      }
    },[ tags.length])

    const handleTagSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setTagSearch(value);
      if (value === null || value === undefined || (typeof value === 'string' && value.trim().length === 0)) {
        setFilteredTags(tags);
        return;
      }
      setFilteredTags(tags.filter(tag => tag.name?.startsWith(value.toUpperCase())));
    }
    
    const handleTagSelection = (tag: Tag) => () => {
      let newValue: Tag[];
      let oldValue: Tag[] = [...currentCampaign.tags ?? []];
      if (oldValue.includes(tag)) {
        newValue = oldValue.filter(o => o.id !== tag.id);
      } else {
        newValue = [...oldValue, tag]
      }
      let camp = {
        ...currentCampaign,
        tags: newValue
      }
      setCurrentCampaign(camp)
    }

    
  
    return (
    <>
    <div className="p-grid p-mt-6">
    <div className="p-md-12 p-mb-5 p-text-center">
      <span className="p-input-icon-right">
        <InputText placeholder={t('Search')} style={{ width: '300px' }} value={tagSearch} onChange={handleTagSearch} />
        <i className="pi pi-search" />
      </span>
    </div>
    {filteredTags.map(tag => (
      <div className="p-md-3" key={tag.id}>
        <Button className={(currentCampaign.tags && currentCampaign.tags?.findIndex(t => t.id === tag.id) >= 0 ? '' : 'p-button-outlined') + ' w-100 p-button-lg'} 
        label={tag.name} onClick={handleTagSelection(tag)} />
      </div>
    ))}
  </div>
  </>
  );
  }