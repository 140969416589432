/* tslint:disable */
/* eslint-disable */
/**
 * Monaco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum EGoal {
    Likes = 'LIKES',
    Comments = 'COMMENTS',
    Views = 'VIEWS',
    Reach = 'REACH',
    Engagement = 'ENGAGEMENT',
    Clicks = 'CLICKS',
    Impressions = 'IMPRESSIONS',
    Shares = 'SHARES'
}



