import {createSlice} from '@reduxjs/toolkit';
import api from '../core/api'
import { User } from '../monaco-client';



interface AuthState{
    user:User | undefined
    token:string|undefined
}

const initialState: AuthState = { 
    user: undefined,
    token: undefined 
}


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action:{ payload: User | undefined; type: string; }) => {
      state.user = action.payload;
    },
    setToken: (state, action:{ payload: string | undefined; type: string; }) => {
      state.token = action.payload;
      sessionStorage.setItem('App.Token', action.payload??"");
    }
  }
});

export const { setToken, setUser } = authSlice.actions;



export default authSlice.reducer;
