export const stringToDate = (s?:string|null) => s ? new Date(s):undefined 
export const dateToString = (d?:Date|null|Date[]) =>{
    if(!d) return undefined;
    let ds:Date[] = []; 
    ds = ds.concat(d);
    if(ds.length > 0){
        return dateToLocalISO(ds[0]);
    }
    return undefined;
}

function dateToLocalISO(date:Date) {
    const off    = date.getTimezoneOffset()
    const absoff = Math.abs(off)
    return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
            (off > 0 ? '-' : '+') + 
            Math.floor(absoff / 60).toFixed(0).padStart(2,'0') + ':' + 
            (absoff % 60).toString().padStart(2,'0'))
}