import React from 'react';
import { Campaign, User } from '../../../monaco-client';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'primereact/progressbar';
import { getGoalStatus } from '../../../core/campaingUtils';
import _ from 'lodash';
import { SummaryResult } from "../../shared/SummaryResult/SummaryResut";
import { isAdmin, isAdvertiser } from '../../../core/utils';

type CampaignResultSummaryProp = {
  toast: (p: any) => void,
  campaign: Campaign,
  user?: User,
}


export const CampaignResultSummary = ({ toast, campaign, user }: CampaignResultSummaryProp) => {
  const { t } = useTranslation();

  

  const getPrimaryGoalStatus = () => {
    return campaign.mainGoal ? getGoalStatus(campaign, campaign.mainGoal) : 0
  }

  const getSecondaryGoalStatus = () => {
    return campaign.secondaryGoal && campaign.mainGoal ? getGoalStatus(campaign, campaign.secondaryGoal) : 0
  }
  const getGenderChartData = () => {
    const genderData = _.get(campaign, 'statistics.audience.gender', {});
    const maleData = genderData.MALE || 0;
    const femaleData = genderData.FEMALE || 0;

    return {
      labels: [t('MALE'), t('FEMALE')],
      datasets: [
        {
          data: [maleData * 100, femaleData * 100],
          backgroundColor: [
            "#36A2EB",
            "#FF6384"
          ],
          hoverBackgroundColor: [
            "#36A2EB",
            "#FF6384"
          ]
        }
      ]
    }
  }

  const getAgeChartData = () => {
    const ageData: any[] = _.get(campaign, 'statistics.audience.age', []);

    return {
      labels: ageData.map(item => {
        if (!item.to) {
          return item.from + '';
        } else if (!item.from) {
          return item.to + '';
        }

        return item.from + '-' + item.to;
      }),
      datasets: [
        {
          label: t('Audience'),
          backgroundColor: '#42A5F5',
          data: ageData.map(item => item.percentage * 100)
        }
      ]
    }
  }

  const getLocationChartData = () => {
    const locationData: any[] = _.get(campaign, 'statistics.audience.location', []);

    return {
      datasets: [
        {
          data: locationData.map(item => item.value * 100),
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          label: t('Locations')
        }
      ],
      labels: locationData.map(item => item.name)
    }
  }
  return (<>
    <SummaryResult campaign={campaign} />
    <>

      
    </>
    {(isAdmin(user) || isAdvertiser(user))  && (
                  <> 
    <div className={campaign.secondaryGoal ? 'p-col-6' : 'p-col-12'}>
      <Card title={t('Primary Goal')}>
        <ProgressBar value={getPrimaryGoalStatus()} />
      </Card>
    </div>
    </>)}
    {isAdmin(user) && (
                  <> 
    {campaign.secondaryGoal && <div className="p-col-6">
      <Card title={t('Secondary Goal')}>
        <ProgressBar value={getSecondaryGoalStatus()} />
      </Card>
    </div>}
    </>)}

    {(isAdmin(user) || isAdvertiser(user)) && (
                  <> 
    {campaign.statistics && campaign.statistics.hasOwnProperty('audience') && (
      <React.Fragment>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Gender Information')} className="content-center">
            <small>{t('Below you can see a chart which shows what gender interacts the most with the content')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="doughnut" data={getGenderChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Audience Age')} className="content-center">
            <small>{t('Below you can see a chart which shows how popular is the content between different ages')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="bar" data={getAgeChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Popular Locations')} className="content-center">
            <small>{t('Below you can see a chart which shows from where your audience comes from')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="polarArea" data={getLocationChartData()} />
            </div>
          </Card>
        </div>
      </React.Fragment>
    )}
     </>)}
  </>)
}
