//import { TreeSelect, TreeSelectChangeParams, TreeSelectSelectionKeys, TreeSelectSelectionKeysType } from 'primereact/treeselect';
//import { Tree, TreeEventNodeParams, TreeSelectionKeys, TreeSelectionKeysType, TreeSelectionParams } from 'primereact/tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AGE_RANGES, GENDERS, TARGET_LOCATION_TREE } from '../../../core/constants';
import { Campaign } from '../../../monaco-client';
import { NJTree } from "../NJTree/NJTree";
import { Card } from 'primereact/card';
// import styles from '../../shared/SummaryResult/CampaignPage.module.scss';

export function TargetAudience(props: { setCurrentCampaign: React.Dispatch<React.SetStateAction<Campaign>>, currentCampaign: Campaign }) {
  const { t } = useTranslation();
  return (<div className="p-fluid p-grid p-formgrid">

    <Card key="gender" className={`p-field p-col-4`} title={t("Gender")}>
      
      <NJTree className={'p-h-10em'} id="gender" value={[...(props.currentCampaign.gender ?? [])]} options={GENDERS(t)}
        onChange={e => {
          props.setCurrentCampaign({
            ...props.currentCampaign,
            gender: e
          });
        }} //multiple={true}
        // placeholder={t('Gender')} 
        propagateSelectionDown={false}
        propagateSelectionUp={false}
        selectionMode="checkbox"
      />
    </Card>
    {/* <div key="location" className={`p-field p-col-12`}>
        <label htmlFor="location" className="p-d-block">{t("Location")}</label>
        <MultiSelect id="location" value={[...(props.currentCampaign.geolocation ?? [])]} optionLabel="label" optionValue="value" options={TARGET_LOCATION(t)} onChange={e => {
          props.setCurrentCampaign({
            ...props.currentCampaign,
            geolocation: e.target.value
          });
        }} placeholder={t('Location')} />
  
      </div> */}

    <Card key="geoLocation" className={'p-col-4'} title={t("Location")}>
      <NJTree id="geoLocation"
        value={[...(props.currentCampaign.geolocation ?? [])]}
        options={TARGET_LOCATION_TREE(t)}
        onChange={e => {
          props.setCurrentCampaign({
            ...props.currentCampaign,
            geolocation: e
          });
        }}
        propagateSelectionDown={false}
        propagateSelectionUp={true}
        selectionMode="checkbox"
        filter ={true}
        filterMode='lenient'
      />

    </Card>


    <Card key="ageRange" className={`p-field p-col-4`} title={t("Age Range")}>
      <NJTree id="ageRange" value={[...(props.currentCampaign.ageRange ?? [])]}
        //optionLabel="label" 
        //optionValue="value" 
        options={AGE_RANGES}
        onChange={e => {
          props.setCurrentCampaign({
            ...props.currentCampaign,
            ageRange: e
          });
        }}
      //placeholder={t('ageRange')} 
        propagateSelectionDown={false}
        propagateSelectionUp={false}
        selectionMode="checkbox"
      />
     

    </Card>
  </div>);
}