import './SmartForm.scss';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ForwardedRef, forwardRef } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect, MultiSelectSelectedItemTemplateType } from 'primereact/multiselect';
import { ColorPicker } from 'primereact/colorpicker';
import { formikValuesIn } from '../../../core/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { dateToString, stringToDate } from "../../../core/dateUtil";
//import { type } from 'os';

export type SmartFormRef = {
  submitForm: () => void
}

export type SmartFormProps<Values =any> = {
  initialValues: Values,
  map?: any,
  className?: string,
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>,
  validationSchema?: any,
  onSubmit: any,
  fields: Field<Values>[],
}

export type Field<Values = any> = {
  label: string,
  key: string,
  type?: string,
  inputType?: string
  visibility?: (values: Values, field: Field<Values>) => boolean | boolean,
  multiple?: boolean,
  size?: number,
  placeholder?: string,
  options?: any,
  optionValue?: string,
  rows?: number,
  optionLabel?: string
  valueTemplate?: (v: Values) => JSX.Element
  selectedItemsTemplate?: MultiSelectSelectedItemTemplateType
  itemTemplate?: (v: Values) => JSX.Element
}

const SmartForm = forwardRef(function SmartForm(props: SmartFormProps, ref: ForwardedRef<FormikProps<any>>) {
  //const formik = useRef<FormikProps<any>>(null);
  const initialValues = formikValuesIn(props.initialValues, props.map);

  // useImperativeHandle<SmartFormRef,SmartFormRef>(ref, () => ({
  //   submitForm: () => formik?.current?.submitForm(),
  //   formik: formik.current
  // }));

  const filterFields = (field: Field, values: FormikValues) => {
    let result = true;

    if (field.visibility !== undefined && !field.visibility) {
      result = false;
    } else if (typeof field.visibility === 'function') {
      result = field.visibility(values, field);
    }

    return result;
  };

  return (
    <div className={`SmartForm ${props.className}`}>
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        validate={props.validate}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-fluid p-grid p-formgrid">
              {props.fields.filter(field => filterFields(field, values)).map(field => (
                <div key={field.key} className={`p-field p-col-${field.size || 12}`}>
                  {field.label && <label htmlFor={field.key} className="p-d-block">{field.label}{props.validationSchema && props.validationSchema.fields[field.key] && props.validationSchema.fields[field.key].spec.presence && props.validationSchema.fields[field.key].spec.presence === 'required' && '*'}</label>}
                  {!field.type && <InputText id={field.key} type={field.inputType} className="p-d-block" onChange={handleChange} onBlur={handleBlur} value={values[field.key]} placeholder={field.placeholder} />}
                  {field.type === 'password' && <Password id={field.key} value={values[field.key]} onChange={handleChange} onBlur={handleBlur} placeholder={field.placeholder} />}
                  {field.type === 'switch' && <InputSwitch checked={values[field.key]} onChange={(e) => setFieldValue(field.key, e.value, true)} />}
                  {field.type === 'list' && field.multiple !== true && <Dropdown value={values[field.key]} options={field.options} onChange={(e) =>
                    setFieldValue(field.key, e.value, true)
                  } optionValue={field.optionValue} optionLabel={field.optionLabel || 'label'}
                    itemTemplate={field.itemTemplate} valueTemplate={field.valueTemplate} />}
                  {field.type === 'list' && field.multiple === true &&
                    <MultiSelect value={values[field.key]} options={field.options} onChange={(e) => setFieldValue(field.key, e.value, true)} optionValue={field.optionValue || 'value'} optionLabel={field.optionLabel || 'label'}
                      itemTemplate={field.itemTemplate} selectedItemTemplate={field.selectedItemsTemplate} />}
                  {field.type === 'date' && <Calendar value={
                    stringToDate(values[field.key])
                  } onChange={(e) => setFieldValue(field.key, dateToString(e.value), true)} dateFormat="dd/mm/yy" touchUI  showIcon  appendTo={document.body} />}
                  {field.type === 'time' && <Calendar value={values[field.key]} onChange={(e) => setFieldValue(field.key, e.value, true)} timeOnly hourFormat="24" touchUI showIcon  appendTo={document.body} />}
                  {field.type === 'datetime' && <Calendar value={values[field.key]} onChange={(e) => setFieldValue(field.key, e.value, true)} dateFormat="dd/mm/yy" showTime showIcon  touchUI appendTo={document.body} />}
                  {field.type === 'color' && <ColorPicker value={values[field.key]} onChange={(e) => setFieldValue(field.key, e.value, true)} />}
                  {field.type === 'textarea' && <InputTextarea value={values[field.key]} onChange={(e) =>
                    setFieldValue(field.key, e.target.value, true)
                  } onBlur={(e) => setFieldValue(field.key, e.target.value, false)} style={{ resize: 'none' }} rows={field.rows} />}
                  {errors[field.key] && touched[field.key] && <small className="p-invalid p-d-block">{errors[field.key]}</small>}
                </div>
              ))}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})
// as (
//   p: SmartFormProps & { ref: Ref<SmartFormRef> }
// ) => ReactElement<any> | null;;

export default SmartForm;
