import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import { useTranslation } from 'react-i18next';
import api, { handleBeforeImportUpload } from '../../../core/api';
import LoaderOverlay from '../../shared/LoaderOverlay/LoaderOverlay';
import { CampaignHeader } from './CampaignHeader'
import { CampaignResultSummary } from './CampaignResultSummary'
import {
  DEFAULT_SUMMARY_ERROR,
  DEFAULT_SUMMARY_SUCCESS,
} from '../../../core/constants';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadUploadParams } from 'primereact/fileupload';
import { isAdmin, isPublisherOrInfluencer, validationNumberRequired } from '../../../core/utils';
import { useHistory } from 'react-router-dom';
import { ToastMessageType } from 'primereact/toast';
import { Campaign, Result } from '../../../monaco-client';
import { useAppSelector } from '../../../redux/hooks';
import { emptyCampaign, statusBodyTemplate } from '../../../core/campaingUtils';
import EditCampaignDialog from '../../shared/EditCampaign/EditCampaignDialog';
import { BriefPlanProperties } from "./BriefPlanProperties";
import { BriefPlanCost } from "./BriefPlanCost";
import { CampaignPostCard } from "./CampaignPostCard";
import { Dialog } from 'primereact/dialog';
import SmartForm from '../../shared/SmartForm/SmartForm';
import * as Yup from 'yup';
import { FormikProps } from 'formik';


type CampaignPageProps = {
  toast: (t: ToastMessageType) => void
}

export default function CampaignPage({ toast }: CampaignPageProps) {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<Campaign>(emptyCampaign);
  const [editSentiment, setEditSentiment] = useState<boolean>(false);
  const sentimentForm = useRef<FormikProps<any>>(null);
  const [editing, setEditing] = useState(false);




  const user = useAppSelector(state => state.auth.user);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const { source, options } = api.getCancelOption();
    api.briefController.getCampaign(id, options)
      .then(response => {
        setCampaign(response.data);
      });
    return () => { source.cancel('request cancelled change page before completion') }
  }, [id]);


  if (!campaign) {
    return (
      <StandardLayout>
        <div className="p-p-6">
          <LoaderOverlay visible={true} />
        </div>
      </StandardLayout>
    )
  }



  // const uploadDataFile = (ev) => {
  //   console.log("fake upload file");
  //   console.log(ev);
  // };

  // const actionBodyTemplate = (rowData:Profile) => {
  //   return (
  //     <React.Fragment>
  //       <Button icon="pi pi-edit" className="p-button-rounded p-button-outlined p-mr-2 p-button-secondary" />
  //       <Button icon="pi pi-thrash" className="p-button-rounded p-button-outlined p-button-danger" />
  //     </React.Fragment>
  //   );
  // };

  const handleImportUploadError = () => {
    toast({
      severity: 'error',
      summary: DEFAULT_SUMMARY_ERROR(t),
      detail: t('We weren\'t able to upload the data file, please contact the administrator')
    });
  }

  function renderResult(results: Result[]): React.ReactNode {
    return (<div style={{width:"300px",height:"80vh" ,overflow:"scroll"}}>
      
     {t('Data was successfully upload and updated the campaign')}
    
     {results.map(r=>(
       <div key={r.lineNumber}>
       
         <b key={r.lineNumber} > Line: {r.lineNumber} </b>
            {(r.errors?.length??0)>0 && (<>ERRORS:<ul key={1}> { r.errors?.map((r,i)=>(<li key={i}>{r}</li>))}  </ul></>)} 
            {(r.warnings?.length??0)>0 && (<>WARNINGS:<ul key={2}> { r.warnings?.map((r,i)=>(<li key={i}>{r}</li>))}  </ul></>)} 
        
        </div>
     ))}
    
    </div>)
  }
  const handleImportUpload = (e:FileUploadUploadParams) => {
    let results:Result[] = JSON.parse(e.xhr.response);
    let withError = false
    let withWarnings = false
    for(let result of results){
      if((result.errors?.length??0) > 0)
        withError = true;
      if((result.warnings?.length??0) > 0)
        withWarnings = true;
    }

    toast({
      severity: withError?'error':withWarnings?'warn':'success',
      summary: DEFAULT_SUMMARY_SUCCESS(t),
      detail: renderResult(results),
      closable:true,
      sticky:withError||withWarnings
    });

    api.briefController.getCampaign(id).then(response => {
      setCampaign(response.data);
    })
  }



  const handleEditing = (newValue: Campaign, save: boolean) => {
    if(save) setCampaign(newValue)
    save && api.briefController.updateCampaign(newValue).then((response) => {
      setCampaign(response.data);
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('The campaign has been updated with new data')
      });
    }).catch(() =>
      api.briefController.getCampaign(id).then((response) => {
        setCampaign(response.data);
      })
    )

    setEditing(false)
  };



  const handleCampaignDeletion = () => {
    campaign.id && api.briefController.deleteCampaign(campaign.id).then(() => {
      history.push('/campaigns');
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('The campaign has been successfully deleted')
      });
    }).catch(console.error);
  };
  function saveSentiment(sentiment: {
    positive:number,
    neutral:number,
    negative:number
  }) {
    handleEditing({
      ...campaign,
      statistics:{
        ...campaign.statistics,
        sentiment
      }
    },true)
    setEditSentiment(false)
  }
  return (
    <StandardLayout>
      <EditCampaignDialog editing={true} initialState={campaign} show={editing} endCallback={handleEditing} toast={toast} />
      <div className="p-p-6">
        <div className="p-d-flex p-flex-row p-align-center">
          <div className="p-mr-3">
            <h1 className="page-title p-mr-2">{t('CAMPAIGN')} <strong>{campaign.publicName}</strong></h1>
            {isAdmin(user) && <h3 className="p-m-0 p-p-0 p-text-normal p-opacity-50">{t('PRIVATE NAME')} {campaign.privateName}</h3>}
          </div>
          {statusBodyTemplate(campaign,t)}
          {isAdmin(user) && (
            <>
              <Button label={editing ? t('Stop Editing') : t('Edit')} icon="far fa-edit" className="p-ml-auto" onClick={() => setEditing(true)} />
              <Button label={t('Delete Campaign')} icon="pi pi-trash" className="p-ml-1 p-button-danger" onClick={handleCampaignDeletion} />
              <FileUpload mode="basic" name="file" 
                url={campaign.id && api.campaignCsvUploadUrl(campaign.id)} 
                chooseLabel={t('Import Data')} className="p-ml-1" 
                accept="text/csv" 
                onBeforeSend={handleBeforeImportUpload} 
                onError={handleImportUploadError} 
                onUpload={handleImportUpload} 
                />
            </>
          )}
        </div>
        <div className="p-grid p-mt-3">
          <CampaignHeader toast={toast} campaign={campaign} user={user} />
          <div className=" p-col-12" style={{textAlign:"right"}}>
          <Button  icon="pi pi-pencil"  className="p-button-outlined p-button-rounded " onClick={() => setEditSentiment(true)}/>
          </div>
          <CampaignResultSummary toast={toast} campaign={campaign} user={user} />
          <CampaignPostCard toast={toast} campaign={campaign} user={user} />


          {/*} {isAdmin(user)&& (
            <ScheduledPostCard toast={toast} campaign={campaign} user={user} />
         )}*/}
          {(isAdmin(user) || isPublisherOrInfluencer(user)) && (
            <BriefPlanProperties toast={toast} campaign={campaign} user={user} setCampaign={setCampaign} />
          )}
          {isAdmin(user) && (<BriefPlanCost toast={toast} campaign={campaign} user={user} setCampaign={setCampaign} />)}
        </div>
      </div>
      
      <Dialog
        header={t('Edit Camapaign Sentiment')}
        visible={editSentiment}
        onHide={() => setEditSentiment(false)}
        style={{ width: '98vw', maxWidth: '500px' }}
        footer={(
          <div>
            <Button label={t('Cancel')} className="p-button-text p-button-secondary" onClick={() => {
              setEditSentiment(false);
            }} />
            <Button label={t('Confirm')} icon="pi pi-check" className="p-button-text" onClick={() => sentimentForm.current?.submitForm()} />
          </div>
        )}
      >
        <SmartForm
          ref ={sentimentForm}
          className="p-mt-5"
          onSubmit={(val:any)=>saveSentiment(val)}
          initialValues={{
            ...campaign.statistics.sentiment??{
              positive:0,
              neutral:0,
              negative:0
            }
            }
          }
          fields={[
            { key: 'positive', label: t('Positive Interaction') },
            { key: 'neutral', label: t('Neutral Interaction') },
            { key: 'negative', label: t('Negative Interaction') },
          ]}
          validationSchema={Yup.object().shape({
            positive: validationNumberRequired(t),
            neutral: validationNumberRequired(t),
            negative: validationNumberRequired(t),
          })}
        />
      </Dialog>

    </StandardLayout>
  )
}




