import { ERROR_PROFILE_IMAGE_URL } from '../../../core/LinkUtil';
import { Profile, ProfileDto, ScheduledPost } from '../../../monaco-client';
import styles from './FacebookPostPreview.module.scss';
type FacebookPostPreview ={
  post:ScheduledPost
}
export default function FacebookPostPreview({ post }:FacebookPostPreview) {

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.root}>
        <div className={`p-d-flex p-flex-row p-align-center p-p-3`} style={{ width: '100%' }}>
          <img src={post.profile?.profilePicture} className={styles.profilePicture} onError={(e)=>{e.currentTarget.src = ERROR_PROFILE_IMAGE_URL}} />
          <strong className={`p-ml-3`}>{post.profile?.name}</strong>
        </div>
        <div className={`p-mt-3`}>
          <img src={post.file?.url} onError={(e)=>{e.currentTarget.src = ERROR_PROFILE_IMAGE_URL}} className={styles.content} />
        </div>
        <div className={`p-mt-3 p-px-3`}>
          <img src="/facebook-preview.png" style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  )
}
