import styles from './CampaignProfilePostRequest.module.scss';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Button} from 'primereact/button';
import { PostRequest } from '../../../monaco-client';

type CampaignProfilePostRequestProps ={
  postRequest:PostRequest;
  postRequestIndex:number;
  onPostRequestSelection:(postRequest:PostRequest,index:number)=>void
}
export default function CampaignProfilePostRequest({ postRequest, postRequestIndex, onPostRequestSelection }:CampaignProfilePostRequestProps) {
  const {t} = useTranslation();

  return (
    <div className={`${styles.root} p-py-2 p-px-3 p-mt-2 p-d-flex p-flex-row p-align-center`}>
      <strong>{t('Post Request')} {postRequest.date ? ' ' + t('scheduled on') + ' ' + moment(postRequest.date).format('DD/MM/YYYY') : '#' + postRequestIndex}</strong>
      <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-ml-auto p-button-secondary" onClick={() => onPostRequestSelection(postRequest, postRequestIndex)} />
    </div>
  )
}
