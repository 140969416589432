/* tslint:disable */
/* eslint-disable */
/**
 * Monaco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Campaign } from '../model';
// @ts-ignore
import { CampaignPost } from '../model';
// @ts-ignore
import { InlineObject3 } from '../model';
// @ts-ignore
import { PostRequest } from '../model';
// @ts-ignore
import { Result } from '../model';
// @ts-ignore
import { ScheduledPost } from '../model';
/**
 * BriefControllerApi - axios parameter creator
 * @export
 */
export const BriefControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('createCampaign', 'campaign', campaign)
            const localVarPath = `/brief/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCampaign', 'id', id)
            const localVarPath = `/brief/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaign', 'id', id)
            const localVarPath = `/brief/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/brief/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorialPlan: async (campaignId: string, profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getEditorialPlan', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getEditorialPlan', 'profileId', profileId)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/editorial`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorialPlan1: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getEditorialPlan1', 'campaignId', campaignId)
            const localVarPath = `/brief/campaign/{campaignId}/editorial`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostPlanningByCampaignAndProfile: async (campaignId: string, profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getPostPlanningByCampaignAndProfile', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getPostPlanningByCampaignAndProfile', 'profileId', profileId)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/planning`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getPosts', 'campaignId', campaignId)
            const localVarPath = `/brief/campaign/{campaignId}/posts`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPosts: async (campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getScheduledPosts', 'campaignId', campaignId)
            const localVarPath = `/brief/campaign/{campaignId}/scheduled`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} type 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importData2: async (campaignId: string, type: string, contentType?: 'multipart/_*', inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('importData2', 'campaignId', campaignId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('importData2', 'type', type)
            const localVarPath = `/brief/campaign/{campaignId}/import/{type}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostRequest: async (campaignId: string, profileId: string, postRequest: PostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('savePostRequest', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('savePostRequest', 'profileId', profileId)
            // verify required parameter 'postRequest' is not null or undefined
            assertParamExists('savePostRequest', 'postRequest', postRequest)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/planning`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostRequest1: async (campaignId: string, profileId: string, postRequest: PostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('savePostRequest1', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('savePostRequest1', 'profileId', profileId)
            // verify required parameter 'postRequest' is not null or undefined
            assertParamExists('savePostRequest1', 'postRequest', postRequest)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/planning`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostSchedule: async (campaignId: string, profileId: string, scheduledPost: ScheduledPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('savePostSchedule', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('savePostSchedule', 'profileId', profileId)
            // verify required parameter 'scheduledPost' is not null or undefined
            assertParamExists('savePostSchedule', 'scheduledPost', scheduledPost)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/schedule`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostSchedule1: async (campaignId: string, profileId: string, scheduledPost: ScheduledPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('savePostSchedule1', 'campaignId', campaignId)
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('savePostSchedule1', 'profileId', profileId)
            // verify required parameter 'scheduledPost' is not null or undefined
            assertParamExists('savePostSchedule1', 'scheduledPost', scheduledPost)
            const localVarPath = `/brief/campaign/{campaignId}/{profileId}/schedule`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)))
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('updateCampaign', 'campaign', campaign)
            const localVarPath = `/brief/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BriefControllerApi - functional programming interface
 * @export
 */
export const BriefControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BriefControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaigns(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaigns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorialPlan(campaignId: string, profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorialPlan(campaignId, profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEditorialPlan1(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEditorialPlan1(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostPlanningByCampaignAndProfile(campaignId: string, profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostPlanningByCampaignAndProfile(campaignId, profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosts(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosts(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduledPosts(campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduledPosts(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} type 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importData2(campaignId: string, type: string, contentType?: 'multipart/_*', inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Result>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importData2(campaignId, type, contentType, inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePostRequest(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePostRequest(campaignId, profileId, postRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePostRequest1(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePostRequest1(campaignId, profileId, postRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePostSchedule(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePostSchedule(campaignId, profileId, scheduledPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePostSchedule1(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePostSchedule1(campaignId, profileId, scheduledPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BriefControllerApi - factory interface
 * @export
 */
export const BriefControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BriefControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return localVarFp.createCampaign(campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(id: string, options?: any): AxiosPromise<Campaign> {
            return localVarFp.getCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns(options?: any): AxiosPromise<Array<Campaign>> {
            return localVarFp.getCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorialPlan(campaignId: string, profileId: string, options?: any): AxiosPromise<Array<ScheduledPost>> {
            return localVarFp.getEditorialPlan(campaignId, profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEditorialPlan1(campaignId: string, options?: any): AxiosPromise<Array<ScheduledPost>> {
            return localVarFp.getEditorialPlan1(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostPlanningByCampaignAndProfile(campaignId: string, profileId: string, options?: any): AxiosPromise<Array<PostRequest>> {
            return localVarFp.getPostPlanningByCampaignAndProfile(campaignId, profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts(campaignId: string, options?: any): AxiosPromise<Array<CampaignPost>> {
            return localVarFp.getPosts(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledPosts(campaignId: string, options?: any): AxiosPromise<Array<ScheduledPost>> {
            return localVarFp.getScheduledPosts(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} type 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importData2(campaignId: string, type: string, contentType?: 'multipart/_*', inlineObject3?: InlineObject3, options?: any): AxiosPromise<Array<Result>> {
            return localVarFp.importData2(campaignId, type, contentType, inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostRequest(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): AxiosPromise<PostRequest> {
            return localVarFp.savePostRequest(campaignId, profileId, postRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostRequest1(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): AxiosPromise<PostRequest> {
            return localVarFp.savePostRequest1(campaignId, profileId, postRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostSchedule(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): AxiosPromise<ScheduledPost> {
            return localVarFp.savePostSchedule(campaignId, profileId, scheduledPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {string} profileId 
         * @param {ScheduledPost} scheduledPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePostSchedule1(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): AxiosPromise<ScheduledPost> {
            return localVarFp.savePostSchedule1(campaignId, profileId, scheduledPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return localVarFp.updateCampaign(campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BriefControllerApi - interface
 * @export
 * @interface BriefControllerApi
 */
export interface BriefControllerApiInterface {
    /**
     * 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    createCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    deleteCampaign(id: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getCampaign(id: string, options?: any): AxiosPromise<Campaign>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getCampaigns(options?: any): AxiosPromise<Array<Campaign>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getEditorialPlan(campaignId: string, profileId: string, options?: any): AxiosPromise<Array<ScheduledPost>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getEditorialPlan1(campaignId: string, options?: any): AxiosPromise<Array<ScheduledPost>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getPostPlanningByCampaignAndProfile(campaignId: string, profileId: string, options?: any): AxiosPromise<Array<PostRequest>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getPosts(campaignId: string, options?: any): AxiosPromise<Array<CampaignPost>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    getScheduledPosts(campaignId: string, options?: any): AxiosPromise<Array<ScheduledPost>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} type 
     * @param {'multipart/_*'} [contentType] 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    importData2(campaignId: string, type: string, contentType?: 'multipart/_*', inlineObject3?: InlineObject3, options?: any): AxiosPromise<Array<Result>>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {PostRequest} postRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    savePostRequest(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): AxiosPromise<PostRequest>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {PostRequest} postRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    savePostRequest1(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): AxiosPromise<PostRequest>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {ScheduledPost} scheduledPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    savePostSchedule(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): AxiosPromise<ScheduledPost>;

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {ScheduledPost} scheduledPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    savePostSchedule1(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any): AxiosPromise<ScheduledPost>;

    /**
     * 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApiInterface
     */
    updateCampaign(campaign: Campaign, options?: any): AxiosPromise<Campaign>;

}

/**
 * BriefControllerApi - object-oriented interface
 * @export
 * @class BriefControllerApi
 * @extends {BaseAPI}
 */
export class BriefControllerApi extends BaseAPI implements BriefControllerApiInterface {
    /**
     * 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public createCampaign(campaign: Campaign, options?: any) {
        return BriefControllerApiFp(this.configuration).createCampaign(campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public deleteCampaign(id: string, options?: any) {
        return BriefControllerApiFp(this.configuration).deleteCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getCampaign(id: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getCampaigns(options?: any) {
        return BriefControllerApiFp(this.configuration).getCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getEditorialPlan(campaignId: string, profileId: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getEditorialPlan(campaignId, profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getEditorialPlan1(campaignId: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getEditorialPlan1(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getPostPlanningByCampaignAndProfile(campaignId: string, profileId: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getPostPlanningByCampaignAndProfile(campaignId, profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getPosts(campaignId: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getPosts(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public getScheduledPosts(campaignId: string, options?: any) {
        return BriefControllerApiFp(this.configuration).getScheduledPosts(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} type 
     * @param {'multipart/_*'} [contentType] 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public importData2(campaignId: string, type: string, contentType?: 'multipart/_*', inlineObject3?: InlineObject3, options?: any) {
        return BriefControllerApiFp(this.configuration).importData2(campaignId, type, contentType, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {PostRequest} postRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public savePostRequest(campaignId: string, profileId: string, postRequest: PostRequest, options?: any) {
        return BriefControllerApiFp(this.configuration).savePostRequest(campaignId, profileId, postRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {PostRequest} postRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public savePostRequest1(campaignId: string, profileId: string, postRequest: PostRequest, options?: any) {
        return BriefControllerApiFp(this.configuration).savePostRequest1(campaignId, profileId, postRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {ScheduledPost} scheduledPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public savePostSchedule(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any) {
        return BriefControllerApiFp(this.configuration).savePostSchedule(campaignId, profileId, scheduledPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {string} profileId 
     * @param {ScheduledPost} scheduledPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public savePostSchedule1(campaignId: string, profileId: string, scheduledPost: ScheduledPost, options?: any) {
        return BriefControllerApiFp(this.configuration).savePostSchedule1(campaignId, profileId, scheduledPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BriefControllerApi
     */
    public updateCampaign(campaign: Campaign, options?: any) {
        return BriefControllerApiFp(this.configuration).updateCampaign(campaign, options).then((request) => request(this.axios, this.basePath));
    }
}
