/* tslint:disable */
/* eslint-disable */
/**
 * Monaco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProfileDto } from '../model';
/**
 * ProfileUpdateControllerApi - axios parameter creator
 * @export
 */
export const ProfileUpdateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('disable', 'profileId', profileId)
            const localVarPath = `/social/profile/{profileId}/disable`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('disconnect', 'profileId', profileId)
            const localVarPath = `/social/profile/{profileId}/disconnect`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getProfile', 'profileId', profileId)
            const localVarPath = `/social/profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('update', 'profileId', profileId)
            const localVarPath = `/profiles/{profileId}/update`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllAdvancedProfiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles/advanced/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllPublicProfiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles/public/update-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileUpdateControllerApi - functional programming interface
 * @export
 */
export const ProfileUpdateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileUpdateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disable(profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disable(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnect(profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnect(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllAdvancedProfiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllAdvancedProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllPublicProfiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllPublicProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileUpdateControllerApi - factory interface
 * @export
 */
export const ProfileUpdateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileUpdateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable(profileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.disable(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect(profileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.disconnect(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(profileId: string, options?: any): AxiosPromise<ProfileDto> {
            return localVarFp.getProfile(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(profileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.update(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllAdvancedProfiles(options?: any): AxiosPromise<object> {
            return localVarFp.updateAllAdvancedProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllPublicProfiles(options?: any): AxiosPromise<object> {
            return localVarFp.updateAllPublicProfiles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileUpdateControllerApi - interface
 * @export
 * @interface ProfileUpdateControllerApi
 */
export interface ProfileUpdateControllerApiInterface {
    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    disable(profileId: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    disconnect(profileId: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    getProfile(profileId: string, options?: any): AxiosPromise<ProfileDto>;

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    update(profileId: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    updateAllAdvancedProfiles(options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApiInterface
     */
    updateAllPublicProfiles(options?: any): AxiosPromise<object>;

}

/**
 * ProfileUpdateControllerApi - object-oriented interface
 * @export
 * @class ProfileUpdateControllerApi
 * @extends {BaseAPI}
 */
export class ProfileUpdateControllerApi extends BaseAPI implements ProfileUpdateControllerApiInterface {
    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public disable(profileId: string, options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).disable(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public disconnect(profileId: string, options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).disconnect(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public getProfile(profileId: string, options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).getProfile(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public update(profileId: string, options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).update(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public updateAllAdvancedProfiles(options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).updateAllAdvancedProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileUpdateControllerApi
     */
    public updateAllPublicProfiles(options?: any) {
        return ProfileUpdateControllerApiFp(this.configuration).updateAllPublicProfiles(options).then((request) => request(this.axios, this.basePath));
    }
}
