import React, {useState} from 'react';
import styles from './StandardLayout.module.scss';
import Logo from '../../shared/Logo/Logo';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import {Link, NavLink} from 'react-router-dom';
import config from '../../../core/config';
// import {changeLanguage} from '../../../redux/settingsSlice';
import {getUserProfilePictureUrl,ERROR_PROFILE_IMAGE_URL} from'../../../core/LinkUtil'
import { /* useAppDispatch, */ useAppSelector } from '../../../redux/hooks';
import { ERole } from '../../../monaco-client';


type StandardLayoutProps = {
  children : any;
}
export default function StandardLayout({children}:StandardLayoutProps) {
  const { t } = useTranslation();
  const user = useAppSelector(state=>state.auth.user);
  // const language = useAppSelector(state=>state.settings.language);
  // const dispatch = useAppDispatch();
  const [miniAside, setMiniAside] = useState(false);
  


  const navigationItems = [
    { to: config.homepage, label: t('Dashboard'), icon: 'pi pi-home' },
    { to: '/campaigns', label: t('Campaigns'), icon: 'far fa-clipboard' },
    { to: '/profiles', label: t('Properties'), icon: 'fas fa-users' },
    { to: '/influencers', label: t('Influencers'), icon: 'fas fa-star' },
    { to: '/publishers', label: t('Publishers'), icon: 'far fa-newspaper' },
    { to: '/advertisers', label: t('Advertisers'), icon: 'fas fa-user-tie' }
  ].filter(item => {
    if (user?.roles?.findIndex(role => role.name === ERole.Admin) === -1 && ['/influencers', '/publishers', '/advertisers', '/authorisation'].indexOf(item.to) > -1) {
      return false
    }

    if ((user?.roles?.findIndex(role => role.name === ERole.Advertiser)??-1) > -1 && ['/profiles'].indexOf(item.to) > -1) {
      return false;
    }

    return true;
  });

  // const handleLanguageSelect = (selectedLanguage:string )=> {
  //   if (selectedLanguage !== language) {
  //     dispatch(changeLanguage(selectedLanguage));
  //   }
  // };

  const handleSideMenuToggle = () => {
    setMiniAside(!miniAside);
  };

  return (
    <div className={`${styles.root}`}>
      <header className={`${styles.mainHeader}`}>
        <button className={styles.toggleMenuBtn} onClick={handleSideMenuToggle}>
          <i className="pi pi-bars" />
        </button>

        <div className={styles.languagePicker + ' p-d-flex p-flex-row p-align-center p-ml-5 p-mr-5'}>
          {/*<Button className="p-button-text style-less" onClick={() => handleLanguageSelect(LANGUAGE_IT)} tooltip={t('Italian')} tooltipOptions={{ position: 'bottom' }}>
            <img src="/svg/italy.svg" className={language === LANGUAGE_IT ? styles.languageSelected : ''} />
          </Button>
          <Button className="p-button-text style-less p-ml-3" onClick={() => handleLanguageSelect(LANGUAGE_EN)} tooltip={t('English')} tooltipOptions={{ position: 'bottom' }}>
            <img src="/svg/united-kingdom.svg" className={(language === LANGUAGE_EN ? styles.languageSelected : '')} />
  </Button>*/}
        </div>

        <Logo style={{ height: '35px' }} className="p-ml-5" />

        <div className={styles.topBarActions}>
          {/*<span className="p-input-icon-right">
              <InputText placeholder={t('Search')} />
              <i className="pi pi-search" />
</span>*/}

          {/*<Button icon="pi pi-bell" className="p-button-rounded p-button-text p-ml-5 p-button-icon p-button-lg" tooltip={t('Notifications')} tooltipOptions={{position: 'bottom'}} />*/}
          {/*<Button icon="pi pi-flag" className="p-button-rounded p-button-text p-ml-2 p-button-icon p-button-lg" tooltip={t('Campaign Actions')} tooltipOptions={{position: 'bottom'}} />*/}
          {/*<Link to="/settings">*/}
          {/*  <Button icon="pi pi-cog" className="p-button-rounded p-button-text p-ml-2 p-button-icon p-button-lg" tooltip={t('Settings')} tooltipOptions={{position: 'bottom'}} />*/}
          {/*</Link>*/}
          <Link to="/logout">
            <Button icon="pi pi-sign-out" className="p-button-rounded p-button-text p-ml-2 p-button-icon p-button-lg" tooltip={t('Sign Out')} tooltipOptions={{position: 'bottom'}} />
          </Link>
        </div>

        <button className={styles.topBarProfile + ' p-ml-5 p-mr-5'}>
          <img src={getUserProfilePictureUrl(user)}
			    onError = {(e)=>{e.currentTarget.src = ERROR_PROFILE_IMAGE_URL}} alt={`${user?.firstName} ${user?.lastName}`}/>
          <span className="p-ml-3">{user && `${user.firstName} ${user.lastName}`}</span>
        </button>
      </header>

      <aside className={`${styles.aside} ${miniAside ? styles.asideMini : ''}`}>
        <nav className={styles.asideNav + ' p-mt-5 sidebar-nav'}>
          <ul>
            {navigationItems.map((navigationItem, index) => (
              <li key={index}>
                <NavLink to={navigationItem.to}>
                  <i className={navigationItem.icon} />
                  {miniAside !== true && <span className="p-ml-3 p-d-block">{navigationItem.label}</span>}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      <main className={`${styles.main} ${miniAside ? styles.mainMini : ''}`}>
          {children}
      </main>
    </div>
  )
}
