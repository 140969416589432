import React, { useCallback, useEffect, useState } from 'react';
import { ERROR_PROFILE_IMAGE_URL, getProfileLink } from '../../../core/LinkUtil'
import { useTranslation } from 'react-i18next';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import {
  AGE_RANGES,
  DEFAULT_SUMMARY_ERROR,
  DEFAULT_SUMMARY_SUCCESS,
  GENDERS,
  SOCIAL_NETWORKS,
  SOCIAL_PROFILE_TYPES
} from '../../../core/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'primereact/card';
import styles from './ProfilesPage.module.scss';

import numeral from 'numeral';
import { Button } from 'primereact/button';
import LoaderOverlay from '../../shared/LoaderOverlay/LoaderOverlay';
import api, { handleBeforeImportUpload,ProfileFilters } from '../../../core/api';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import config from '../../../core/config';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { isAdmin, isPublisherOrInfluencer, getDataAccessBadge } from '../../../core/utils';
import { Badge } from 'primereact/badge';
import { useAppSelector } from '../../../redux/hooks';
import { ToastMessageType } from 'primereact/toast';
import { AxiosRequestConfig } from 'axios';
import { ESocialPlatform, Profile } from '../../../monaco-client';



type ProfilesPageProp = {
  toast: (toast: ToastMessageType) => void
}
export default function ProfilesPage({ toast }: ProfilesPageProp) {
  const user = useAppSelector(state => state.auth.user);
  const categories = useAppSelector(state => state.data.categories);
  const interests = useAppSelector(state => state.data.interests);
  const tags = useAppSelector(state => state.data.tags);
  const countries = useAppSelector(state => state.data.countries);
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState<Profile[]>([]);
  const [loaderOverlay, setLoaderOverlay] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize/* , setPagesize */] = useState(20);
  const [total, setTotal] = useState(0);
  const [filterObject, setFilterObject] = useState<ProfileFilters>({
    orderByFollowers: "DESC"
  });
  const location = useLocation();
  const [orderByFollowers, setOrderByFollowers] = useState(false);


  var currentPage = page;
  const fetchData = useCallback((filterObj: ProfileFilters, options?: AxiosRequestConfig) => {
    setLoaderOverlay(true);
    api.socialController.getProfilesWithObject(currentPage, pageSize, filterObj, options).then(response => {
      setData(response.data?.content ?? []);
      let tmpData: any = response.data;
      if (tmpData.hasOwnProperty('total'))
        setTotal(tmpData.total);
      else
        setTotal(response?.data?.totalElements ?? response?.data?.content?.length ?? 0)
    }).catch((e) => {
      console.log(e)
    }).finally(() => setLoaderOverlay(false));
  },[currentPage, pageSize]);



  useEffect(() => {
    document.title = t('Connected Properties - NativeJ');
    const { source, options } = api.getCancelOption();
    var sessionFilterObject: ProfileFilters | null = null;

    let storedFilter = localStorage.getItem("filterObject")
    let storedCurrentPage = localStorage.getItem("currentPage")
    if (storedFilter != null && storedCurrentPage != null) {
      sessionFilterObject = JSON.parse(storedFilter);
      if (sessionFilterObject) {
        setFilterObject(sessionFilterObject);
      }
      setPage(parseInt(storedCurrentPage));
    }

    fetchData(sessionFilterObject != null ? sessionFilterObject : filterObject, options);

    if (localStorage.getItem("isBack") != null && localStorage.getItem("isBack")) {
      localStorage.removeItem("isBack");
      localStorage.removeItem("filterObject");
      localStorage.removeItem("currentPage");
    }

    if (location.search.length > 0 && location.search.startsWith('?')) {
      const queryString = location.search.substr(1, location.search.length);
      const queryParams: any = queryString.split('&').reduce((acc, curr) => {
        const split = curr.split('=');

        return {
          ...acc,
          [split[0]]: split[1]
        }
      }, {});

      if (queryParams.connect === 'facebook' && queryParams.code) {
        setLoaderOverlay(true);
        api.socialController.convertCodeToToken('facebook', queryParams.code, options).then(response => {
          api.socialController.extendShortLivedToken('facebook', response.data, options).then(response2 => {
            api.socialController.connectProperties('facebook', response2.data, options).then(() => {
              toast({
                severity: 'success',
                summary: DEFAULT_SUMMARY_SUCCESS(t),
                detail: t('All your authorised properties were connected')
              });
              setLoaderOverlay(false);
              window.history.pushState({}, document.title, location.pathname);
              fetchData(filterObject, options);
            }).catch(error => {
              toast({
                severity: 'error',
                summary: DEFAULT_SUMMARY_ERROR(t),
                detail: t('We are sorry but we failed to connect your existing properties')
              });

              setLoaderOverlay(false);
              console.error(error);
            });
          }).catch(error => {
            toast({
              severity: 'error',
              summary: DEFAULT_SUMMARY_ERROR(t),
              detail: t('We are sorry but we failed to extend your social token')
            });

            setLoaderOverlay(false);
            console.error(error);
          });
        }).catch(error => {
          toast({
            severity: 'error',
            summary: DEFAULT_SUMMARY_ERROR(t),
            detail: t('We are sorry but we failed to connect your social profile')
          });

          setLoaderOverlay(false);
          console.error(error);
        });
      }
    }
    return () => { source.cancel('request cancelled change page before completion') }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ t, toast]);//exclude deps fetchData call on button press not on filter object change

  const handleSearch = () => {
    currentPage = 0;
    fetchData(filterObject);
  };

  
  const handleFacebookConnect = () => {
    api.socialController.retrieveSocialLogin('facebook').then(response => {
      window.location.href = response.data;
    }).catch(console.error);
  };

  // const handleDisconnection = (item:Profile) => {
  //   setLoaderOverlay(true);

  //   item.id && api.profileUpdateController.disconnect(item.id).then(response => {
  //     toast({
  //       severity: 'success',
  //       summary: DEFAULT_SUMMARY_SUCCESS(t),
  //       detail: t('Abbiamo scollegato il profilo dalla piattaforma')
  //     });

  //     fetchData(filterObject);
  //   });
  // };


  const handlePageChange = (e: PaginatorPageState) => {
    setPage(e.page);
    currentPage = e.page;
    fetchData(filterObject);
    window.scrollTo(0, 0);
  };


  const handleImportUploadError = () => {
    toast({
      severity: 'error',
      summary: DEFAULT_SUMMARY_ERROR(t),
      detail: t('We weren\'t able to upload the data file, please contact the administrator')
    });
  }

  const handleImportUpload = () => {
    toast({
      severity: 'success',
      summary: DEFAULT_SUMMARY_SUCCESS(t),
      detail: t('Data was successfully upload and updated the properties')
    });

    fetchData(filterObject);
  }

  const handleFilterChange = (key: string, value: string) => {
    let filtObj ={
      ...filterObject,
      [key]: value ? value : undefined
    };
    setFilterObject(filtObj);
  }

  const toggleOrderByFollowers = () => {
    if (orderByFollowers === true) {
      handleFilterChange("orderByFollowers", "DESC");
    } else {
      handleFilterChange("orderByFollowers", "ASC");
    }
    setOrderByFollowers(!orderByFollowers);
  }

  const profileDetail = (id?: string) => {
    localStorage.setItem("filterObject", JSON.stringify(filterObject));
    localStorage.setItem("currentPage", currentPage.toString());
    history.push('/profile/' + id)
  }

  return (
    <StandardLayout>
      <div className="p-p-6">
        <LoaderOverlay visible={loaderOverlay} />
        <h1 className="page-title">{t('Connected Properties')}</h1>
        {isPublisherOrInfluencer(user) && (
          <div className="p-d-flex p-flex-row p-align-center p-mt-6">
            <Button label={t('Connect Facebook & Instagram Business')} icon="fab fa-facebook" className="p-button-secondary" onClick={handleFacebookConnect} />

            <span className="p-ml-auto">
              <strong>{numeral(total).format('0,0')} {(total === 1 ? t('Property') : t('Properties')) + ' ' + t('Connected')}</strong>
            </span>
          </div>
        )
        }
        {isAdmin(user) && (
          <div className="p-d-flex p-flex-row p-align-center p-mt-6">
            <span className="p-ml-auto">
              <strong>{numeral(total).format('0,0')} {(total === 1 ? t('Property') : t('Properties')) + ' ' + t('Connected')}</strong>
            </span>
            <FileUpload mode="basic" name="file" url={config.apiURL + '/social/profiles/import'} chooseLabel={t('Import Data')} className="p-ml-3" accept="text/csv" onBeforeSend={handleBeforeImportUpload} onError={handleImportUploadError} onUpload={handleImportUpload} />
          </div>
        )}
        <h3 className="p-text-normal p-text-primary-gray p-mb-3 p-p-0 p-mt-6">{t('Filters')}</h3>
        <div className="p-grid">
          <div className="p-col-12 p-lg-3">
            <label>{t('Name')}</label>
            <InputText className="w-100" placeholder={t('Name')} value={filterObject.name ?? ''} onChange={event => handleFilterChange('name', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Identification')}</label>
            <InputText className="w-100" placeholder={t('Identification')} value={filterObject.identification ?? ''} onChange={event => handleFilterChange('identification', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Social')}</label>
            <Dropdown showClear className="w-100" placeholder={t('Social')} value={filterObject.social ?? ''} options={[...SOCIAL_NETWORKS]} optionLabel="label" optionValue="value" onChange={e => handleFilterChange('social', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Type')}</label>
            <Dropdown showClear className="w-100" placeholder={t('Type')} value={filterObject.type ?? ''} options={[...SOCIAL_PROFILE_TYPES]} optionLabel="label" optionValue="value" onChange={e => handleFilterChange('type', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Followers')}</label>
            <InputText className="w-100" placeholder={t('Followers')} value={filterObject.followers ?? ''} onChange={event => handleFilterChange('followers', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Reach')}</label>
            <InputText className="w-100" placeholder={t('Reach')} value={filterObject.reach ?? ''} onChange={event => handleFilterChange('reach', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Likes')}</label>
            <InputText className="w-100" placeholder={t('Likes')} value={filterObject.likes ?? ''} onChange={event => handleFilterChange('likes', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Comments')}</label>
            <InputText className="w-100" placeholder={t('Comments')} value={filterObject.comments ?? ''} onChange={event => handleFilterChange('comments', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Views')}</label>
            <InputText className="w-100" placeholder={t('Views')} value={filterObject.views ?? ''} onChange={event => handleFilterChange('views', event.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Categories')}</label>
            <MultiSelect className="w-100" placeholder={t('Categories')} value={filterObject.categories ?? ''} options={categories} showClear optionLabel="name" optionValue="id" onChange={e => handleFilterChange('categories', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Interests')}</label>
            <MultiSelect className="w-100" placeholder={t('Interests')} value={filterObject.interests ?? ''} options={interests} showClear optionLabel="name" optionValue="id" onChange={e => handleFilterChange('interests', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Tags')}</label>
            <MultiSelect className="w-100" placeholder={t('Tags')} value={filterObject.tags ?? ''} options={tags} showClear optionLabel="name" optionValue="id" onChange={e => handleFilterChange('tags', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Location')}</label>
            <InputText className="w-100" placeholder={t('Location')} value={filterObject.location ?? ''} onChange={e => handleFilterChange('location', e.target.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Country')}</label>
            <MultiSelect className="w-100" placeholder={t('Country')} optionValue="code" value={filterObject.country ?? ''} options={countries} showClear onChange={e => handleFilterChange('country', e.value)} optionLabel="name" />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Gender')}</label>
            <MultiSelect className="w-100" placeholder={t('Gender')} value={filterObject.gender ?? ''} options={GENDERS(t)} showClear optionLabel="label" optionValue="value" onChange={e => handleFilterChange('gender', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            <label>{t('Age Range')}</label>
            <MultiSelect className="w-100" placeholder={t('Age Range')} value={filterObject.ageRange ?? ''} options={AGE_RANGES} showClear optionLabel="label" optionValue="value" onChange={e => handleFilterChange('ageRange', e.value)} />
          </div>
          <div className="p-col-12 p-lg-3">
            Followers {orderByFollowers ? t('ASC') : t('DESC')} <i className={orderByFollowers ? "pi pi-sort-numeric-up" : "pi pi-sort-numeric-down"} onClick={toggleOrderByFollowers} />
            <InputText type="hidden" value={filterObject.orderByFollowers ?? ''}></InputText>
          </div>
          <div className="p-col-6 p-lg-2 p-ml-auto">
            <Button className="w-100" onClick={handleSearch} label={t('Search')} />
          </div>
        </div>
        <div className="p-grid p-mt-3">
          {data.map(item => (
            <div className="p-col-12 p-md-4" key={item.id}>
              <Card className={styles.personCard} >
                {item.social === ESocialPlatform.Facebook && <div className={styles.personCard__socialBar + ' ' + styles.personCard__socialBar__facebook}>Facebook</div>}
                {item.social === ESocialPlatform.Instagram && <div className={styles.personCard__socialBar + ' ' + styles.personCard__socialBar__instagram}>Instagram</div>}
                <div onClick={e => profileDetail(item.id)}>
                  <div className="p-grid p-align-center" >
                    <div className="p-md-4">
                      <img width="410" height="410"
                        src={item.profilePicture || 'https://hunziker-watches.com/wp-content/uploads/2020/03/placeholder-images-image_large.png?&trace=' + item.identification}
                        onError={(e) => { e.currentTarget.src = ERROR_PROFILE_IMAGE_URL }}
                        alt={item.name}
                      />
                    </div>
                    <div className="p-md-8">
                      <h3>{item.name}
                        <span className="p-text-error p-text-bold p-ml-3"><Badge severity=
                          {getDataAccessBadge(item.dataAccess)} />
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="p-grid p-align-center p-mt-5">
                    <div className="p-md-6">
                      <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                        <i className="fas fa-thumbs-up p-fs-10 p-opacity-75 p-text-primary-gray" />
                        <span className="p-mt-3 p-fs-6 p-text-primary p-text-light">{numeral(item.likes).format('0a')}</span>
                      </div>
                    </div>
                    <div className="p-md-6">
                      <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                        <i className="fas fa-comments p-fs-10 p-opacity-75 p-text-primary-gray" />
                        <span className="p-mt-3 p-fs-6 p-text-primary p-text-light">{numeral(item.comments).format('0a')}</span>
                      </div>
                    </div>
                    <div className="p-md-6 p-mt-3">
                      <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                        <i className="fas fa-chart-pie p-fs-10 p-opacity-75 p-text-primary-gray" />
                        <span className="p-mt-3 p-fs-6 p-text-primary p-text-light">{numeral(item.reach).format('0a')}</span>
                      </div>
                    </div>
                    <div className="p-md-6 p-mt-3">
                      <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                        <i className="fas fa-users p-fs-10 p-opacity-75 p-text-primary-gray" />
                        <span className="p-mt-3 p-fs-6 p-text-primary p-text-light">{numeral(item.followers).format('0.0a')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-d-flex p-flex-row p-align-center p-justify-center p-mt-5">
                  <a href={getProfileLink(item)} target="_blank" rel="noreferrer">
                    <Button icon="fas fa-eye" className="p-button-text p-button-icon p-button-rounded p-button-lg" />
                  </a>
                </div>
              </Card>
            </div>
          ))}
        </div>
        <Paginator className="p-mt-5" first={page * pageSize} rows={pageSize} totalRecords={total} onPageChange={handlePageChange} />
      </div>

    </StandardLayout>
  )
}
