import { ComposableMap, Geographies, Geography, Graticule, ZoomableGroup } from 'react-simple-maps';
import React, { useEffect, useState } from 'react';
import { scaleLinear, scaleLog } from 'd3-scale';
import ReactTooltip from 'react-tooltip';
import { FollowersForCountry, Profile } from '../../../monaco-client';
import { Property } from "csstype";
//"https://raw.githubusercontent.com/openpolis/geojson-italy/master/topojson/limits_IT_municipalities.topo.json"
//"https://raw.githubusercontent.com/openpolis/geojson-italy/master/topojson/limits_IT_provinces.topo.json"
//"https://raw.githubusercontent.com/openpolis/geojson-italy/master/topojson/limits_IT_regions.topo.json";
const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale:(n:number)=>any =scaleLog()
  .domain([1, 1000000])
  .range(["#ffedea"as any, "#ec7b6b"as any]);


const colorScale1:(n:number)=>string = (n:number) => {
  
  let scale = scaleLog().domain([1, 1000000]).range([0xffedea, 0xec7b6b]);
  let value = Math.round(scale(n))
  return "#"+ value.toString(16).padStart(6, '0');
}

  

type MapChartProp ={
  className:string;
  socialProfile:FollowersForCountry[];
}
export default function MapChart({ className, socialProfile }:MapChartProp) {

  const [content, setContent] = useState("");
  

  const data = socialProfile;

  return (
    <div>       
    <ComposableMap data-tip=""
      className={className}
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-20.0, -52.0, 0],
        scale: 700
      }
    } 
    >
      <ZoomableGroup zoom={1}>
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const dataObject = data.find(o => o.id === geo.properties.ISO_A3);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { NAME } = geo.properties;
                    dataObject ? setContent(`${NAME} - ` + dataObject.val + ` followers`) : setContent("");
                    //console.log("mapchart test =  " + content);
                  }}
                  onMouseLeave={() => {
                    setContent("");
                  }}
                  style={{
                    default: {
                      //fill:  (dataObject ?colorScale1(dataObject?.val??0) : "#ECEFF1") ,
                      fill: dataObject ? colorScale(dataObject.val??0) : "#ECEFF1",
                      stroke: "#D6D6DA",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    hover: {
                      fill: "#54b19d",
                      stroke: "#D6D6DA",
                      strokeWidth: 0.75,
                      outline: "none",
                    },
                    pressed: {
                      fill: "#064f19",
                      stroke: "#D6D6DA",
                      strokeWidth: 0.75,
                      outline: "none",
                    }
                  }}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
    <ReactTooltip>{content}</ReactTooltip>
    </div>
  )
  
}