import { TFunction } from "react-i18next";
import { NJTreeNode } from "../components/shared/NJTree/NJTree";
import { ECampaignStatus, EGender, EPriceType, ESocialPlatform } from "../monaco-client";

export const DEFAULT_SUMMARY_ERROR = (t: TFunction) => t('Something went wrong');
export const DEFAULT_SUMMARY_SUCCESS = (t: TFunction) => t('Operation completed');
export const LANGUAGE_EN = 'EN';
export const LANGUAGE_IT = 'IT';
export const PRIMARY_COLOR = "#54b19d";
export const CHART_COLOR_1 = "#b15471";
export const CHART_COLOR_2 = "#3e4342";
export const CHART_COLOR_3 = "#b154af";
export const PRIMARY_COLOR_HOVER = "#64cdb6";
export const CHART_COLOR_1_HOVER = "#c65f7f";
export const CHART_COLOR_2_HOVER = "#676d6b";
export const CHART_COLOR_3_HOVER = "#ca64c7";

export const SOCIAL_NETWORKS = [
  { label: 'FACEBOOK', value: ESocialPlatform.Facebook },
  { label: 'INSTAGRAM', value: ESocialPlatform.Instagram }
];
export const SOCIAL_PROFILE_TYPES = [
  { label: 'INFLUENCER', value: 'INFLUENCER' },
  { label: 'PUBLISHER', value: 'PUBLISHER' }
];

export const CAMPAIGN_STATUS = Object.values(ECampaignStatus).map(v => { return { label: v.toString(), value: v }; })

export const PRICE_PLATFORMS = Object.values(ESocialPlatform).map(v => { return { label: v.toString(), value: v } })


export const PRICE_TYPES = Object.values(EPriceType).map(v => { return { label: v.toString(), value: v } })

export const GENDERS = (t: TFunction) => Object.values(EGender).map(v => { return { label: t(v.toString()), value: v } })

export const AGE_RANGES = [
  { value: '13-17', label: '13-17' },
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-44', label: '35-44' },
  { value: '45-54', label: '45-54' },
  { value: '55-64', label: '55-64' },
  { value: '65+', label: '65+' }
];


export const TARGET_LOCATION_TREE: (t: TFunction) => NJTreeNode<string>[] = (t) => [
  {
    value: 'ALL', label: t('All'), children: [
      {
        value: 'ITALY', label: t('Italy'), children: [
          {
            value: 'NORTH-ITALY', label: t('North of Italy'), children: [
              { value: 'AOSTA-VALLEY', label: t('Aosta Valley') },
              { value: 'PIEDMONT', label: t('Piedmont') },
              { value: 'LIGURIA', label: t('Liguria') },
              { value: 'LOMBARDY', label: t('Lombardy') },
              { value: 'EMILIA-ROMAGNA', label: t('Emilia-Romagna') },
              { value: 'VENETO', label: t('Veneto') },
              { value: 'FRIULI-VENEZIA_GIULIA', label: t('Friuli-Venezia Giulia') },
              { value: 'TRENTINO-ALTO_ADIGE', label: t('Trentino-Alto Adige') },
            ]
          },
          {
            value: 'CENTER-ITALY', label: t('Center of Italy'), children: [
              { value: 'LAZIO', label: t('Lazio') },
              { value: 'MARCHE', label: t('Marche') },
              { value: 'TUSCANY', label: t('Tuscany') },
              { value: 'UMBRIA', label: t('Umbria') },
            ]
          },
          {
            value: 'SOUTH-ITALY', label: t('South of Italy'), children: [
              { value: 'ABRUZZO', label: t('Abruzzo') },
              { value: 'PUGLIA', label: t('Puglia') },
              { value: 'BASILICATA', label: t('Basilicata') },
              { value: 'CALABRIA', label: t('Calabria') },
              { value: 'CAMPANIA', label: t('Campania') },
              { value: 'MOLISE', label: t('Molise') },
              { value: 'SICILY', label: t('Sicily') },
              { value: 'SARDINIA', label: t('Sardinia') },
            ]
          },
        ]
      },
      {
        value: 'EUROPE', label: t('Europe'), children: [
          { value: 'AUSTRIA', label: t('Austria') },
          { value: 'BELGIUM', label: t('Belgium') },
          { value: 'CROATIA', label: t('Croatia') },
          { value: 'DENMARK', label: t('Denmark') },
          { value: 'FINLAND', label: t('Finland') },
          { value: 'FRANCE', label: t('France') },
          { value: 'GERMANY', label: t('Germany') },
          { value: 'GREECE', label: t('Greece') },
          { value: 'IRELAND', label: t('Ireland') },
          { value: 'NETHERLANDS', label: t('Netherlands') },
          { value: 'POLAND', label: t('Poland') },
          { value: 'PORTUGAL', label: t('Portugal') },
          { value: 'SPAIN', label: t('Spain') },
          { value: 'SWEDEN', label: t('Sweden') },
        ]
      },
      {
        value: 'EXTRA-EUROPE', label: t('Extra Europe'), children: [
          { value: 'UK', label: t('UK') },
          { value: 'USA', label: t('USA') },
          { value: 'RUSSIA', label: t('Russia') },
        ]
      }
    ]
  },
]

const treeFlatMap: <T>(array: NJTreeNode<T>[]) => { label: string, value: T }[] = (array) => {
  return array.flatMap(data => {
    let current = { label: data.label, value: data.value }
    if (data.children && data.children.length>0)
      return [current, ...treeFlatMap(data.children)]
    else
      return [current]

  })
}
export const TARGET_LOCATION = (t: TFunction) => treeFlatMap(TARGET_LOCATION_TREE(t))

