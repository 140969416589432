import React from 'react';
import styles from './MinimalLayout.module.scss';
import Footer from '../Footer/Footer';

type MinimalLayoutProps = {
  children : any;
}
export default function MinimalLayout({ children }:MinimalLayoutProps) {
  return (
    <div>
    <div className={`${styles.root} p-p-6`}>
      {children}
    </div>
    <Footer />
    </div>  
  )
}
