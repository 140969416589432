import React, {ForwardedRef, ForwardRefRenderFunction, RefForwardingComponent, useEffect, useImperativeHandle, useState} from 'react';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import api from '../../../core/api';
import {useTranslation} from 'react-i18next';

import {tableNumberFilter} from '../../../core/utils';
import {Dropdown} from 'primereact/dropdown';
import {AGE_RANGES, GENDERS, PRICE_PLATFORMS, PRICE_TYPES} from '../../../core/constants';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {MultiSelect} from 'primereact/multiselect';
import { useAppSelector } from '../../../redux/hooks';
import {forwardRef} from 'react';
import { Category, Profile, ProfilePrice, ESocialPlatform, EPriceType, EGender } from '../../../monaco-client';


export interface ProfilePickerRef {
  getSelectedProfiles(): Profile[];
}

type ProfilePickerProps ={
  className : string;
  defaultProfiles?:Profile[];
}

const ProfilePicker  = forwardRef(({ className, defaultProfiles }:ProfilePickerProps, ref:ForwardedRef<ProfilePickerRef>)=>  {
  const countries = useAppSelector(state=>state.data.countries);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [filteredProfiles, setFilteredProfiles] = useState<Profile[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState([...(defaultProfiles || [])]);
  const [loading, setLoading] = useState(true);
  const [pricePlatform, setPricePlatform] = useState<ESocialPlatform|null>(null);
  const [priceType, setPriceType] = useState<EPriceType|null>(null);
  const [priceValue, setPriceValue] = useState<string>("");
  const [locationFilter, setLocationFilter] = useState<string>("");
  const [countryFilter, setCountryFilter] = useState([]);
  const [genderFilter, setGenderFilter] = useState<EGender[]>([]);
  const [ageRangeFilter, setAgeRangeFilter] = useState([]);
  const {t} = useTranslation();


  
  useEffect(() => {
    api.socialController.getAllProfiles().then(response => {
      setProfiles(response.data);
      setFilteredProfiles([...response.data]);
      setLoading(false);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    getSelectedProfiles: () => selectedProfiles
  }));

  const filterByMultiName = (value:{name:string}[], filter:string) => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    for (const item of value) {
      if (item.name.toLowerCase().startsWith(filter.toLowerCase())) {
        return true;
      }
    }

    return false;
  };

  const categoriesBodyTemplate = (rowData:Profile) => {
    return [...rowData.categories??[]].filter(o => o !== null).map(o => o.name).join(', ');
  };

  const interestsBodyTemplate = (rowData:Profile)  => {
    return [...rowData.interests??[]].filter(o => o !== null).map(o => o.name).join(', ');
  };

  const tagsBodyTemplate = (rowData:Profile)  => {
    return [...rowData.tags??[]].filter(o => o !== null).map(o => o.name).join(', ');
  };

  const handlePriceFilters = () => {
    let filteredProfiles = [...profiles];

    if (pricePlatform && priceType && priceValue && pricePlatform.length > 0 && priceType.length > 0 && priceValue.length > 0) {
      filteredProfiles = filteredProfiles.filter(profile => {
        const prices = (profile.prices || []).filter(price => {
          if (price.platform !== pricePlatform || price.type !== priceType || price.active === false) {
            return false;
          }

          return tableNumberFilter(price.price, priceValue);
        });

        return prices.length > 0;
      });
    }

    if (locationFilter && locationFilter.length > 0) {
      filteredProfiles = filteredProfiles.filter(profile => {
        if (!profile.hasOwnProperty('followersByCity') || typeof profile.followersByCity !== 'object') {
          return true;
        }

        const locations = Object.keys(profile.followersByCity).map(item => item.toLowerCase().trim());
        const filterValue = locationFilter.toLowerCase().trim();

        return locations.findIndex(location => location.indexOf(filterValue) > -1) > -1;
      });
    }

    if (countryFilter && countryFilter.length > 0) {
      filteredProfiles = filteredProfiles.filter(profile => {
        if (!profile.hasOwnProperty('followersByCountry') || typeof profile.followersByCountry !== 'object') {
          return true;
        }

        for (const filterValue of countryFilter) {
          if (profile.followersByCountry.hasOwnProperty(filterValue) && profile.followersByCountry[filterValue] > 0) {
            return true;
          }
        }

        return false;
      });
    }

    if (genderFilter && genderFilter.length > 0) {
      filteredProfiles = filteredProfiles.filter(profile => {
        if (!profile.hasOwnProperty('followersByGender') || typeof profile.followersByGender !== 'object') {
          return true;
        }

        for (const filterValue of genderFilter) {
          if (profile.followersByGender.hasOwnProperty(filterValue) && profile.followersByGender[filterValue] > 0) {
            return true;
          }
        }

        return false;
      });
    }

    if (ageRangeFilter && ageRangeFilter.length > 0) {
      filteredProfiles = filteredProfiles.filter(profile => {
        if (!profile.hasOwnProperty('followersByAgeRange') || typeof profile.followersByAgeRange !== 'object') {
          return true;
        }

        for (const filterValue of ageRangeFilter) {
          if (profile.followersByAgeRange.hasOwnProperty(filterValue) && profile.followersByAgeRange[filterValue] > 0) {
            return true;
          }
        }

        return false;
      });
    }

    setFilteredProfiles(filteredProfiles);
  };

  const getEstimatedCost = () => {
    let cost = 0;

    selectedProfiles.forEach(selectedProfile => {
      const price = (selectedProfile.prices || []).find(o =>
        o.platform === selectedProfile.social
        && o.type === 'POST'
        && o.active === true
        && !o.packageName
        && o.units === 1
      );

      if (price) {
        cost += price.price??0;
      }
    });

    return cost;
  };

  return (
    <div>
      <h3 className="p-text-normal p-text-primary-gray">{t('Price Filters')}</h3>
      <div className="p-d-flex p-flex-row p-align-center">
        <Dropdown placeholder={t('Platform')} value={pricePlatform} options={[...PRICE_PLATFORMS]} optionLabel="label" optionValue="value" onChange={e => setPricePlatform(e.value)} />
        <Dropdown className="p-ml-3" placeholder={t('Type')} value={priceType} options={[...PRICE_TYPES]} optionLabel="label" optionValue="value" onChange={e => setPriceType(e.value)} />
        <InputText className="p-ml-3" placeholder={t('Price')} value={priceValue} onChange={e => setPriceValue(e.target.value)} />
      </div>
      <h3 className="p-text-normal p-text-primary-gray">{t('Audience Filters')}</h3>
      <div className="p-d-flex p-flex-row p-align-center">
        <InputText placeholder={t('Location')} value={locationFilter} onChange={e => setLocationFilter(e.target.value)} />
        <MultiSelect className="p-ml-3" placeholder={t('Country')} value={countryFilter} options={countries} showClear optionLabel="name" optionValue="code" onChange={e => setCountryFilter(e.value)} />
        <MultiSelect className="p-ml-3" placeholder={t('Gender')} value={genderFilter} options={GENDERS(t)} showClear optionLabel="label" optionValue="value" onChange={e => setGenderFilter(e.value)} />
        <MultiSelect className="p-ml-3" placeholder={t('Age Range')} value={ageRangeFilter} options={AGE_RANGES} showClear optionLabel="label" optionValue="value" onChange={e => setAgeRangeFilter(e.value)} />
      </div>
      <div className="p-mt-5 p-d-flex p-flex-row p-align-center">
        <Button label={t('Apply')} className="p-button-outlined w-100" style={{ maxWidth: 200 }} onClick={handlePriceFilters} />
        <strong className="p-ml-auto">{t('Costo stimato')}: &euro; {getEstimatedCost()}</strong>
      </div>
      <DataTable loading={loading} value={filteredProfiles} className={'p-datatable-lg p-datatable-gridlines p-datatable-striped ' + className} columnResizeMode="fit" paginator rows={20} scrollable scrollHeight="300px" selection={selectedProfiles} onSelectionChange={e => setSelectedProfiles(e.value)} dataKey="id">
        <Column selectionMode="multiple" headerStyle={{width: '55px'}} />
        <Column field="name" header={t('Name')} headerStyle={{ width: '200px' }} filter sortable />
        <Column field="followers" header={t('Followers')} headerStyle={{ width: '150px' }} filter filterFunction={tableNumberFilter} filterMatchMode="custom" sortable />
        <Column field="likes" header={t('Likes')} headerStyle={{ width: '150px' }} filter sortable />
        <Column field="views" header={t('Views')} headerStyle={{ width: '150px' }} filter sortable />
        <Column field="reach" header={t('Reach')} headerStyle={{ width: '150px' }} filter sortable />
        <Column field="categories" header={t('Categories')} headerStyle={{ width: '200px' }} body={categoriesBodyTemplate} filter filterMatchMode="custom" filterFunction={filterByMultiName} />
        <Column field="interests" header={t('Interests')} headerStyle={{ width: '200px' }} body={interestsBodyTemplate} filter filterMatchMode="custom" filterFunction={filterByMultiName} />
        <Column field="tags" header={t('Tags')} headerStyle={{ width: '200px' }} body={tagsBodyTemplate} filter filterMatchMode="custom" filterFunction={filterByMultiName} />
        <Column field="identification" header={t('Identification')} headerStyle={{ width: '200px' }} filter sortable />
        <Column field="social" header={t('Social')} headerStyle={{ width: '200px' }} filter sortable />
        <Column field="type" header={t('Type')} headerStyle={{ width: '200px' }} filter sortable />
      </DataTable>
    </div>
  )
});

export default ProfilePicker;
