import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Tree, TreeDragDropParams, TreeEventNodeParams, TreeExpandedKeysType, TreeExpandedParams, TreeFilterModeType, TreeFooterTemplateType, TreeHeaderTemplateType, TreeNodeTemplateType, TreeSelectionModeType, TreeSelectionParams, TreeTogglerTemplateType } from "primereact/tree";
import TreeNode from "primereact/components/treenode/TreeNode";
export interface NJTreeNode<T> {
    value: T
    label: string
    children?: NJTreeNode<T>[]
    icon?: string


}
interface NJTreeProps<T> {
    options: NJTreeNode<T>[]
    value: T[]
    onChange: (value: T[]) => void
    expandedValue?: T[]

    id?: string;
    disabled?: boolean;
    selectionMode?: TreeSelectionModeType;
    contextMenuSelectionKey?: string;
    //expandedKeys?: TreeExpandedKeysType;
    style?: object;
    className?: string;
    contentStyle?: object;
    contentClassName?: string;
    metaKeySelection?: boolean;
    propagateSelectionUp?: boolean;
    propagateSelectionDown?: boolean;
    loading?: boolean;
    loadingIcon?: string;
    dragdropScope?: string;
    header?: TreeHeaderTemplateType;
    footer?: TreeFooterTemplateType;
    showHeader?: boolean;
    filter?: boolean;
    filterValue?: string;
    filterBy?: string;
    filterMode?: TreeFilterModeType;
    filterPlaceholder?: string;
    filterLocale?: string;
    togglerTemplate?: TreeTogglerTemplateType;
    nodeTemplate?: TreeNodeTemplateType;
    onContextMenuSelectionChange?(e: TreeSelectionParams): void;
    onSelect?(e: TreeEventNodeParams): void;
    onUnselect?(e: TreeEventNodeParams): void;
    onExpand?(e: TreeEventNodeParams): void;
    onCollapse?(e: TreeEventNodeParams): void;
    onToggle?(e: TreeExpandedParams): void;
    onDragDrop?(e: TreeDragDropParams): void;
    onContextMenu?(e: TreeEventNodeParams): void;
    onFilterValueChange?(e: React.FormEvent<HTMLInputElement>): void;

}
export function NJTree<T>(props: NJTreeProps<T>) {
    //const [selectedNode,setSelectdeNode] = useState<TreeNode[]>([])
    const [keys, setKeys] = useState(new Map<T, string>())
    const [options, setOptions] = useState<TreeNode[]>([])

    const toSelectionKey = (values: T[]) => {
        let ret: any = {};
        for (let val of values) {
            let key = keys.get(val)

            if (key) {
                if (props.propagateSelectionUp) {
                    let keyArr = key.split('-')
                    for (let index = 1; index < keyArr.length; index++) {
                        let parentKey = keyArr.slice(0, index).join('-')
                        let parent = ret[parentKey]
                        if (parent) parent.partialChecked = true;
                        else ret[parentKey] = { partialChecked: true };
                    }
                }
                ret[key] = {
                    checked: true
                };
            }
        }
        return ret;
    }
    
     const toTreeNode = useCallback(<T,>(op: NJTreeNode<T>[], keys_: Map<T,string>,baseKey:number[]) => {
        let retKeys = keys_;
        let retNodes: TreeNode[] = [];
        for (let index = 0; index < op.length; index++) {
            const node = op[index];
            let keyArr = baseKey.concat(index) 
            let key = keyArr.join('-')
            retKeys.set(node.value,key)
            let { nodes, keys } = (node.children && node.children.length > 0) ? toTreeNode(node.children, keys_,keyArr) : { nodes: [], keys: keys_ }
            retKeys = keys;
            let res: TreeNode = {
                key: key,
                data: node.value,
                label: node.label,
                icon: node.icon,
                children: nodes,
            }
            retNodes = retNodes.concat(res);
        }
        return { nodes: retNodes, keys: retKeys }
    },[])
    
    
        useEffect(() => {
            
            let { nodes, keys } = toTreeNode(props.options, new Map(),[]);
            setOptions(nodes)
            setKeys(keys)
        }, [props.options, setKeys, setOptions, toTreeNode])
    
    

    const handleSelectionChange = (e: TreeSelectionParams) => {
        console.log(e.value)
        let retVals: T[] = []
        let baseKeyToSkip: string[] = [];
        for (let k of keys.entries()) {
            let key = k[1]
            let checked: any = e.value[key]
 //           if ((checked && checked.checked && checked.partialChecked)) {
   //             
     //       }
            if (checked && checked.checked) {
                baseKeyToSkip = baseKeyToSkip.concat(key)
                if (baseKeyToSkip.findIndex(
                    basekey => (basekey !== key) && key.startsWith(basekey)
                ) < 0) {
                    retVals = retVals.concat(k[0])
                }
            }
        }
        props.onChange(retVals)
    }

    const toExpandedKey = useMemo(() => {
        let { keys } = toTreeNode(props.options, new Map(), []);
        let vals = props.expandedValue ?? props.value
        let ret: TreeExpandedKeysType = {}
        for (let val of vals) {
            let key = keys.get(val)
            if (key) {
                let keyArr = key.split('-')
                for (let index = 1; index < keyArr.length; index++) {
                    ret[keyArr.slice(0, index).join('-')] = true
                }
            }
        }
        return ret;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.expandedValue, props.options]);

    return (
        <Tree
            value={options}
            selectionKeys={toSelectionKey(props.value)}
            onSelectionChange={handleSelectionChange}
            id={props.id}
            disabled={props.disabled}
            selectionMode={props.selectionMode}
            contextMenuSelectionKey={props.contextMenuSelectionKey}
            expandedKeys={toExpandedKey}
            style={props.style}
            className={props.className}
            contentStyle={props.contentStyle}
            contentClassName={props.contentClassName}
            metaKeySelection={props.metaKeySelection}
            propagateSelectionUp={props.propagateSelectionUp}
            propagateSelectionDown={props.propagateSelectionDown}
            loading={props.loading}
            loadingIcon={props.loadingIcon}
            dragdropScope={props.dragdropScope}
            header={props.header}
            footer={props.footer}
            showHeader={props.showHeader}
            filter={props.filter}
            filterValue={props.filterValue}
            filterBy={props.filterBy}
            filterMode={props.filterMode}
            filterPlaceholder={props.filterPlaceholder}
            filterLocale={props.filterLocale}
            togglerTemplate={props.togglerTemplate}
            nodeTemplate={props.nodeTemplate}
            onContextMenuSelectionChange={props.onContextMenuSelectionChange}
            onSelect={props.onSelect}
            onUnselect={props.onUnselect}
            onExpand={props.onExpand}
            onCollapse={props.onCollapse}
            onToggle={props.onToggle}
            onDragDrop={props.onDragDrop}
            onContextMenu={props.onContextMenu}
            onFilterValueChange={props.onFilterValueChange}
        />)
}
