/* tslint:disable */
/* eslint-disable */
/**
 * Monaco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HistoryData } from '../model';
/**
 * ProfileHistoryControllerApi - axios parameter creator
 * @export
 */
export const ProfileHistoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {string} from 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        history: async (profileId: string, from: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('history', 'profileId', profileId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('history', 'from', from)
            const localVarPath = `/profiles/{profileId}/history`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileHistoryControllerApi - functional programming interface
 * @export
 */
export const ProfileHistoryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileHistoryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {string} from 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async history(profileId: string, from: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.history(profileId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileHistoryControllerApi - factory interface
 * @export
 */
export const ProfileHistoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileHistoryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {string} from 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        history(profileId: string, from: string, to?: string, options?: any): AxiosPromise<HistoryData> {
            return localVarFp.history(profileId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileHistoryControllerApi - interface
 * @export
 * @interface ProfileHistoryControllerApi
 */
export interface ProfileHistoryControllerApiInterface {
    /**
     * 
     * @param {string} profileId 
     * @param {string} from 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileHistoryControllerApiInterface
     */
    history(profileId: string, from: string, to?: string, options?: any): AxiosPromise<HistoryData>;

}

/**
 * ProfileHistoryControllerApi - object-oriented interface
 * @export
 * @class ProfileHistoryControllerApi
 * @extends {BaseAPI}
 */
export class ProfileHistoryControllerApi extends BaseAPI implements ProfileHistoryControllerApiInterface {
    /**
     * 
     * @param {string} profileId 
     * @param {string} from 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileHistoryControllerApi
     */
    public history(profileId: string, from: string, to?: string, options?: any) {
        return ProfileHistoryControllerApiFp(this.configuration).history(profileId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}
