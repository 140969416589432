import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from './config';
import {
  AuthControllerApiFactory,
  DataControllerApiFactory,
  ReportControllerApiFactory,
  BriefControllerApiFactory,
  BriefControllerApiInterface,
  UserControllerApiFactory,
  SocialControllerApiFactory,
  SocialControllerApiInterface,
  ProfileHistoryControllerApiFactory,
  ProfileUpdateControllerApiFactory,
  PublicControllerApiFactory,
  FileControllerApiFactory,

} from '../monaco-client/api'
import { PostRequest, ESocialPlatform, EGender, PageImplProfile } from '../monaco-client/model';
import { Configuration } from '../monaco-client/configuration';
import { ToastMessageType } from 'primereact/toast';
import { TFunction } from 'react-i18next';
import { DEFAULT_SUMMARY_ERROR, DEFAULT_SUMMARY_SUCCESS } from './constants';
import { FileUploadBeforeSendParams } from 'primereact/fileupload';

export interface BriefControllerApiCustom extends BriefControllerApiInterface {
  savePostRequestPlanning(campaignId: string, profileId: string, postRequest: PostRequest, options?: any): AxiosPromise<PostRequest>
}

export interface ProfileFilters {
  name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string
}
export interface SocialControllerApiCustom extends SocialControllerApiInterface {
  getProfilesWithObject(page: number, pageSize: number, filter: ProfileFilters, options?: any): AxiosPromise<PageImplProfile>
}


export class Api {
  // axiosWithToast
  private apiConfiguration
  getCancelOption() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let options: AxiosRequestConfig = { cancelToken: source.token }
    return {
      options,
      source
    }
  }
  dataController
  authController
  reportController
  briefController: BriefControllerApiCustom
  userController
  socialController: SocialControllerApiCustom
  profileHistoryController
  profileUpdateController
  publicController
  fileController

  constructor() {
    //axios.interceptors.response.use(response => ({...response, error: false}), error => ({...error.response, error: true}));    
    axios.interceptors.request.use(requestConfig => {
      requestConfig.headers.Authorization = `Bearer ${sessionStorage.getItem('App.Token')}`;
      return requestConfig;
    });

    this.apiConfiguration = new Configuration({
      basePath: config.apiURL,
      accessToken: (name?: string, scopes?: string[]) => {
        return sessionStorage.getItem('App.Token') ?? ""
      }

    });

    this.dataController = DataControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.authController = AuthControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.reportController = ReportControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.briefController = {
      ...BriefControllerApiFactory(
        this.apiConfiguration,
        config.apiURL,
        axios
      ),
      savePostRequestPlanning(campaignId: string, profileId: string, postRequest: PostRequest, options?: any) {
        if (postRequest.id) {
          //put
          return this.savePostRequest1(campaignId, profileId, postRequest, options)
        } else {
          //post
          return this.savePostRequest(campaignId, profileId, postRequest, options)
        }

      }
    }
    this.userController = UserControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.socialController = {
      ...SocialControllerApiFactory(
        this.apiConfiguration,
        config.apiURL,
        axios,
      ),
      getProfilesWithObject(page: number, pageSize: number, filter: ProfileFilters, options?: any): AxiosPromise<PageImplProfile> {
        return this.getProfiles(
          pageSize,
          page,
          filter.name,
          filter.identification,
          filter.social,
          filter.type,
          filter.followers,
          filter.reach,
          filter.likes,
          filter.comments,
          filter.views,
          filter.categories,
          filter.interests,
          filter.tags,
          filter.location,
          filter.country,
          filter.gender,
          filter.ageRange,
          filter.orderByFollowers,
          options
        )


      }
    }

    this.profileHistoryController = ProfileHistoryControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.profileUpdateController = ProfileUpdateControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.publicController = PublicControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

    this.fileController = FileControllerApiFactory(
      this.apiConfiguration,
      config.apiURL,
      axios
    )

  }

  public campaignCsvUploadUrl(id: string) {
    return config.apiURL + '/brief/campaign/' + id + '/import/daily'
  }





  public createToastInterceptor(toast: ((t: ToastMessageType) => void), t: TFunction, detail?: string, summary?: string) {
    axios.interceptors.response.use(response => {

      //showResponseToastAxiosSuccess(toast,response,t)
      return response;
    }, error => {
      if (!axios.isCancel(error)) {
        showResponseToastAxiosFailure(toast, error, t, detail, summary)
      }
      return Promise.reject(error);
    })

  }

};

export const handleBeforeImportUpload: (e: FileUploadBeforeSendParams) => void = (ev) => {
  ev.xhr.setRequestHeader('Authorization', `Bearer ${sessionStorage.getItem('App.Token')}`);
}

export const showResponseToastAxiosSuccess = (toast: ((t: ToastMessageType) => void), response: AxiosResponse<object>, t: TFunction) => {

  const message = response.headers ? response.headers['message'] : undefined;
  toast({
    severity: 'success',
    summary: DEFAULT_SUMMARY_SUCCESS(t),
    detail: message ? message : t('Everything is working properly')
  });
};


export const showResponseToastAxiosFailure = (toast: ((t: ToastMessageType) => void), response: AxiosResponse<object>, t: TFunction, description?: string, summary?: string) => {
  if (axios.isCancel(response))
    return;
  let detail: string;
  let resp: any = response
  if (description)
    detail = description
  else if (response.headers && response.headers['message'])
    detail = response.headers['message'];
  else if (resp.message)
    detail = resp.message;
  else if (response.statusText)
    detail = response.statusText
  else if (resp?.data?.message)
    detail = resp?.data?.message
  else
    detail = t('Error ') + response.status
  if (resp?.data?.trace)
    console.log("Server Error")
  console.log(resp?.data?.trace)

  toast({
    severity: 'error',
    "summary": summary ?? DEFAULT_SUMMARY_ERROR(t),
    "detail": detail
  });
};



const api = new Api();
export default api;
