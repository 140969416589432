import {InputTextarea} from 'primereact/inputtextarea';
import styles from './CampaignContentPlanning.module.scss';
import {Button} from 'primereact/button';
import {useTranslation} from 'react-i18next';
import React, {useEffect,  useState} from 'react';
import {DEFAULT_SUMMARY_SUCCESS} from '../../../core/constants';

import ButtonUpload from '../ButtonUpload/ButtonUpload';
import {ProgressSpinner} from 'primereact/progressspinner';
import api from '../../../core/api';
import CampaignProfilePostRequest from '../CampaignProfilePostRequest/CampaignProfilePostRequest';
import {isAdmin, isPublisherOrInfluencer} from '../../../core/utils';
import {Calendar} from 'primereact/calendar';
import moment from 'moment';
import FacebookPostPreview from '../FacebookPostPreview/FacebookPostPreview';
import { useAppSelector } from '../../../redux/hooks';
import { ToastMessageType } from 'primereact/toast';
import { Campaign, PostRequest, PostRequestNote, EPostRequestNoteAuthor, Profile, ScheduledPost, UploadedFile } from '../../../monaco-client';
import { dateToString,stringToDate } from "../../../core/dateUtil";


const postRequestDefaults:Partial<PostRequest> = {
  profile: undefined,
  campaign: undefined,
  scheduledPost: undefined,
  file: undefined,
  date: undefined,
  notes: []
};
type CampaignContentPlanningProps ={
  toast:  (toast: ToastMessageType) => void; 
  campaign : Campaign; 
  profile?:Profile|null
}
export default function CampaignContentPlanning({ toast, campaign, profile }:CampaignContentPlanningProps) {
  const { t } = useTranslation();
  // const menu = useRef(null);
  // const smartForm = useRef();
  const user = useAppSelector(s=>s.auth.user);
  const [loading, setLoading] = useState(true);
  const [postRequests, setPostRequests] = useState<PostRequest[]>([]);
  const [postRequest, setPostRequest] = useState<Partial<PostRequest>|null>(null);
  const [note, setNote] = useState("");

  useEffect(() => {
    if(campaign.id && profile?.id)
    api.briefController.getPostPlanningByCampaignAndProfile(campaign.id, profile.id).then(( response ) => {
      setPostRequests(response.data);
      setLoading(false);
    }).catch(console.error);
  }, [campaign, profile]);



  // const handleImportUploadError = () => {
  //   toast({
  //     severity: 'error',
  //     summary: DEFAULT_SUMMARY_ERROR(t),
  //     detail: t('We weren\'t able to upload the data file, please contact the administrator')
  //   });
  // }

  // const handleImportUpload = () => {
  //   toast({
  //     severity: 'success',
  //     summary: DEFAULT_SUMMARY_SUCCESS(t),
  //     detail: t('Data was successfully upload and updated the properties')
  //   });
  // }

  const handleRequestAnswer = () => {
    if(campaign.id && profile?.id)
    api.briefController.savePostRequestPlanning(campaign.id, profile.id, {
      ...postRequest,
      notes: [
        ...postRequest?.notes??[],
        {
          date: moment().format('YYYY-MM-DD'),
          message: note,
          author: isAdmin(user) ? EPostRequestNoteAuthor.NativejManager : EPostRequestNoteAuthor.PropertyManager
        }
      ],
      campaign,
      profile
    }).then((response ) => {
      postRequests.push(response.data);
      setPostRequest(null);
    }).catch(console.error);
  };

  const handleRequestSave = () => {
    if (campaign.id && profile && profile.id) {
      var newPostRequest:PostRequest ={
        ...postRequest,
        campaign,
        profile,
        notes:postRequest?.notes??[]
      } 
      
      api.briefController.savePostRequestPlanning(campaign.id, profile.id, newPostRequest).then(( response ) => {
        toast({
          severity: 'success',
          summary: DEFAULT_SUMMARY_SUCCESS(t),
          detail: t('The post request has been updated')
        });
        if (response.data) {
          setPostRequest(response.data)
          const index = postRequests.findIndex(o => o.id === response.data.id);
          setPostRequests([
            ...postRequests.slice(0, index),
            response.data,
            ...postRequests.slice(index + 1)
          ])
        }
      }).catch(console.error);
    }
  };

  const handleRequestReply = () => {
    if (campaign.id && profile?.id && postRequest && postRequest.campaign && postRequest.profile)
      api.briefController.savePostRequestPlanning(campaign.id, profile.id, {
        ...postRequest,
        profile:postRequest.profile,
        campaign:postRequest.campaign,
        notes: [
          ...postRequest?.notes ?? [],
          {
            date: moment().format('YYYY-MM-DD'),
            message: note,
            author: isAdmin(user) ? EPostRequestNoteAuthor.NativejManager : EPostRequestNoteAuthor.PropertyManager
          }
        ]
      }).then((response) => {
        setPostRequest(response.data);
        setNote('');
      }).catch(console.error);
  };

  const handleFileUploadReply = (file: UploadedFile) => {
    if (campaign.id && profile?.id && postRequest && postRequest.campaign && postRequest.profile)
      api.briefController.savePostRequestPlanning(campaign.id, profile.id, {
        ...postRequest,
        profile:postRequest.profile,
        campaign:postRequest.campaign,
        notes: [
          ...postRequest.notes??[],
          {
            date: moment().format('YYYY-MM-DD'),
            message: t('I have attached the following file for you to check and approve'),
            author: isAdmin(user) ? EPostRequestNoteAuthor.NativejManager : EPostRequestNoteAuthor.PropertyManager
          }
        ]
      }).then((response) => {
        setPostRequest(response.data);
      }).catch(console.error);
  };

  const handlePostRequestSelection = (postRequest:PostRequest, index:number) => {
    setPostRequest(postRequest);
  };

  const handlePostSchedule = (note:PostRequestNote) => {
    const scheduledPost = {
      profile,
      campaign,
      date: postRequest?.date,
      file: note.attachedFile
    };

    if (!scheduledPost.date) {
      toast({
        severity: 'warn',
        summary: t('No scheduled date'),
        detail: t('To schedule a post you must first insert a posting date')
      });

      return;
    }

    if (!scheduledPost.file) {
      toast({
        severity: 'warn',
        summary: t('No content file'),
        detail: t('To schedule a post you must also select a file created by the property')
      });

      return;
    }
    if (scheduledPost?.profile && scheduledPost.campaign.id && scheduledPost?.profile?.id)
      api.briefController.savePostSchedule(scheduledPost?.campaign?.id, scheduledPost.profile.id, scheduledPost as ScheduledPost).then((response) => {
        if (campaign.id && profile?.id && postRequest && postRequest.profile && postRequest.campaign){
          let validPostRequest:PostRequest =  {
            ...postRequest,
            profile: postRequest.profile,
            notes: postRequest.notes ?? [],
            campaign: postRequest.campaign
          };
          api.briefController.savePostRequestPlanning(campaign.id, profile.id, {
            ...validPostRequest,
            scheduledPost: response.data
          }).then(() => {
            toast({
              severity: 'success',
              summary: DEFAULT_SUMMARY_SUCCESS(t),
              detail: t('The post has been scheduled and will be posted on the scheduled date')
            });
            const index = postRequests.findIndex(o => o.id === postRequest?.id);

            setPostRequests([
              ...postRequests.slice(0, index),
              {
                ...validPostRequest,
                scheduledPost: response.data
              },
              ...postRequests.slice(index + 1)
            ]);
            setPostRequest(null);
          }).catch(console.error);
        }
      }).catch(console.error);
  };

  if (loading) {
    return (
      <div className="p-d-flex p-flex-row p-justify-center">
        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className="p-d-flex p-flex-row p-align-center">
        {postRequest === null && isAdmin(user) && 
          <Button label={t('New Request')} icon="pi pi-plus" className="p-button-outlined" onClick={() => setPostRequest({...postRequestDefaults})} />}
        {postRequest !== null && !postRequest.id && (
          <>
            <Button label={t('Go Back')} icon="pi pi-arrow-left" className="p-button-outlined p-button-secondary" onClick={() => setPostRequest(null)} />
            <Button label={t('Send')} icon="pi pi-send" className="p-ml-2 p-button-outlined p-button-secondary" onClick={handleRequestAnswer} disabled={!note || note.length === 0} />
          </>
        )}
        {postRequest !== null && postRequest.id && (
          <>
            <Button label={t('Go Back')} icon="pi pi-arrow-left" className="p-button-outlined p-button-secondary" onClick={() => setPostRequest(null)} />
          </>
        )}
      </div>
      {postRequest === null && (
        <div className="p-mt-5">
          {postRequests.map((postRequest, postRequestIndex) => (
            <CampaignProfilePostRequest key={postRequest.id} postRequest={postRequest} postRequestIndex={postRequestIndex} onPostRequestSelection={handlePostRequestSelection} />
          ))}
        </div>
      )}
      {postRequest !== null && postRequest.id && postRequest.scheduledPost && (
        <div className="p-mt-5">
          <FacebookPostPreview post={postRequest.scheduledPost} />
        </div>
      )}
      {postRequest !== null && postRequest.id && !postRequest.scheduledPost && (
        <div className="p-mt-5">
          <div className={`${styles.notesWrapper} p-mb-5`}>
            {postRequest.notes?.map((note, index) => {
              const isNativeJManagerAuthor = isAdmin(user) && note.author === 'NATIVEJ_MANAGER';
              const isPropertyManagerAuthor = isPublisherOrInfluencer(user) && note.author === 'PROPERTY_MANAGER';
              const isAuthor = isNativeJManagerAuthor || isPropertyManagerAuthor;
              return (
                <div key={index} className={`${styles.note} p-mb-2 ${isAuthor ? styles.noteAuthor : ''}`}>
                  <p>{note.message}</p>
                  {note.attachedFile && (
                    <div className="p-d-flex p-flex-row p-align-center p-mt-3">
                      <a href={note.attachedFile.url} target="_blank" rel="noreferrer">{t('Click to view attached file')}</a>
                      {isAdmin(user) && <Button className="p-button-link p-my-0 p-mr-0 p-py-0 p-px-0 p-ml-2" style={{ fontSize: '12px' }} label={t('Approve and schedule post')} onClick={() => handlePostSchedule(note)} />}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="p-d-flex p-flex-column">
            <InputTextarea style={{ resize: 'none', width: '100%' }} placeholder={t('Write down your reply in this box and press Send')} rows={3} onChange={e => setNote(e.target.value)} value={note} />
            <div className="p-d-flex p-flex-row p-align-center p-mt-3">
              <Button label={t('Send')} icon="pi pi-send" className="p-ml-auto p-button-outlined p-button-secondary" onClick={handleRequestReply} disabled={!note || note.length === 0} />
              {isPublisherOrInfluencer(user) && <ButtonUpload label={t('Send File')} icon="pi pi-file" className="p-ml-2 p-button-outlined p-button-secondary" onFileSelection={(files:any[]) => {
                if (files.length > 0) {
                  api.fileController.importData1(files[0]).then((response) => {
                    handleFileUploadReply(response.data);
                  }).catch(console.error);
                }
              }} />}
            </div>
          </div>
          {isAdmin(user) && (
            <>
              <h3>{t('CHANGE REQUEST INFORMATION')}</h3>
              <div className="p-d-flex p-flex-column">
                <label className="p-text-uppercase p-opacity-50 p-mb-2  ">{t('Scheduled Date')}</label>
                <Calendar value={postRequest.date && moment(postRequest.date).isValid() ? moment(postRequest.date).toDate() : undefined} onChange={(e) => setPostRequest({...postRequest, date: dateToString(Array.isArray(e.value)?e.value[0]:e.value)})} dateFormat="dd/mm/yy" touchUI appendTo={document.body} placeholder={t('Scheduled Date')} className="p-mb-2" showButtonBar />
                <Button label={t('Save')} icon="pi pi-save" className="p-ml-auto p-mt-3 p-button-outlined p-button-secondary" onClick={handleRequestSave} />
              </div>
            </>
          )}
        </div>
      )}
      {postRequest !== null && !postRequest.id && (
        <div className="p-mt-5">
          <div className="p-d-flex p-flex-column p-mb-2">
            <label className="p-text-uppercase p-opacity-50 p-mb-2  ">{t('Scheduled Date')}</label>
            <Calendar value={stringToDate(postRequest.date)} onChange={(e) => setPostRequest({...postRequest, date: dateToString(Array.isArray(e.value)?e.value[0]:e.value)})} dateFormat="dd/mm/yy" touchUI appendTo={document.body} placeholder={t('Pick a date')} className="p-mb-2" showButtonBar />
          </div>
          <div className="p-d-flex p-flex-column">
            <label className="p-text-uppercase p-opacity-50 p-mb-2  ">{t('Message')}</label>
            <InputTextarea style={{ resize: 'none', width: '100%' }} placeholder={t('Write down your initial request to send to the property')} rows={3} onChange={e => setNote(e.target.value)} />
          </div>
        </div>
      )}
    </div>
  )
}
