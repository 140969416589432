import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import {
  DEFAULT_SUMMARY_SUCCESS,
} from '../../../core/constants';
import { useHistory } from 'react-router-dom';
import config from '../../../core/config';
import { Card } from 'primereact/card';
import styles from './PeoplePage.module.scss';

import { Button } from 'primereact/button';
import api from '../../../core/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Dialog } from 'primereact/dialog';
import SmartForm from '../../shared/SmartForm/SmartForm';
import * as Yup from 'yup';
import {
  isAdmin,
  validationEmailRequired,
  validationString,
  validationStringRequired
} from '../../../core/utils';
import { getUserProfilePictureUrl, ERROR_PROFILE_IMAGE_URL } from '../../../core/LinkUtil'
import { useAppSelector } from '../../../redux/hooks';
import { ToastMessageType } from 'primereact/toast';
import { ERole, User } from '../../../monaco-client';
import { FormikProps, FormikValues } from 'formik';

const tmpUser: User = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: [],
  phone: '',
  company: '',
  isActive: true,
  profilePictureUrl: '',
  dateJoined: '',
  admin: false,
  advertiser: false,
  influencer: false,
  publisher: false
};

const emptyUser = {
  ...tmpUser,
  confirmPassword: ''
}

type PeoplePageProp = {
  toast: (toast: ToastMessageType) => void
  peopleGroup: ERole
}
export default function PeoplePage({ toast, peopleGroup }: PeoplePageProp) {
  const { t } = useTranslation();
  const history = useHistory();
  const smartForm = useRef<FormikProps<any>>(null);
  const [data, setData] = useState<User[]>([]);
  const [userDialog, setUserDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const roles = useAppSelector(s => s.data.roles);
  const user = useAppSelector(s => s.auth.user);

  const fetchPeople = useCallback(() => {
    let roleId = roles.find(role => role.name === peopleGroup)?.id
    api.userController.getUsers(roleId).then((response) => {
      setData(response.data);
    });
  }, [peopleGroup, roles]);

  useEffect(() => {
    if (peopleGroup === ERole.Influencer) {
      document.title = t('Influencers Listing - NativeJ');
      fetchPeople();
    } else if (peopleGroup === ERole.Publisher) {
      document.title = t('Publishers Listing - NativeJ');
      fetchPeople();
    } else if (peopleGroup === ERole.Advertiser) {
      document.title = t('Advertisers Listing - NativeJ');
      fetchPeople();
    } else {
      history.push(config.homepage);
    }
  }, [fetchPeople, history, peopleGroup, t]);



  const getPageTitle = () => {
    if (peopleGroup === ERole.Influencer) {
      return t('Influencers');
    } else if (peopleGroup === ERole.Publisher) {
      return t('Publishers');
    } else if (peopleGroup === ERole.Advertiser) {
      return t('Advertisers');
    }

    return null;
  }

  const profilePictureBodyTemplate = (rowData: User) => {
    return <img src={getUserProfilePictureUrl(rowData)} onError={(e) => { e.currentTarget.src = ERROR_PROFILE_IMAGE_URL }} className={styles.profilePicture} alt={rowData.firstName} />;
  }

  const handleUserSave = (values: FormikValues, { setSubmitting }: any) => {
    if (values.password !== values.confirmPassword) {
      toast({
        severity: 'warn',
        summary: t('Passwords do not match'),
        detail: t('In order to be sure you\'re correctly writing down your passwords, both Password and Confirmation Password must match')
      });

      return;
    }

    setSubmitting(true);
    if (selectedUser) {
      const data: User = {
        ...selectedUser,
        ...values
      };

      // data.roles = data.roles.map(roleName => {
      //   return roles.find(role => role.name === roleName);
      // }).filter(o => o !== null);

      // let roleNames = values.roles;
      // let tmp = roleNames.map((roleName:ERole) => {
      //   return roles.find(role => role.name === roleName);
      // }).filter((o:Role) => o !== null);

      // if(tmp){ data.roles = tmp; }

      //delete data.confirmPassword;

      api.userController.saveUser(data).then(response => {
        setSubmitting(false);
        toast({
          severity: 'success',
          summary: DEFAULT_SUMMARY_SUCCESS(t),
          detail: t('The user account has been updated')
        });

        if (!selectedUser.id) {
          setUserDialog(false);
          setSelectedUser(null);
        }

        fetchPeople();
      }).catch(console.error);
    }
    //
    // if (selectedUser.id) {
    //   promise = api.repositoryPut('users', selectedUser.id, {
    //     ...selectedUser,
    //     ...data
    //   });
    // } else {
    //   promise = api.repositoryPost('users', data);
    // }
    //
    // promise.then(r => {
    //   setSubmitting(false);
    //   toast({
    //     severity: 'success',
    //     summary: DEFAULT_SUMMARY_SUCCESS(t),
    //     detail: t('The user account has been registed to the platform correctly')
    //   });
    //
    //   if (!selectedUser.id) {
    //     setUserDialog(false);
    //     setSelectedUser({});
    //   }
    //
    //   fetchPeople();
    // }).catch(r => {
    //   handleJpaResponse(toast, r, t, {
    //     11000: t('The email address you provided is already in use')
    //   });
    // });

  };

  const handleCreateUser = () => {
    setSelectedUser(emptyUser);
    setUserDialog(true)
  }
  const handleUserDelete = () => {
    if (!selectedUser?.id) {
      return;
    }

    api.userController.deleteUser(selectedUser.id).then(() => {
      toast({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('The user account has been deleted successfully')
      });

      setUserDialog(false);
      setSelectedUser(null);

      fetchPeople();
    }).catch(r => {
      //handleJpaResponse(toast, r, t);
    });
  }

  const actionBodyTemplate = (rowData: User) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined" onClick={() => {
          setSelectedUser({
            ...rowData,
            password: ''
          });
          setUserDialog(true);
        }} />
      </React.Fragment>
    );
  };

  return (
    <StandardLayout>
      <div className="p-p-6">
        <h1 className="page-title">{getPageTitle()}</h1>
        {isAdmin(user) && (
          <div className="p-d-flex p-flex-row p-align-center p-mt-6">
            <Button label={t('CREATE NEW')} icon="far fa-plus-square" className="p-button-sm" onClick={handleCreateUser} />
          </div>
        )}
        <div className="p-grid p-mt-3">
          <div className="p-col-12">
            <Card className={styles.userCard}>
              <DataTable value={data} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                <Column header={t('Profile Picture')} body={profilePictureBodyTemplate} style={{ width: '150px' }} />
                <Column field="firstName" header={t('First Name')} filter sortable />
                <Column field="lastName" header={t('Last Name')} filter sortable />
                <Column field="email" header={t('Email')} filter sortable />
                <Column field="phone" header={t('Phone')} filter sortable />
                {peopleGroup === ERole.Advertiser && <Column field="company" header={t('Company')} filter sortable />}
                <Column body={actionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
              </DataTable>
            </Card>
          </div>
        </div>
      </div>
      <Dialog
        header={t('Create new user')}
        visible={userDialog}
        onHide={() => setUserDialog(false)}
        style={{ width: '98vw', maxWidth: '500px' }}
        footer={(
          <div>
            {selectedUser?.id && <Button label={t('Delete')} icon="pi pi-trash" className="p-button-text p-button-danger" onClick={handleUserDelete} />}
            <Button label={t('Cancel')} className="p-button-text p-button-secondary" onClick={() => {
              setUserDialog(false);
              setSelectedUser(null);
            }} />
            <Button label={t('Confirm')} icon="pi pi-check" className="p-button-text" onClick={() => smartForm.current?.submitForm()} />
          </div>
        )}
      >
        <SmartForm
          ref={smartForm}
          className="p-mt-5"
          onSubmit={handleUserSave}
          initialValues={{
            ...emptyUser,
            ...{
              ...selectedUser,
              //roles: (selectedUser.roles || []).map(role => role.name)
            }
          }}
          fields={[
            { key: 'firstName', label: t('First Name') },
            { key: 'lastName', label: t('Last Name') },
            { key: 'email', label: t('Email address') },
            { key: 'password', label: t('Password'), inputType: 'password' },
            { key: 'confirmPassword', label: t('Confirm Password'), inputType: 'password' },
            { key: 'phone', label: t('Phone Number') },
            {
              key: 'roles',
              label: t('Roles'),
              type: 'list',
              multiple: true,
              options: [
                { value: roles.find(r => r.name === ERole.Publisher), label: t('Publisher') },
                { value: roles.find(r => r.name === ERole.Influencer), label: t('Influencer') },
                { value: roles.find(r => r.name === ERole.Advertiser), label: t('Advertiser') }
              ]
            },
            { key: 'company', label: t('Company') },
            { key: 'isActive', label: t('Active'), type: 'switch' },
            { key: 'profilePictureUrl', label: t('Profile Picture') }
          ]}
          validationSchema={Yup.object().shape({
            firstName: validationStringRequired(t),
            lastName: validationStringRequired(t),
            email: validationEmailRequired(t),
            password: validationString(t),
            confirmPassword: validationString(t),
            phone: validationString(t),
            company: validationString(t).nullable()
          })}
        />
      </Dialog>
    </StandardLayout>
  )
}
