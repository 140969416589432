import {createSlice} from '@reduxjs/toolkit';
import {LANGUAGE_EN} from '../core/constants';
import { RootState } from './store';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    language: LANGUAGE_EN
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    }
  }
});

export const { changeLanguage } = settingsSlice.actions;

export default settingsSlice.reducer;
