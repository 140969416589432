
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { ToastMessageType } from 'primereact/toast';
import { Campaign, User } from '../../../monaco-client';
import { TargetGoal } from './TargetGoal'
import { TargetAudience } from './TargetAudience'
import { GeneralInformation } from "./GeneralInformation";
import { FormikProps } from 'formik';
import { Categories } from "./Categories";
import { Tags } from "./Tags";
import { Interests } from "./Interests";

type EditCampaignDialogProp = {
  toast: (p: ToastMessageType) => void,
  endCallback: (campaign: Campaign, save: boolean) => void,
  show: boolean,
  initialState: Campaign,
  editing?: boolean
}

export default function EditCampaignDialog({ endCallback, show, initialState, editing }: EditCampaignDialogProp) {
  const { t } = useTranslation();

  const smartForm = useRef<FormikProps<User>>(null);
  const [currentCampaign, setCurrentCampaign] = useState({ ...initialState });
  const [end, setEnd] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setCurrentCampaign(initialState)
    setStep(0)
  }, [initialState,show])


  const save = () => {
    if (step === 0 && smartForm?.current && smartForm.current.dirty) {
      smartForm.current.submitForm().then(()=>setEnd(true))
    } else {
      endCallback(currentCampaign, true);
    }
    
  }

  /*
  currentCampaign, when need form commit, is changed in next rendering use "end" state to trigger this method
   */
  useEffect(() => {
    if (end) {
      if (smartForm.current?.isValid){ 
        endCallback(currentCampaign, true);
      }else{ 
        setStep(0);
      }
      setEnd(false);
    }
  }, [end, currentCampaign, endCallback])



  const steps = [
    { label: t('Information') },
    { label: t('Target') },
    { label: t('Audience') },
    { label: t('Categories') },
    { label: t('Interests') },
    { label: t('Tags') }
  ];

  const setNewStep = (val: number) => {
    if (step === 0 && (currentCampaign.publicName==='' ||smartForm?.current?.dirty)) {
      smartForm?.current?.submitForm().then(() => {
        if (Object.keys(smartForm.current!.errors).length === 0) 
          setStep(val);
      })
    } else setStep(val);
  }

  const getTitle = () => {
    switch (step) {
      case 0:
        return t('Campaign Details');
      case 1:
        return t('Campaign Target');
      case 2:
        return 'Choose Your Audience';
      case 3:
        return 'Categories';
      case 4:
        return 'Interests';
      case 5:
        return 'Tags';
      default:
        return null;
    }
  }


  const nextStep = () => {
    if (step < 5) {
      setNewStep(step + 1);
    } else if (step === 5 && !editing) { save(); }
  };


  const hideDialog = () => {
    setStep(0);
    endCallback(currentCampaign, false);
  }




  return <Dialog
    header={getTitle()}
    visible={show}
    position="top"
    maximized
    maximizable
    modal
    style={{ width: '70vw' }}
    onHide={hideDialog}
    focusOnShow={false}
    footer={(
      <div className="p-d-flex p-flex-row p-align-center p-pt-4">
        <Button icon="pi pi-arrow-left"
          label={t('Back')}
          className="p-ml-auto p-button-lg p-button-secondary p-button-outlined"
          onClick={() => setStep(step - 1)}
          disabled={step <= 0}
        />
        <Button icon="pi pi-arrow-right"
          label={
            (step === 5 && !editing) ? t('Complete') :
              t('Next Step')}
          disabled={editing && step >= 5}
          className={'p-ml-2 p-button-lg p-button-outlined p-button-info'} onClick={nextStep} />
        {editing && <Button
          label={t("Save")}
          className={' p-button-lg p-button-secondary p-button-info'}
          onClick={save}
        />}
      </div>
    )}>

    <div className="p-py-6">
      <Steps model={steps} activeIndex={step} onSelect={(e: any) => setNewStep(e.index)} readOnly={!editing} />
      {step === 0 && (
        <GeneralInformation currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign} smartForm={smartForm} editing={editing} />
      )}
      {step === 1 && (
        <TargetGoal currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign}></TargetGoal>
      )}
      {step === 2 && (
        <TargetAudience currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign}></TargetAudience>
      )}
      {step === 3 && (
        <Categories currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign}></Categories>
      )}
      {step === 4 && (
        <Interests currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign} />
      )}
      {step === 5 && (
        <Tags currentCampaign={currentCampaign} setCurrentCampaign={setCurrentCampaign} />
      )}
    </div>
  </Dialog>
}
