/* tslint:disable */
/* eslint-disable */
/**
 * Monaco API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EGender } from '../model';
// @ts-ignore
import { ESocialPlatform } from '../model';
// @ts-ignore
import { InlineObject1 } from '../model';
// @ts-ignore
import { PageImplProfile } from '../model';
// @ts-ignore
import { Profile } from '../model';
// @ts-ignore
import { ProfileScreen } from '../model';
/**
 * SocialControllerApi - axios parameter creator
 * @export
 */
export const SocialControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} social 
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectProperties: async (social: string, accessToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'social' is not null or undefined
            assertParamExists('connectProperties', 'social', social)
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('connectProperties', 'accessToken', accessToken)
            const localVarPath = `/social/{social}/connect-properties`
                .replace(`{${"social"}}`, encodeURIComponent(String(social)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} social 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCodeToToken: async (social: string, code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'social' is not null or undefined
            assertParamExists('convertCodeToToken', 'social', social)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('convertCodeToToken', 'code', code)
            const localVarPath = `/social/{social}/convert`
                .replace(`{${"social"}}`, encodeURIComponent(String(social)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProfile', 'id', id)
            const localVarPath = `/social/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} social 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendShortLivedToken: async (social: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'social' is not null or undefined
            assertParamExists('extendShortLivedToken', 'social', social)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('extendShortLivedToken', 'token', token)
            const localVarPath = `/social/{social}/extend-token`
                .replace(`{${"social"}}`, encodeURIComponent(String(social)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProfiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/social/profiles/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScreens: async (profileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('getProfileScreens', 'profileId', profileId)
            const localVarPath = `/social/profile/{profileId}/screen`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {number} page 
         * @param {string} [name] 
         * @param {string} [identification] 
         * @param {ESocialPlatform} [social] 
         * @param {string} [type] 
         * @param {string} [followers] 
         * @param {string} [reach] 
         * @param {string} [likes] 
         * @param {string} [comments] 
         * @param {string} [views] 
         * @param {Array<string>} [categories] 
         * @param {Array<string>} [interests] 
         * @param {Array<string>} [tags] 
         * @param {string} [location] 
         * @param {Array<string>} [country] 
         * @param {Array<EGender>} [gender] 
         * @param {Array<string>} [ageRange] 
         * @param {string} [orderByFollowers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles: async (size: number, page: number, name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getProfiles', 'size', size)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getProfiles', 'page', page)
            const localVarPath = `/social/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (identification !== undefined) {
                localVarQueryParameter['identification'] = identification;
            }

            if (social !== undefined) {
                localVarQueryParameter['social'] = social;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (followers !== undefined) {
                localVarQueryParameter['followers'] = followers;
            }

            if (reach !== undefined) {
                localVarQueryParameter['reach'] = reach;
            }

            if (likes !== undefined) {
                localVarQueryParameter['likes'] = likes;
            }

            if (comments !== undefined) {
                localVarQueryParameter['comments'] = comments;
            }

            if (views !== undefined) {
                localVarQueryParameter['views'] = views;
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }

            if (interests) {
                localVarQueryParameter['interests'] = interests;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (country) {
                localVarQueryParameter['country'] = country;
            }

            if (gender) {
                localVarQueryParameter['gender'] = gender;
            }

            if (ageRange) {
                localVarQueryParameter['ageRange'] = ageRange;
            }

            if (orderByFollowers !== undefined) {
                localVarQueryParameter['orderByFollowers'] = orderByFollowers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importData: async (contentType?: 'multipart/_*', inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/social/profiles/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} social 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSocialLogin: async (social: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'social' is not null or undefined
            assertParamExists('retrieveSocialLogin', 'social', social)
            const localVarPath = `/social/{social}/login`
                .replace(`{${"social"}}`, encodeURIComponent(String(social)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Profile} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProfile: async (profile: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('saveProfile', 'profile', profile)
            const localVarPath = `/social/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProfileScreens: async (profileId: string, profileScreen: Array<ProfileScreen>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('saveProfileScreens', 'profileId', profileId)
            // verify required parameter 'profileScreen' is not null or undefined
            assertParamExists('saveProfileScreens', 'profileScreen', profileScreen)
            const localVarPath = `/social/profile/{profileId}/screen`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileScreen, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileScreens: async (profileId: string, profileScreen: Array<ProfileScreen>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('updateProfileScreens', 'profileId', profileId)
            // verify required parameter 'profileScreen' is not null or undefined
            assertParamExists('updateProfileScreens', 'profileScreen', profileScreen)
            const localVarPath = `/social/profile/{profileId}/delete-screen`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileScreen, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialControllerApi - functional programming interface
 * @export
 */
export const SocialControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} social 
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectProperties(social: string, accessToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectProperties(social, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} social 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertCodeToToken(social: string, code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertCodeToToken(social, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} social 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendShortLivedToken(social: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendShortLivedToken(social, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProfiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Profile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileScreens(profileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileScreen>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileScreens(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {number} page 
         * @param {string} [name] 
         * @param {string} [identification] 
         * @param {ESocialPlatform} [social] 
         * @param {string} [type] 
         * @param {string} [followers] 
         * @param {string} [reach] 
         * @param {string} [likes] 
         * @param {string} [comments] 
         * @param {string} [views] 
         * @param {Array<string>} [categories] 
         * @param {Array<string>} [interests] 
         * @param {Array<string>} [tags] 
         * @param {string} [location] 
         * @param {Array<string>} [country] 
         * @param {Array<EGender>} [gender] 
         * @param {Array<string>} [ageRange] 
         * @param {string} [orderByFollowers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiles(size: number, page: number, name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageImplProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiles(size, page, name, identification, social, type, followers, reach, likes, comments, views, categories, interests, tags, location, country, gender, ageRange, orderByFollowers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importData(contentType?: 'multipart/_*', inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importData(contentType, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} social 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSocialLogin(social: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSocialLogin(social, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Profile} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProfile(profile: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProfile(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileScreen>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProfileScreens(profileId, profileScreen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfileScreens(profileId, profileScreen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SocialControllerApi - factory interface
 * @export
 */
export const SocialControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} social 
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectProperties(social: string, accessToken: string, options?: any): AxiosPromise<object> {
            return localVarFp.connectProperties(social, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} social 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCodeToToken(social: string, code: string, options?: any): AxiosPromise<string> {
            return localVarFp.convertCodeToToken(social, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} social 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendShortLivedToken(social: string, token: string, options?: any): AxiosPromise<string> {
            return localVarFp.extendShortLivedToken(social, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProfiles(options?: any): AxiosPromise<Array<Profile>> {
            return localVarFp.getAllProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScreens(profileId: string, options?: any): AxiosPromise<Array<ProfileScreen>> {
            return localVarFp.getProfileScreens(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {number} page 
         * @param {string} [name] 
         * @param {string} [identification] 
         * @param {ESocialPlatform} [social] 
         * @param {string} [type] 
         * @param {string} [followers] 
         * @param {string} [reach] 
         * @param {string} [likes] 
         * @param {string} [comments] 
         * @param {string} [views] 
         * @param {Array<string>} [categories] 
         * @param {Array<string>} [interests] 
         * @param {Array<string>} [tags] 
         * @param {string} [location] 
         * @param {Array<string>} [country] 
         * @param {Array<EGender>} [gender] 
         * @param {Array<string>} [ageRange] 
         * @param {string} [orderByFollowers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles(size: number, page: number, name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string, options?: any): AxiosPromise<PageImplProfile> {
            return localVarFp.getProfiles(size, page, name, identification, social, type, followers, reach, likes, comments, views, categories, interests, tags, location, country, gender, ageRange, orderByFollowers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'multipart/_*'} [contentType] 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importData(contentType?: 'multipart/_*', inlineObject1?: InlineObject1, options?: any): AxiosPromise<object> {
            return localVarFp.importData(contentType, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} social 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSocialLogin(social: string, options?: any): AxiosPromise<string> {
            return localVarFp.retrieveSocialLogin(social, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Profile} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProfile(profile: Profile, options?: any): AxiosPromise<Profile> {
            return localVarFp.saveProfile(profile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): AxiosPromise<Array<ProfileScreen>> {
            return localVarFp.saveProfileScreens(profileId, profileScreen, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} profileId 
         * @param {Array<ProfileScreen>} profileScreen 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): AxiosPromise<object> {
            return localVarFp.updateProfileScreens(profileId, profileScreen, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialControllerApi - interface
 * @export
 * @interface SocialControllerApi
 */
export interface SocialControllerApiInterface {
    /**
     * 
     * @param {string} social 
     * @param {string} accessToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    connectProperties(social: string, accessToken: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} social 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    convertCodeToToken(social: string, code: string, options?: any): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    deleteProfile(id: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} social 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    extendShortLivedToken(social: string, token: string, options?: any): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    getAllProfiles(options?: any): AxiosPromise<Array<Profile>>;

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    getProfileScreens(profileId: string, options?: any): AxiosPromise<Array<ProfileScreen>>;

    /**
     * 
     * @param {number} size 
     * @param {number} page 
     * @param {string} [name] 
     * @param {string} [identification] 
     * @param {ESocialPlatform} [social] 
     * @param {string} [type] 
     * @param {string} [followers] 
     * @param {string} [reach] 
     * @param {string} [likes] 
     * @param {string} [comments] 
     * @param {string} [views] 
     * @param {Array<string>} [categories] 
     * @param {Array<string>} [interests] 
     * @param {Array<string>} [tags] 
     * @param {string} [location] 
     * @param {Array<string>} [country] 
     * @param {Array<EGender>} [gender] 
     * @param {Array<string>} [ageRange] 
     * @param {string} [orderByFollowers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    getProfiles(size: number, page: number, name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string, options?: any): AxiosPromise<PageImplProfile>;

    /**
     * 
     * @param {'multipart/_*'} [contentType] 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    importData(contentType?: 'multipart/_*', inlineObject1?: InlineObject1, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {string} social 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    retrieveSocialLogin(social: string, options?: any): AxiosPromise<string>;

    /**
     * 
     * @param {Profile} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    saveProfile(profile: Profile, options?: any): AxiosPromise<Profile>;

    /**
     * 
     * @param {string} profileId 
     * @param {Array<ProfileScreen>} profileScreen 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    saveProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): AxiosPromise<Array<ProfileScreen>>;

    /**
     * 
     * @param {string} profileId 
     * @param {Array<ProfileScreen>} profileScreen 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApiInterface
     */
    updateProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any): AxiosPromise<object>;

}

/**
 * SocialControllerApi - object-oriented interface
 * @export
 * @class SocialControllerApi
 * @extends {BaseAPI}
 */
export class SocialControllerApi extends BaseAPI implements SocialControllerApiInterface {
    /**
     * 
     * @param {string} social 
     * @param {string} accessToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public connectProperties(social: string, accessToken: string, options?: any) {
        return SocialControllerApiFp(this.configuration).connectProperties(social, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} social 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public convertCodeToToken(social: string, code: string, options?: any) {
        return SocialControllerApiFp(this.configuration).convertCodeToToken(social, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public deleteProfile(id: string, options?: any) {
        return SocialControllerApiFp(this.configuration).deleteProfile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} social 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public extendShortLivedToken(social: string, token: string, options?: any) {
        return SocialControllerApiFp(this.configuration).extendShortLivedToken(social, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public getAllProfiles(options?: any) {
        return SocialControllerApiFp(this.configuration).getAllProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public getProfileScreens(profileId: string, options?: any) {
        return SocialControllerApiFp(this.configuration).getProfileScreens(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {number} page 
     * @param {string} [name] 
     * @param {string} [identification] 
     * @param {ESocialPlatform} [social] 
     * @param {string} [type] 
     * @param {string} [followers] 
     * @param {string} [reach] 
     * @param {string} [likes] 
     * @param {string} [comments] 
     * @param {string} [views] 
     * @param {Array<string>} [categories] 
     * @param {Array<string>} [interests] 
     * @param {Array<string>} [tags] 
     * @param {string} [location] 
     * @param {Array<string>} [country] 
     * @param {Array<EGender>} [gender] 
     * @param {Array<string>} [ageRange] 
     * @param {string} [orderByFollowers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public getProfiles(size: number, page: number, name?: string, identification?: string, social?: ESocialPlatform, type?: string, followers?: string, reach?: string, likes?: string, comments?: string, views?: string, categories?: Array<string>, interests?: Array<string>, tags?: Array<string>, location?: string, country?: Array<string>, gender?: Array<EGender>, ageRange?: Array<string>, orderByFollowers?: string, options?: any) {
        return SocialControllerApiFp(this.configuration).getProfiles(size, page, name, identification, social, type, followers, reach, likes, comments, views, categories, interests, tags, location, country, gender, ageRange, orderByFollowers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'multipart/_*'} [contentType] 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public importData(contentType?: 'multipart/_*', inlineObject1?: InlineObject1, options?: any) {
        return SocialControllerApiFp(this.configuration).importData(contentType, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} social 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public retrieveSocialLogin(social: string, options?: any) {
        return SocialControllerApiFp(this.configuration).retrieveSocialLogin(social, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Profile} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public saveProfile(profile: Profile, options?: any) {
        return SocialControllerApiFp(this.configuration).saveProfile(profile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {Array<ProfileScreen>} profileScreen 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public saveProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any) {
        return SocialControllerApiFp(this.configuration).saveProfileScreens(profileId, profileScreen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} profileId 
     * @param {Array<ProfileScreen>} profileScreen 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialControllerApi
     */
    public updateProfileScreens(profileId: string, profileScreen: Array<ProfileScreen>, options?: any) {
        return SocialControllerApiFp(this.configuration).updateProfileScreens(profileId, profileScreen, options).then((request) => request(this.axios, this.basePath));
    }
}
