
import {setToken, setUser} from '../../../redux/authSlice';
import { useAppDispatch } from '../../../redux/hooks';

export default function LogoutPage() {
  const dispatch = useAppDispatch();
  dispatch(setUser());
  dispatch(setToken());

  return (<span/>)
}
