import './App.scss';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AuthRoute from './components/wrappers/AuthRoute/AuthRoute';
import config from './core/config';
import LoginPage from './components/pages/LoginPage/LoginPage';
import LogoutPage from './components/pages/LogoutPage/LogoutPage';
import PeoplePage from './components/pages/PeoplePage/PeoplePage';
import RegisterPage from './components/pages/RegisterPage/RegisterPage';
import {useDispatch} from 'react-redux';
import {createContext, useEffect, useRef} from 'react';
import api from './core/api';
import {setCategories, setCountries, setInterests, setRoles, setTags} from './redux/dataSlice';
import {Toast, ToastMessageType} from 'primereact/toast';
import HomePage from './components/pages/HomePage/HomePage';
import ProfilesPage from './components/pages/ProfilesPage/ProfilesPage';
import CampaignsPage from './components/pages/CampaignsPage/CampaignsPage';
import CampaignPage from './components/pages/CampaignPage/CampaignPage';
import ProfilePage from './components/pages/ProfilePage/ProfilePage';
import * as numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from './redux/hooks';
import { ERole } from './monaco-client';
export const ToastContext = createContext((t:ToastMessageType)=>{});
try {
  numeral.register('locale', 'it', {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    currency: {
      symbol: '€'
    },
    ordinal: (num:number)=>'º'
  });
} catch (error) {
  console.log("error in register numeral locale");
  console.log(error);
}

numeral.locale('it');

function App() {
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);
  const token = useAppSelector(s=>s.auth.token)
  const { t } = useTranslation();
  useEffect(() => {
    if (token) {
      
      api.dataController.getCategories().then(response => {
        dispatch(setCategories(response.data));
      }).catch(console.error);

      api.dataController.getInterests().then(response => {
        dispatch(setInterests(response.data));
      }).catch(console.error);

      api.dataController.getTags().then(response => {
        dispatch(setTags(response.data));
      }).catch(console.error);

      api.dataController.getCountries().then(r => {
        dispatch(setCountries(r.data));
      }).catch(console.error);


    }
  }, [dispatch, token]);

  useEffect(() => {
    api.authController.getRoles().then(response => {
      if(response.data.data)
        dispatch(setRoles(response.data.data));
      else{
        console.log("role empty")
        console.log(response)
      }
    }).catch(console.error);
  }, [dispatch, token]);

  const toast = (toast:ToastMessageType) => {
    if (!toastRef.current) {
      return;
    }

    toastRef.current.show(toast);
  };
  api.createToastInterceptor(toast,t);
  
  return (
    <>
      <ToastContext.Provider value ={toast}>
      
      <BrowserRouter>
        <Switch>
          <AuthRoute path="/campaign/:id" exact forceLoggedIn>
            <CampaignPage toast={toast} />
          </AuthRoute>
          <AuthRoute path="/campaigns" exact forceLoggedIn>
            <CampaignsPage/>
          </AuthRoute>
          <AuthRoute path="/advertisers" exact forceLoggedIn>
            <PeoplePage toast={toast} peopleGroup={ERole.Advertiser} />
          </AuthRoute>
          <AuthRoute path="/profiles" exact forceLoggedIn>
            <ProfilesPage toast={toast} />
          </AuthRoute>
          <AuthRoute path="/profile/:id" exact forceLoggedIn>
            <ProfilePage toast={toast} />
          </AuthRoute>
          <AuthRoute path="/influencers" exact forceLoggedIn>
            <PeoplePage toast={toast} peopleGroup={ERole.Influencer} />
          </AuthRoute>
          <AuthRoute path="/publishers" exact forceLoggedIn>
            <PeoplePage toast={toast} peopleGroup={ERole.Publisher} />
          </AuthRoute>
          <AuthRoute path={config.homepage} exact forceLoggedIn>
            <HomePage toast={toast} />
          </AuthRoute>
          <AuthRoute path="/login" exact forceLoggedOut>
            <LoginPage toast={toast} />
          </AuthRoute>
          <AuthRoute path="/logout" exact forceLoggedIn>
            <LogoutPage />
          </AuthRoute>
          <AuthRoute path="/register" exact forceLoggedOut>
            <RegisterPage toast={toast} />
          </AuthRoute>
          <Route path="/" exact>
            <Redirect to={config.homepage} />
          </Route>
          <Route path="**">
            <p>404</p>
          </Route>
        </Switch>
      </BrowserRouter>
   
      </ToastContext.Provider>
      <Toast ref={toastRef} />
    </>
  );
}

export default App;
