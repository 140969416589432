import { Campaign, User } from '../../../monaco-client';
import { Chip } from 'primereact/chip';
import { Card } from 'primereact/card';
import styles from '../../shared/SummaryResult/CampaignPage.module.scss';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { getGoalTargetValue } from '../../../core/campaingUtils';
import { TARGET_LOCATION } from '../../../core/constants';
import { statusBodyTemplate } from "../../../core/campaingUtils";
import { isAdmin } from '../../../core/utils';


type CampaignHeaderProp = {
  toast: (p: any) => void,
  campaign: Campaign,

  user?: User,
}

export const CampaignHeader = ({ toast, campaign, user }: CampaignHeaderProp) => {
  const { t } = useTranslation();
  return (<>

    <div className="p-col-12">
      <Card className={styles.noPaddingCard}>
        {user && (
          <table className="p-table">
            <tbody>
              <tr>
                <td className="p-table-header">{t('Public Name')}</td>
                <td>{campaign.publicName}</td>
                {isAdmin(user) && (
                  <> 
                  <td className="p-table-header">{t('Private Name')}</td>  
                  <td>{campaign.privateName}</td>
                </>)}
                <td className="p-table-header">{t('Brand')}</td>
                <td>{campaign.brand}</td>
                <td className="p-table-header">{t('Status')}</td>
                <td>{statusBodyTemplate(campaign,t)}</td>
              </tr>
              <tr>
                <td className="p-table-header">{t('Start Date')}</td>
                <td>{campaign.startDate}</td>
                <td className="p-table-header">{t('End Date')}</td>
                <td>{campaign.endDate}</td>
                <td className="p-table-header">{t('Advertiser')}</td>
                <td>{campaign?.advertiser ? (campaign?.advertiser?.firstName + ' ' + campaign?.advertiser?.lastName) : ''}</td>
                {isAdmin(user) && (
                  <> 
                <td className="p-table-header">{t('Budget')}</td>
                <td>{numeral(campaign.budget).format('$0')}</td>
                </>)}
              </tr>
              <tr>
                <td className="p-table-header">{t('Main Goal')}</td>
                <td>{campaign.mainGoal}</td>
                {isAdmin(user) && (
                  <> 
                <td className="p-table-header">{t('Main Goal')}</td>
                <td>{numeral(campaign.mainGoal && getGoalTargetValue(campaign, campaign.mainGoal)).format('0.0a')}</td>
                </>)}
                <td className="p-table-header">{t('Secondary Goal')}</td>
                <td>{campaign.secondaryGoal}</td>
                {isAdmin(user) && (
                  <> 
                <td className="p-table-header">{t('Secondary Goal')}</td>
                <td>{numeral(campaign.secondaryGoal && getGoalTargetValue(campaign, campaign.secondaryGoal)).format('0.0a')}</td>
                </>)}
              </tr>
              {isAdmin(user) && (
                  <> 
              <tr>
                <td className="p-table-header">{t('Target Gender')}</td>
                <td colSpan={3}>{campaign.gender?.map(v => (<Chip key={v} label={v} />))}</td>
                <td className="p-table-header">{t('Target Age Range')}</td>
                <td colSpan={3}>{campaign.ageRange?.map(v => (<Chip key={v} label={v} />))}</td>
              </tr>
              <tr>
                <td className="p-table-header">{t('Categories')}</td>
                <td colSpan={3}>{campaign.categories?.map(v => (<Chip key={v.id} label={v.name} />))}</td>
                <td className="p-table-header">{t('Interests')}</td>
                <td colSpan={3}>{campaign.interests?.map(v => (<Chip key={v.id} label={v.name} />))}</td>
              </tr>
              <tr>
                <td className="p-table-header">{t('Tags')}</td>
                <td colSpan={3}>{campaign.tags?.map(v => (<Chip key={v.id} label={v.name} />))}</td>
                <td className="p-table-header">{t('Location')}</td>
                <td colSpan={3}>{campaign.geolocation?.map(v => TARGET_LOCATION(t).find(l => l.value === v)?.label ?? v).map(v => (<Chip key={v} label={v} />))}</td>
              </tr>
              <tr>
                <td className="p-table-header">{t('Note')}</td>
                <td colSpan={8} > {campaign.note}</td>
              </tr>
                </>)}
            </tbody>
          </table>
        )}


      </Card>
    </div>




  </>)

}

export default CampaignHeader

