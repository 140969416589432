import * as Yup from 'yup';
import moment from 'moment';
import React from 'react';
import {DEFAULT_SUMMARY_ERROR, DEFAULT_SUMMARY_SUCCESS} from './constants';
import { Calendar as Cal } from '@fullcalendar/core';
import {Calendar} from 'primereact/calendar';
import {InputNumber} from 'primereact/inputnumber';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import _, { isArray } from 'lodash';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataAccessType, ERole, User } from '../monaco-client';
import { TFunction } from 'i18next';
import { FormikValues } from 'formik';
import { Field } from '../components/shared/SmartForm/SmartForm';
import { ToastMessageType } from 'primereact/toast';
import { AxiosResponse } from 'axios';

export const validationEmailRequired = (t:TFunction) => Yup.string().email(t('Email address invalid')).required(t('Required field'));
export const validationEmail = (t:TFunction) => Yup.string().email(t('Email address invalid'));
export const validationStringRequired = (t:TFunction) => Yup.string().required(t('Required field'));
export const validationString = (t:TFunction)=> Yup.string();
export const validationNumber = (t:TFunction)=> Yup.number();
export const validationNumberRequired = (t:TFunction) => validationNumber(t).required(t('Required field'));
export const validationUrl = (t:TFunction)=> Yup.string().url(t('URL address invalid'));
export const validationDate = (t:TFunction) => Yup.date();
export const validationDateRequired = (t:TFunction) => Yup.date().required(t('Required field'));
export const validationBoolean = (t:TFunction) => Yup.boolean();
export const validationBooleanRequired = (t:TFunction) => Yup.boolean().required(t('Required field'));
export const validationArrayRequired = (t:TFunction) => Yup.array(t('The field must be a list')).required(t('Required field'));

// export const getValueFromType = (type, item, defaultValue = undefined) => {
//   switch (type) {
//     case 'boolean':
//     case 'switch':
//       return defaultValue !== undefined ? defaultValue : false;

//     case 'list':
//       if (item.multiple === true) {
//         return defaultValue !== undefined ? defaultValue : [];
//       }

//       return defaultValue !== undefined ? defaultValue : null;

//     case 'object':
//       return defaultValue !== undefined ? defaultValue : {};

//     case 'number':
//       return defaultValue !== undefined ? defaultValue : -1;

//     default:
//       return defaultValue !== undefined ? defaultValue : '';
//   }
// }

// export const mapToRecord = map => map.reduce((acc, curr) => ({
//   ...acc,
//   [curr.key]: getValueFromType(curr.type, curr, curr.defaultValue)
// }), {});

// export const mapToValidationSchema = map => Yup.object().shape(map.filter(item => item.validation !== undefined && item.validation !== null).reduce((acc, curr) => ({
//   ...acc,
//   [curr.key]: curr.validation
// }), {}));

// export const mapToFields = map => map.map(item => ({
//   key: item.key,
//   label: item.header,
//   type: item.type,
//   inputType: item.inputType,
//   defaultValue: item.defaultValue,
//   data: item.data,
//   optionValue: item.optionValue,
//   optionLabel: item.optionLabel,
//   multiple: item.multiple
// }));

// export const transformRecordWithMap = (record, map) => {
//   if (!record) {
//     return record;
//   }

//   const transformedRecord = { ...record };

//   map.forEach(item => {
//     if (!transformedRecord.hasOwnProperty(item.key)) {
//       return;
//     }

//     if (!transformedRecord[item.key]) {
//       return;
//     }

//     if (item.type === 'list' && item.multiple === true) {
//       transformedRecord[item.key] = transformedRecord[item.key].map(o => {
//         if (typeof o !== 'object') {
//           return o;
//         }

//         return o[item.optionValue || 'value'];
//       });
//     } else if (item.type === 'list' && item.multiple !== true && typeof transformedRecord[item.key] === 'object') {
//       transformedRecord[item.key] = transformedRecord[item.key][item.optionValue || 'value'];
//     }
//   });

//   console.log({...transformedRecord});

//   return transformedRecord;
// };

// export const getContrast = hexcolor => {
//   // If a leading # is provided, remove it
//   if (hexcolor.slice(0, 1) === '#') {
//     hexcolor = hexcolor.slice(1);
//   }

//   // If a three-character hexcode, make six-character
//   if (hexcolor.length === 3) {
//     hexcolor = hexcolor.split('').map(function (hex) {
//       return hex + hex;
//     }).join('');
//   }

//   // Convert to RGB value
//   var r = parseInt(hexcolor.substr(0,2),16);
//   var g = parseInt(hexcolor.substr(2,2),16);
//   var b = parseInt(hexcolor.substr(4,2),16);

//   // Get YIQ ratio
//   var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

//   // Check contrast
//   return (yiq >= 128) ? 'black' : 'white';

// };

// export const formikValuesOut = (values, map = []) => {
//   const newValues = {...values};

//   map.forEach(item => {
//     if (!newValues.hasOwnProperty(item.key)) {
//       return;
//     }

//     if (item.type === 'date') {
//       newValues[item.key] = moment(newValues[item.key]).format('YYYY-MM-DD');
//     } else if (item.type === 'datetime') {
//       newValues[item.key] = moment(newValues[item.key]).format('YYYY-MM-DD HH:mm');
//     }
//   });

//   return newValues;
// };

export const formikValuesIn = (values:FormikValues, map:Field[] = []) => {
  const newValues = {...values};

  map.forEach(item => {
    if (!newValues.hasOwnProperty(item.key)) {
      return;
    }

    const value = newValues[item.key];

    if (item.type === 'date') {
      newValues[item.key] = moment(value).toDate();
    } else if (item.type === 'datetime') {
      newValues[item.key] = moment(value).toDate();
    } else if (item.type === 'time') {
      newValues[item.key] = moment(value).toDate();
    } else if (item.type === 'switch') {
      newValues[item.key] = typeof value === 'string' ? value === '1' : (typeof value === 'number' ? value === 1 : value);
    }
  });

  return newValues;
};

export const showResponseToast = (toast: (toast: ToastMessageType) => void, response:AxiosResponse, t:TFunction) => {
  let severity;
  const { code, message } = response.data;


  switch (code) {
    case 'ERROR':
      severity = 'error';
      break;

    case 'WARN':
      severity = 'warn';
      break;

    case 'INFO':
      severity = 'info';
      break;

    case 'SUCCESS':
      severity = 'success';
      break;

    default:
  }

  if (severity && message !== 'OK') {
    toast({
      severity,
      summary: code === 'SUCCESS' ? DEFAULT_SUMMARY_SUCCESS(t) : DEFAULT_SUMMARY_ERROR(t),
      detail: message || t('Unfortunately we do not know what happened, please contact us')
    });
  }
};

export const getEditingDecorator = (editing:boolean, source:any, handleEditChange:any, key:string, still:JSX.Element, field:any, options:any = {}) => {
  if (editing) {
    if (React.isValidElement(field)) {
      return field;
    }

    if (field === 'date') {
      return <Calendar className="w-100" value={moment(source[key]).toDate()} onChange={(e) => handleEditChange(key, moment((isArray(e.value)?e.value[0]:e.value)).format('YYYY-MM-DD'))} dateFormat="dd/mm/yy" {...(options.inputProps || {})} />;
    }

    if (field === 'number') {
      return <InputNumber className="w-100" value={source[key]} onChange={event => handleEditChange(key, event.value)} {...(options.inputProps || {})} />;
    }
    if (field === 'textArea') {
      return <InputTextarea className="w-100" value={source[key]} onChange={event => handleEditChange(key, event.target.value)} {...(options.inputProps || {})} />;
    }

    if (field === 'list') {
      const list = (options.data || []).map((item:any) => {
        if (typeof item === 'string') {
          return {
            value: item,
            label: item
          };
        }

        return item;
      });
    
      return <Dropdown className="w-100" value={options.initialValue || source[key]} options={list} optionLabel={options.optionLabel || 'label'} optionValue={options.optionValue  || 'value'} onChange={e => handleEditChange(key, e.value)} showClear={options.hideClear !== true} {...(options.inputProps || {})} />;
    }

    return <InputText className="w-100 p-col" value={source[key]} onChange={event => handleEditChange(key, event.target.value)} {...(options.inputProps || {})} />;
  }

  return still || source[key];
}

// export const handleJpaResponse = (toast, response, t, messages = {}) => {
//   const errorCode = _.get(response, 'data.throwable.cause.code', null);

//   switch (errorCode) {
//     case 11000:
//       let message = t('The information you provided is duplicated and cannot be inserted');

//       if (messages.hasOwnProperty(11000)) {
//         message = messages[11000];
//       }

//       toast({
//         severity: 'error',
//         summary: DEFAULT_SUMMARY_ERROR(t),
//         detail: message
//       });

//       break;

//     default:
//       let detail = response.stack??t('We have encountered an unknown error, please contact and inform us about the error')
//       toast({
//         severity: 'error',
//         summary: DEFAULT_SUMMARY_ERROR(t),
//         detail
//       });
//   }
// }

export const tableNumberFilter = (value:number|undefined|null, filter:string|undefined|null) => {
  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }

  if (filter.startsWith('>=')) {
    const filterValue = Number(filter.replace('>=', '').trim());

    return value >= filterValue;
  } else if (filter.startsWith('>')) {
    const filterValue = Number(filter.replace('>', '').trim());

    return value > filterValue;
  } else if (filter.startsWith('<=')) {
    const filterValue = Number(filter.replace('<=', '').trim());

    return value <= filterValue;
  } else if (filter.startsWith('<')) {
    const filterValue = Number(filter.replace('<', '').trim());

    return value < filterValue;
  } else if (filter.startsWith('!')) {
    const filterValue = Number(filter.replace('!', '').trim());

    return filterValue !== value;
  }

  return Number(filter) === value;
};

export const getPastDays = (numberOfDays:number, format:string) => {
  let date = moment();
  let past = [];
  past = [date.format(format)];

  for (let a = 1; a < numberOfDays; a++) {
    date = moment().subtract(a, 'days');
    past = [
      date.format(format),
      ...past
    ];
  }

  return past;
};

export const getDate = (numberOfDays:number, format:string) => {
  return moment().subtract(numberOfDays, 'days').format(format);
};

export const isAdmin = (user:User|undefined|null) => {
  return (user?.roles?.findIndex(role => role.name === ERole.Admin)??-1) > -1;
};

export const isPublisherOrInfluencer =  (user:User|undefined|null)  => {
  return (user?.roles?.findIndex(role => role.name === ERole.Publisher || role.name === ERole.Advertiser)??-1) > -1;
};

export const isAdvertiser =  (user:User|undefined|null)  => {
  return (user?.roles?.findIndex(role => role.name === ERole.Advertiser)??-1) > -1;
};

export  const getDataAccessBadge = (dataAccess:DataAccessType|null|undefined) => {
  if(dataAccess === DataAccessType.Advanced){
    return 'success';
  }
  else if (dataAccess === DataAccessType.Public){
    return 'warning';
  }
  else return 'danger';
}
