import React, { useState } from 'react';
import { Campaign, CampaignCostEstimate, Profile, User, EPriceType, ESocialPlatform } from '../../../monaco-client';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import styles from '../../shared/SummaryResult/CampaignPage.module.scss';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import api from '../../../core/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { DEFAULT_SUMMARY_SUCCESS, PRICE_PLATFORMS } from '../../../core/constants';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

const defaultCostEstimate: CampaignCostEstimate = {
    profile: undefined,
    packageName: undefined,
    platform: undefined,
    units: 1,
    type: undefined,
    cost: undefined
};

type BriefPlanCostProp = {
    toast: (p: any) => void,
    campaign: Campaign,
    user?: User,
    setCampaign: React.Dispatch<React.SetStateAction<Campaign>>
}

export const BriefPlanCost = ({ toast, campaign, user, setCampaign }: BriefPlanCostProp) => {
    const { t } = useTranslation();
    const [costEstimateDialog, setCostEstimateDialog] = useState(false);
    const [costEstimate, setCostEstimate] = useState<CampaignCostEstimate>(defaultCostEstimate);


    const showCostEstimateDialog = () => {
        setCostEstimate({ ...defaultCostEstimate });
        setCostEstimateDialog(true);
    }

    const getEstimatedCampaignCost = () => {
        let cost = 0;

        (campaign.costEstimates || []).forEach(costEstimate => {
            cost += costEstimate?.cost ?? 0;
        });

        return cost;
    }

    const getCostEstimate = () => {
        let cost = 0;
        const prices = ((costEstimate.profile && costEstimate.profile.prices) || []).sort((a, b) => (a.units ?? 0) > (b.units ?? 0) ? -1 : 1);

        if (costEstimate.profile && costEstimate.packageName && (costEstimate.units ?? 0) > 0) {
            const priceLines = prices.filter(o => o.packageName === costEstimate.packageName);
            console.log("getCostEstimate");
            console.log(priceLines);
            priceLines.forEach(priceLine => {
                cost += priceLine.price ?? 0;
            });
        } else if (costEstimate.profile && costEstimate.platform && (costEstimate.units ?? 0) > 0) {
            const priceLine = prices.find(o => o.platform === costEstimate.platform && (o.units === costEstimate.units || o.units === 1) && !o.packageName);

            if (priceLine) {
                cost += priceLine.price ?? 0;
            }
        }

        if (costEstimate.units ?? 0 > 1) {
            cost = cost * (costEstimate.units ?? 0);
        }

        return cost;
    }

    interface CostEstimateData {
        value?: string,
        label?: string
    }
    const getCostEstimateProfilePackages = () => {
        const values: CostEstimateData[] = [
            { value: undefined, label: t('No package') }
        ];

        if (!costEstimate.profile) {
            return values;
        }

        (costEstimate.profile.prices || []).filter(o => o.packageName).map(o => o.packageName).filter((o, i, s) => s.indexOf(o) === i).forEach(o => {
            values.push({
                value: o,
                label: o
            });
        });

        return values
    }

    const hideCostEstimateDialog = () => {
        setCostEstimateDialog(false);
    }

    const handleCostEstimateSave = () => {
        const existingCostEstimates = campaign.costEstimates || [];
        existingCostEstimates.push({
            ...costEstimate,
            cost: getCostEstimate()
        });

        api.briefController.updateCampaign({
            ...campaign,
            costEstimates: [...existingCostEstimates]
        }).then((response) => {
            setCostEstimateDialog(false);
            setCostEstimate({});
            setCampaign({
                ...response.data
            });
            toast({
                severity: 'success',
                summary: DEFAULT_SUMMARY_SUCCESS(t),
                detail: t('The campaign has been updated with new cost estimates')
            });
        }).catch(console.error);
    };

    const handleCostEstimateDelete = (rowData: any, params: { rowIndex: number }) => {
        const existingCostEstimates = [...(campaign.costEstimates || [])];
        existingCostEstimates.splice(params.rowIndex, 1);
        api.briefController.updateCampaign({
            ...campaign,
            costEstimates: [
                ...existingCostEstimates
            ]
        }).then((response) => {
            setCampaign({ ...response.data });
            toast({
                severity: 'success',
                summary: DEFAULT_SUMMARY_SUCCESS(t),
                detail: t('The campaign has been updated with new cost estimates')
            });
        }).catch(console.error);
    };

    const costEstimatesActionBodyTemplate = (rowData: any, params: { rowIndex: number }) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => handleCostEstimateDelete(rowData, params)} />
            </React.Fragment>
        );
    }

    const propertiesDropdownTemplate = (profile:{name:string ,value:Profile}) => {
        if (!profile) {
            return (
                <div className="p-d-flex p-flex-row p-align-center">
                    <span>{t('Choose a property')}</span>
                </div>
            )
        }

        return (
            <div className="p-d-flex p-flex-row p-align-center">
                <i className={`fab ${profile.value.social === ESocialPlatform.Facebook ? 'fa-facebook' : 'fa-instagram'}`} />
                <span className="p-ml-2">{profile.name}</span>
            </div>
        )
    }

    const costEstimateDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideCostEstimateDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={handleCostEstimateSave} disabled={!costEstimate.profile || (!costEstimate.packageName && !costEstimate.platform) || (costEstimate.units ?? 0) < 1} />
        </React.Fragment>
    );




    return (<>


        <div className="p-col-12">
            <h1 className="page-title p-mt-5">{t('Brief Plan - Cost Explorer')}</h1>
            <div className="p-d-flex p-flex-row p-align-center p-mt-6 p-mb-3">
                <Button label={t('Add')} icon="pi pi-plus" onClick={showCostEstimateDialog} />
                <strong className="p-ml-auto">&euro; {numeral(getEstimatedCampaignCost()).format('0.0')}</strong>
            </div>

            <Card className={styles.noPaddingCard}>
                <DataTable value={campaign.costEstimates || []} className={'p-datatable-lg p-datatable-gridlines p-datatable-striped'} columnResizeMode="fit" paginator rows={20} scrollable scrollHeight="300px">
                    <Column header={t('Profile')} headerStyle={{ width: '200px' }} body={rowData => rowData.profile?.name} filter sortable />
                    <Column field="platform" header={t('Platform')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="units" header={t('Units')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="packageName" header={t('Package')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="cost" header={t('Cost')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column body={costEstimatesActionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
                </DataTable>
            </Card>
        </div>
        <Dialog header={t('Post Estimate Details')} visible={costEstimateDialog} style={{ width: '95vw', maxWidth: '450px' }} footer={costEstimateDialogFooter} onHide={hideCostEstimateDialog} keepInViewport={false} contentStyle={{ overflow: 'visible' }}>
            <div className="p-fluid p-grid p-formgrid">
                <div className="p-field p-col-12">
                    <label htmlFor={'input-profile'} className="p-d-block">{t('Property')}</label>
                    <Dropdown value={costEstimate.profile} options={campaign.profiles?.map(p => { return { value: p, name: p.name } })} optionValue="value" optionLabel="name" itemTemplate={propertiesDropdownTemplate} onChange={e => setCostEstimate({ ...costEstimate, profile: e.value })} valueTemplate={propertiesDropdownTemplate} />
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor={'input-package'} className="p-d-block">{t('Package')}</label>
                    <Dropdown id="input-package" value={costEstimate.packageName} options={getCostEstimateProfilePackages()} optionValue="value" optionLabel="label" onChange={e => setCostEstimate({ ...costEstimate, packageName: e.value, platform: undefined })} />
                </div>
                {
                    !costEstimate.packageName &&
                    (
                        <>
                            <div className="p-field p-col-12">
                                <label htmlFor={'input-platform'} className="p-d-block">{t('Platform')}</label>
                                <Dropdown id="input-platform" value={costEstimate.platform} options={[...PRICE_PLATFORMS].filter(o => costEstimate.profile && costEstimate.profile.prices && costEstimate.profile.prices.findIndex(price => price.platform === o.value && (!price.packageName || price.packageName.length === 0)) > -1)} optionValue="value" optionLabel="label" onChange={e => setCostEstimate({ ...costEstimate, platform: e.value })} />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor={'input-type'} className="p-d-block">{t('Type')}</label>
                                <Dropdown id="input-platform" value={costEstimate.type} options={Object.values(EPriceType).map(v => { return { label: v.toString(), value: v } }).filter(o => costEstimate.profile && costEstimate.profile.prices && costEstimate.profile.prices.findIndex(price => price.type === o.value && (!price.packageName || price.packageName.length === 0)) > -1)} optionValue="value" optionLabel="label" onChange={e => setCostEstimate({ ...costEstimate, type: e.value })} />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor={'input-units'} className="p-d-block">{t('Units')}</label>
                                <InputNumber id="input-units" value={costEstimate.units} onChange={e => setCostEstimate({ ...costEstimate, units: e.value })} />
                            </div>
                        </>
                    )
                }
                <div className="p-col-12 p-mt-5">
                    <div className="p-d-flex p-flex-row p-align-center">
                        <strong>Estimated Cost:</strong>
                        <strong className="p-ml-auto">&euro; {numeral(getCostEstimate()).format('0.0')}</strong>
                    </div>
                </div>
            </div>
        </Dialog>

    </>)
}
