import React, {useEffect, useRef} from 'react';
import MinimalLayout from '../../wrappers/MinimalLayout/MinimalLayout';
import {Card} from 'primereact/card';
import Logo from '../../shared/Logo/Logo';
import styles from './RegisterPage.module.scss';
import {useTranslation} from 'react-i18next';
import SmartForm from '../../shared/SmartForm/SmartForm';
import * as Yup from 'yup';
import {
  showResponseToast,
  validationEmailRequired,
  validationString,
  validationStringRequired
} from '../../../core/utils';
import {Button} from 'primereact/button';
import {Link, useHistory} from 'react-router-dom';
import api from '../../../core/api';
import {useDispatch} from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { ToastMessageType } from 'primereact/toast';
import { FormikProps } from 'formik';

type RegisterPageProp ={
  toast: (toast: ToastMessageType) => void
}
export default function RegisterPage({ toast }:RegisterPageProp) {
  const { t } = useTranslation();
  const smartForm = useRef<FormikProps<any>>(null);
  const roles = useAppSelector(s=>s.data.roles);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = t('Create an account - NativeJ');
  }, [t]);

  const handleRegistration = (values:any, { setSubmitting }:any) => {
    if (values.password !== values.confirmPassword) {
      toast({
        severity: 'warn',
        summary: t('Passwords do not match'),
        detail: t('In order to be sure you\'re correctly writing down your passwords, both Password and Confirmation Password must match')
      });

      return;
    }

    setSubmitting(true);

    const data = {...values};
    data.roles = [roles.find(role => role.name === data.role)];

    delete data.role;
    delete data.confirmPassword;

    api.authController.register(data).then(r => {
      setSubmitting(false);
      showResponseToast(toast, r, t);
      history.push('/login');
    }).catch(r => {
      showResponseToast(toast, r, t);
    })
  };

  return (
    <MinimalLayout>
      <Card style={{ width: '100%', maxWidth: '500px' }}>
        <div className={`${styles.formTitle} p-py-3 p-pl-3`}>
          <div className={`p-d-flex p-flex-row p-align-center`}>
            <h2 className="p-m-0 p-p-0 p-text-normal">{t('Create an account on')}</h2>
            <Logo className="p-ml-3" style={{ height: '30px' }} />
          </div>
          <small className="p-opacity-75 p-fs-4 p-d-block p-mt-1">{t('Start using our platform as a Publisher, Influencer or Advertiser')}</small>
        </div>

        <SmartForm
          ref={smartForm}
          className="p-mt-5"
          onSubmit={handleRegistration}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            phone: ''
          }}
          fields={[
            { key: 'firstName', label: t('First Name') },
            { key: 'lastName', label: t('Last Name') },
            { key: 'email', label: t('Email address') },
            { key: 'password', label: t('Password'), inputType: 'password' },
            { key: 'confirmPassword', label: t('Confirm Password'), inputType: 'password' },
            { key: 'phone', label: t('Phone Number') },
            {
              key: 'role',
              label: t('Register as'),
              type: 'list',
              options: [
                { value: 'ROLE_PUBLISHER', label: t('Publisher') },
                { value: 'ROLE_INFLUENCER', label: t('Influencer') },
                { value: 'ROLE_ADVERTISER', label: t('Advertiser') }
              ]
            },
            { key: 'company', label: t('Company'), visibility: ({ values }) => values?.role === 'ROLE_ADVERTISER' }
          ]}
          validationSchema={Yup.object().shape({
            firstName: validationStringRequired(t),
            lastName: validationStringRequired(t),
            email: validationEmailRequired(t),
            password: validationStringRequired(t),
            confirmPassword: validationStringRequired(t),
            phone: validationString(t),
            role: validationStringRequired(t),
            company: validationString(t)
          })}
        />

        <div className="p-grid">
          <div className="p-col-12">
            <Button label={t('Sign Up')} className="w-100 p-button-lg p-mt-5" onClick={() => smartForm?.current?.submitForm()} />
          </div>
          <div className="p-col-12 p-mt-1 p-text-center p-fs-2 p-text-bold">
            <span>{t('Cliccando su "Sign Up", accetti i nostri Termini e Condizioni di utilizzo e confermi di aver letto la nostra Privacy Policy, inclusa la Cookie Policy di utilizzo dei cookie')}</span>
          </div>
          <div className="p-col-12 p-mt-3 p-text-center p-fs-6">
            <span>{t('Already have an account?')}</span> <Link to="/login">{t('Login')}</Link>
          </div>
        </div>
      </Card>
    </MinimalLayout>
  )
}
