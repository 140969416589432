import styles from './LoaderOverlay.module.scss';
import {ProgressSpinner} from 'primereact/progressspinner';

type LoaderOverlayProps ={
  visible:boolean ;
}
export default function LoaderOverlay(props:LoaderOverlayProps) {
  if (!props.visible) {
    return null
  }

  return (
    <div className={styles.root}>
      <ProgressSpinner />
    </div>
  )
}
