import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import config from '../../../core/config';
import { useAppSelector } from '../../../redux/hooks';

type AuthRouteProps = {
  children: JSX.Element, 
  exact : boolean, 
  path : string, 
  forceLoggedIn? : boolean | undefined, 
  forceLoggedOut?: boolean | undefined
}

 const AuthRoute = ({ children, exact, path, forceLoggedIn = false, forceLoggedOut =false}:AuthRouteProps) =>{
  const user = useAppSelector(state=>state.auth.user)
  const token = useAppSelector(s=>s.auth.token)

  

  if ((!user || !token ) && forceLoggedIn) {
    return <Redirect to="/login" />
  } else if ((user && token) && forceLoggedOut) {
    return <Redirect to={config.homepage} />
  }

  return <Route  path={path} exact={exact}>{children}</Route>
}
export default  AuthRoute;