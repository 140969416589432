import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../core/api';
import SmartForm, { Field } from '../SmartForm/SmartForm';
import { Campaign, ERole, User } from '../../../monaco-client';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { validationStringRequired, validationNumberRequired, validationDateRequired } from '../../../core/utils';
import { useAppSelector } from '../../../redux/hooks';
import { CAMPAIGN_STATUS } from '../../../core/constants';



export function GeneralInformation({ smartForm, editing, setCurrentCampaign, currentCampaign }: { smartForm: React.RefObject<FormikProps<User>>, editing?: boolean, setCurrentCampaign: React.Dispatch<React.SetStateAction<Campaign>>, currentCampaign: Campaign }) {
    const { t } = useTranslation();
    const [advertisers, setAdvertisers] = useState<User[]>([]);
    const roles = useAppSelector(s => s.data.roles);

    useEffect(() => {
        if (roles.length === 0) {
            return;
        }
        api.userController.getUsers(roles.find((role) => role.name === ERole.Advertiser)?.id).then((response) => {
            setAdvertisers(response.data);

        });
    }, [roles])

    const handleCampaignInformationSubmit = (values: any) => {
        setCurrentCampaign({
            ...currentCampaign,
            ...values,
        });
    };

    const userTemplate = (o: User) => {
        if (!o)
            return <div>_</div>
        return (
            <div >
                {o.firstName} {o.lastName}
            </div>
        );
    }

    const getFields: () => Field<User>[] = () => {
        let fields: Field<User>[] = []
        if (editing)
            fields = [{ key: 'status', label: t('Status'), size: 12, type: 'list', options: CAMPAIGN_STATUS }];

        return fields.concat([
            { key: 'publicName', label: t('Public Name'), size: 4 },
            { key: 'privateName', label: t('Private Name'), size: 4 },
            { key: 'budget', label: t('Budget'), size: 4, inputType: 'number' },
            { key: 'startDate', label: t('Start Date'), size: 4, type: 'date' },
            { key: 'endDate', label: t('End Date'), size: 4, type: 'date' },
            { key: 'brand', label: t('Brand'), size: 4 },
            { key: 'note', label: t('Note'), size: 8, type: 'textarea', rows: 4 },
            {
                key: 'advertiser', label: t('Advertiser'), size: 4, type: 'list', optionLabel: 'name', options: advertisers,
                valueTemplate: userTemplate,
                itemTemplate: userTemplate
            }
        ])

    }

    return (<>
        <h4 className="p-p-0 p-mt-6 p-mb-5 p-opacity-50 p-text-normal p-text-uppercase">{t('General Information')}</h4>
        <SmartForm ref={smartForm} className="p-mt-5"
            onSubmit={handleCampaignInformationSubmit}
            initialValues={currentCampaign}
            fields={getFields()}
            validationSchema={Yup.object().shape({
                publicName: validationStringRequired(t),
                privateName: validationStringRequired(t),
                budget: validationNumberRequired(t),
                startDate: validationDateRequired(t),
                endDate: validationDateRequired(t),
                brand: validationStringRequired(t),
                note: validationStringRequired(t),
                advertiser: Yup.object().required()

            })} />
    </>);
}