import { Profile, ESocialPlatform, User } from '../monaco-client';

export const ERROR_PROFILE_IMAGE_URL = "/images/placeholders/placeholder-7.svg"

export function getProfileLink(profile:Profile) {
    if(profile.social === ESocialPlatform.Facebook) 
      return "https://www.facebook.com/" + profile.identification;
    if(profile.social === ESocialPlatform.Instagram) 
      return "https://www.instagram.com/" + profile.identification;
}

export function getUserProfilePictureUrl(user?:User){
  if(!user)
    return undefined;
  if(user.profilePictureUrl)
    return   user.profilePictureUrl;
  else
    return 'https://eu.ui-avatars.com/api/?name=' + user.firstName + '+' + user.lastName + '&background=random';
}
