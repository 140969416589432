import { TFunction } from "i18next";
import { Chip } from "primereact/chip";
import { Campaign, EGoal,ECampaignStatus } from "../monaco-client/model";

export const statusBodyTemplate = (rowData:Campaign,t:TFunction) => {
    
    return (
      <div className="p-text-center">
          {rowData.status === ECampaignStatus.Briefing && <Chip label={t('Briefing')} className="" />}
          {rowData.status === ECampaignStatus.Active && <Chip label={t('Active')} className="warn" />}
          {rowData.status === ECampaignStatus.Complete && <Chip label={t('Complete')} className="primary" />}
          {rowData.status === ECampaignStatus.OnHold && <Chip label={t('On Hold')} className="warn" />}
          {rowData.status === ECampaignStatus.Stopped && <Chip label={t('Failed')} className="error" />}
      </div>
    )
  }

export const emptyCampaign:Campaign = {
    "publicName": "",
    "privateName": "",
    "status":ECampaignStatus.Briefing,
    "startDate": undefined,
    "endDate": undefined,
    "budget": 0,
    "brand": "",
    "advertiser": undefined,
    "mainGoal": undefined,
    "secondaryGoal": undefined,
    "likes": 0,
    "comments": 0,
    "views": {total:0},
    "reach": 0,
    "impression": 0,
    "engagement": 0,
    "shares": 0,
    "clicks": 0,
    "actions": {},
    "targetLikes": 0,
    "targetComments": 0,
    "targetViews": {total:0},
    "targetReach": 0,
    "targetEngagement": 0,
    "targetImpression": 0,
    "targetShares": 0,
    "targetActions": {},
    "targetClicks": 0,
    "statistics": {
      "sentiment": {
        "positive": 0,
        "neutral": 0,
        "negative": 0
      }
    },
    "note": "",
    "categories": [],
    "interests": [],
    "tags": [],
    "gender": [],
    "ageRange": [],
    "geolocation": [],
    "profiles": [],
    "costEstimates" : []
  };

export const getGoalTargetValue: (campaign: Campaign, goal: EGoal|undefined) => number|undefined = (campaign, goal) => {
    if (!goal)
        return undefined;
    switch (goal) {
        case EGoal.Clicks:
            return campaign.targetClicks
        case EGoal.Comments:
            return campaign.targetComments
        case EGoal.Engagement:
            return campaign.targetEngagement
        case EGoal.Likes:
            return campaign.targetLikes
        case EGoal.Reach:
            return campaign.targetReach
        case EGoal.Views:
            return campaign.targetViews?.total
        case EGoal.Impressions:
            return campaign.targetImpression
        case EGoal.Shares:
            return campaign.targetShares
    }
}

export const getGoalValue: (campaign: Campaign, goal: EGoal|undefined) => number|undefined = (campaign, goal) => {
    if (!goal)
        return undefined;
    switch (goal) {
        case EGoal.Clicks:
            return campaign.clicks
        case EGoal.Comments:
            return campaign.comments
        case EGoal.Engagement:
            return campaign.engagement
        case EGoal.Likes:
            return campaign.likes
        case EGoal.Reach:
            return campaign.reach
        case EGoal.Views:
            return campaign.views?.total
        case EGoal.Impressions:
            return campaign.impression
        case EGoal.Shares:
            return campaign.shares
    }
}

export const withGoalTargetValue: (campaign: Campaign, goal: EGoal , value: number) => Campaign = (campaign, goal, value) => {
    if(!goal)
        return campaign;
    switch (goal) {
        case EGoal.Clicks:
            return {
                ...campaign,
                targetClicks : value
            }
        case EGoal.Comments:
            return {
                ...campaign,
                targetComments : value
            }
        case EGoal.Engagement:
            return {
                ...campaign,
                targetEngagement : value
            }
        case EGoal.Likes:
            return {
                ...campaign,
                targetLikes : value
            }
        case EGoal.Reach:
            return {
                ...campaign,
                targetReach : value
            }
        case EGoal.Views:
            return {
                ...campaign,
                targetViews : {
                    total: value
                }
            }
        case EGoal.Impressions:
            return {
                ...campaign,
               targetImpression: value
            }
        case EGoal.Shares:
            return {
                ...campaign,
                targetShares: value
            }
    }
}

export const setGoalValue: (campaign: Campaign, goal: EGoal | EGoal, value: number) => void = (campaign, goal, value) => {
    switch (goal) {
        case EGoal.Clicks:
            return {
                ...campaign,
                clicks : value
            }
        case EGoal.Comments:
            return {
                ...campaign,
                comments : value
            }
        case EGoal.Engagement:
            return {
                ...campaign,
                engagement : value
            }
        case EGoal.Likes:
            return {
                ...campaign,
                likes : value
            }
        case EGoal.Reach:
            return {
                ...campaign,
                reach : value
            }
        case EGoal.Views:
            return {
                ...campaign,
                views : {
                    total: value
                }
            }
        case EGoal.Impressions:
            return {
                ...campaign,
                impression: value
            }
        case EGoal.Shares:
            return {
                ...campaign,
                shares: value
            }
    }
}

export const withMainGoal :(campaign: Campaign, goal: EGoal) =>Campaign = (campaign, goal) => {
    let retVal = campaign;
    if (goal === campaign.secondaryGoal)
        retVal = {
            ...retVal,
            secondaryGoal : undefined
        }
    if (campaign.mainGoal) {
        const oldVal = getGoalTargetValue(campaign, campaign.mainGoal);
        retVal = withGoalTargetValue(campaign, campaign.mainGoal, 0);
        retVal = withGoalTargetValue(campaign,goal,oldVal??0);
    }
    return {
        ...retVal,
        mainGoal:goal
    }
}

export const withSecondaryGoal :(campaign: Campaign, goal: EGoal) =>Campaign = (campaign, goal)  => {
    let retVal = campaign;
    if (goal === campaign.mainGoal)
        return retVal;
    if (campaign.secondaryGoal) {
        const oldVal = getGoalTargetValue(campaign, campaign.secondaryGoal);
        retVal = withGoalTargetValue(campaign, campaign.secondaryGoal, 0);
        retVal = withGoalTargetValue(campaign,goal,oldVal??0);
    }
    return {
        ...retVal,
        secondaryGoal:goal
    }
}

export const getGoalStatus = (campaign:Campaign,goal:EGoal|undefined) => {
    const currentValue = getGoalValue(campaign,goal)??0;
    const totalValue = getGoalTargetValue(campaign,goal)??0;

    if (totalValue === 0 || currentValue === 0) {
      return 0;
    }

    return Math.ceil(100 * currentValue / totalValue);
  }

 export  const calculateSentiment = (campaign:Campaign ,data :number ) => {
    const sentiment :any   = campaign?.statistics ? campaign.statistics['sentiment'] : {};
    const { positive, neutral, negative } = sentiment;

    const total = (positive || 0) + (neutral || 0) + (negative || 0);

    if (total === 0 || data === 0) {
      return 0;
    }

    return (data * 100) / total;
  }