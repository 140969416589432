import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from './authSlice';
import dataReducer from './dataSlice';
import settingsReducer from './settingsSlice';
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer, persistStore} from 'redux-persist';

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  settings: settingsReducer
});

const persistConfig = {
  key: 'root',
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});
export default store;

export type appDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export const storePersistor = persistStore(store);
