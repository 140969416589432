import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import {Chart} from 'primereact/chart';
import {Card} from 'primereact/card';
import api, { showResponseToastAxiosFailure } from '../../../core/api';
import styles from '../../shared/SummaryResult/CampaignPage.module.scss';
import * as _ from 'lodash';
import numeral from 'numeral';
import {isAdmin, isAdvertiser, isPublisherOrInfluencer} from '../../../core/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {useHistory} from 'react-router-dom';
import { ToastMessageType } from 'primereact/toast';
import { Campaign, ECampaignStatus, Dashboard } from '../../../monaco-client';
import { useAppSelector } from '../../../redux/hooks';
import { getGoalStatus } from '../../../core/campaingUtils';
import { SummaryResult } from '../../shared/SummaryResult/SummaryResut';

type HomePageProp ={
  toast: (toast: ToastMessageType) => void
}

export default function HomePage({ toast }:HomePageProp) {
  const { t } = useTranslation();
  const user = useAppSelector(s=>s.auth.user);
  const [dashboard, setDashboard] = useState<Dashboard|null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const history = useHistory();

  useEffect(() => {
    const {source,options} = api.getCancelOption();
    document.title = t('Dashboard - NativeJ');
    api.reportController.getDashboard(options).then(response => {
      setDashboard(response.data.data?response.data.data:null);
    }).catch(e => console.error(e));

    api.briefController.getCampaigns(options).then(response => {
     
        var activeCampaigns = response.data.filter((campaign) => { return campaign.status === ECampaignStatus.Active;});
        setCampaigns(activeCampaigns);
     
    },(error=>{showResponseToastAxiosFailure(toast,error,t)}));
    return ()=>{source.cancel('request cancelled change page before completion')}
  }, [t, toast]);

  const handleCampaignView = (campaign:Campaign)=> {
    history.push('/campaign/' + campaign.id);
  };

  const actionBodyTemplate = (rowData:Campaign) => {
    return (
      <React.Fragment>
        <Button icon="fas fa-eye" className="p-button-rounded p-button-outlined p-mr-2" onClick={() => handleCampaignView(rowData)} />
      </React.Fragment>
    );
  }

  const getPrimaryGoalStatus = (campaign:Campaign) => {
    return getGoalStatus(campaign,campaign.mainGoal)
  }

  const getSecondaryGoalStatus = (campaign:Campaign) => {
    return getGoalStatus(campaign,campaign.secondaryGoal)
  }


  const getGenderChartData = () => {
    const genderData = _.get(dashboard, 'statistics.audience.gender', {});
    const maleData = genderData.MALE || 50;
    const femaleData = genderData.FEMALE || 50;

    return {
      labels: [t('FEMALE'), t('MALE')],
      datasets: [
        {
          data: [femaleData, maleData],
          backgroundColor: [
            "#FF6384",
            "#36A2EB"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB"
          ]
        }
      ]
    }
  }

  const getAgeChartData = () => {
    const ageData = _.get(dashboard, 'statistics.audience.age', []);

    return {
      labels: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
      datasets: [
        {
          label: t('Audience'),
          backgroundColor: '#42A5F5',
          data: [5, 5, 30, 40, 20, 0, 0]
        }
      ]
    }
  }

  const getLocationChartData = () => {
    const locationData = _.get(dashboard, 'statistics.audience.location', []);

    return {
      datasets: [
        {
          data: [50, 5, 20, 15, 10],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FF6384",
            "#ffe563",
            "#FFA726"
          ],
          label: t('Locations')
        }
      ],
      labels: ['Milan', 'Varese', 'Rome', 'Palermo', 'Venice']
    }
  }

  

  const getAdvertiserDashboard = () => {
    return (
      <React.Fragment>
        {dashboard && <SummaryResult campaign={dashboard}/>}
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.planningCampaigns).format('0.0a')} <br />{t('Planned Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-thumbs-up" />
              <span className="p-mt-3">{numeral(dashboard?.activeCampaigns).format('0.0a')} <br />{t('Active Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.pastCampaigns).format('0.0a')} <br />{t('Past Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-12">
            <Card className={styles.userCard}>
              <DataTable value={campaigns} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                <Column field="publicName" header={t('Public')} filter sortable />
                <Column field="privateName" header={t('Private')} filter sortable />
                <Column field="budget" header={t('Budget')} filter sortable />
                <Column field="startDate" header={t('Start')} filter sortable />
                <Column field="endDate" header={t('End')} filter sortable />
                <Column field="brand" header={t('Brand')} filter sortable />
                <Column body={props => getPrimaryGoalStatus(props)} header={t('Main Goal')} filter sortable />
                <Column body={props => getSecondaryGoalStatus(props)} header={t('Secondary Goal')} filter sortable />
                <Column body={actionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
              </DataTable>
            </Card>
        </div>

      </React.Fragment>
    )
  }

  const getPublisherOrInfluencerDashboard = () => {
    return (
      <React.Fragment>
        {dashboard && <SummaryResult campaign={dashboard}/>}
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.planningCampaigns).format('0.0a')} <br />{t('Planned Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-thumbs-up" />
              <span className="p-mt-3">{numeral(dashboard?.activeCampaigns).format('0.0a')} <br />{t('Active Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.pastCampaigns).format('0.0a')} <br />{t('Past Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-12">
            <Card className={styles.userCard}>
              <DataTable value={campaigns} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                <Column field="publicName" header={t('Public')} filter sortable />
                <Column field="privateName" header={t('Private')} filter sortable />
                <Column field="budget" header={t('Budget')} filter sortable />
                <Column field="startDate" header={t('Start')} filter sortable />
                <Column field="endDate" header={t('End')} filter sortable />
                <Column field="brand" header={t('Brand')} filter sortable />
                <Column body={props => getPrimaryGoalStatus(props)} header={t('Main Goal')} filter sortable />
                <Column body={props => getSecondaryGoalStatus(props)} header={t('Secondary Goal')} filter sortable />
                <Column body={actionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
              </DataTable>
            </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Gender Information')} className="content-center">
            <small>{t('Below you can see a chart which shows what gender interacts the most with the content')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="doughnut" data={getGenderChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Audience Age')} className="content-center">
            <small>{t('Below you can see a chart which shows how popular is the content between different ages')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="bar" data={getAgeChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Popular Locations')} className="content-center">
            <small>{t('Below you can see a chart which shows from where your audience comes from')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="polarArea" data={getLocationChartData()} />
            </div>
          </Card>
        </div>
      </React.Fragment>
    )
  }

  const getAdminDashboard = () => {
    return (
      <React.Fragment>
        {dashboard && <SummaryResult campaign={dashboard}/>}
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.planningCampaigns).format('0.0a')} <br />{t('Planned Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-thumbs-up" />
              <span className="p-mt-3">{numeral(dashboard?.activeCampaigns).format('0.0a')} <br />{t('Active Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
            <div className="p-d-flex p-flex-column p-align-center p-justify-center">
              <i className="fas fa-comments" />
              <span className="p-mt-3">{numeral(dashboard?.pastCampaigns).format('0.0a')} <br />{t('Past Campaigns')}</span>
            </div>
          </Card>
        </div>
        <div className="p-col-12">
            <Card className={styles.userCard}>
              <DataTable value={campaigns} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                <Column field="publicName" header={t('Public')} filter sortable />
                <Column field="privateName" header={t('Private')} filter sortable />
                <Column field="budget" header={t('Budget')} filter sortable />
                <Column field="startDate" header={t('Start')} filter sortable />
                <Column field="endDate" header={t('End')} filter sortable />
                <Column field="brand" header={t('Brand')} filter sortable />
                <Column body={(props:Campaign) => getPrimaryGoalStatus(props)} header={t('Main Goal')} filter sortable />
                <Column body={props => getSecondaryGoalStatus(props)} header={t('Secondary Goal')} filter sortable />
                <Column body={actionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
              </DataTable>
            </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Gender Information')} className="content-center">
            <small>{t('Below you can see a chart which shows what gender interacts the most with the content')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="doughnut" data={getGenderChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Audience Age')} className="content-center">
            <small>{t('Below you can see a chart which shows how popular is the content between different ages')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="bar" data={getAgeChartData()} />
            </div>
          </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
          <Card title={t('Popular Locations')} className="content-center">
            <small>{t('Below you can see a chart which shows from where your audience comes from')}</small>
            <div className="p-mt-auto p-mb-auto">
              <Chart type="polarArea" data={getLocationChartData()} />
            </div>
          </Card>
        </div>
      </React.Fragment>
    )
  }

  // const getAdminDashboardBackup = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="p-md-4">
  //         <Card title={<h2 className="p-text-normal p-m-0 p-p-0 p-fs-4">{t('Popular Categories')}</h2>}>
  //           <Chart type="pie" data={{
  //             labels: ['Male', 'Female', 'Other'],
  //             datasets: [
  //               {
  //                 data: [50, 35, 15],
  //                 backgroundColor: [
  //                   "#42A5F5",
  //                   "#66BB6A",
  //                   "#FFA726"
  //                 ],
  //                 hoverBackgroundColor: [
  //                   "#64B5F6",
  //                   "#81C784",
  //                   "#FFB74D"
  //                 ]
  //               }
  //             ]
  //           }} options={{
  //             legend: {
  //               labels: {
  //                 fontColor: '#495057'
  //               }
  //             }
  //           }} />
  //         </Card>
  //       </div>
  //       <div className="p-md-4">
  //         <Card title={<h2 className="p-text-normal p-m-0 p-p-0 p-fs-4">{t('Live Performance')}</h2>}>
  //           <Chart type="line" data={{
  //             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  //             datasets: [
  //               {
  //                 label: 'Reach',
  //                 data: [0, 0, 0, 0, 0, 0, 0],
  //                 fill: false,
  //                 borderColor: '#42A5F5'
  //               },
  //               {
  //                 label: 'Engagement',
  //                 data: [0, 0, 0, 0, 0, 0, 0],
  //                 fill: false,
  //                 borderDash: [5, 5],
  //                 borderColor: '#66BB6A'
  //               },
  //               {
  //                 label: 'Clicks',
  //                 data: [0, 0, 0, 0, 0, 0, 0],
  //                 fill: true,
  //                 borderColor: '#FFA726',
  //                 backgroundColor: 'rgba(255,167,38,0.2)'
  //               }
  //             ]
  //           }} options={{
  //             legend: {
  //               labels: {
  //                 fontColor: '#495057'
  //               }
  //             },
  //             scales: {
  //               xAxes: [{
  //                 ticks: {
  //                   fontColor: '#495057'
  //                 }
  //               }],
  //               yAxes: [{
  //                 ticks: {
  //                   fontColor: '#495057'
  //                 }
  //               }]
  //             }
  //           }} />
  //         </Card>
  //       </div>
  //       <div className="p-md-4">
  //         <Card title={<h2 className="p-text-normal p-m-0 p-p-0 p-fs-4">{t('Growth Indicator')}</h2>}>
  //           <Chart type="bar" data={{
  //             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  //             datasets: [{
  //               type: 'line',
  //               label: 'Likes',
  //               borderColor: '#42A5F5',
  //               borderWidth: 2,
  //               fill: false,
  //               data: [
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0
  //               ]
  //             }, {
  //               type: 'bar',
  //               label: 'Comments',
  //               backgroundColor: '#66BB6A',
  //               data: [
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0
  //               ],
  //               borderColor: 'white',
  //               borderWidth: 2
  //             }, {
  //               type: 'bar',
  //               label: 'Views',
  //               backgroundColor: '#FFA726',
  //               data: [
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0,
  //                 0
  //               ]
  //             }]
  //           }} options={{
  //             legend: {
  //               labels: {
  //                 fontColor: '#495057'
  //               }
  //             },
  //             scales: {
  //               xAxes: [{
  //                 ticks: {
  //                   fontColor: '#495057'
  //                 }
  //               }],
  //               yAxes: [{
  //                 ticks: {
  //                   fontColor: '#495057'
  //                 }
  //               }]
  //             }
  //           }} />
  //         </Card>
  //       </div>
  //     </React.Fragment>
  //   )
  // }

  return (
    <StandardLayout>
      <div className="p-p-6">
        <h1 className="page-title">{t('Your Dashboard')}</h1>
        <div className="p-grid p-mt-5">
          {(dashboard && isAdmin(user) )&& getAdminDashboard()}
          {(dashboard && isAdvertiser(user)) && getAdvertiserDashboard()}
          {(dashboard && isPublisherOrInfluencer(user)) && getPublisherOrInfluencerDashboard()}
        </div>
      </div>
    </StandardLayout>
  )
}
