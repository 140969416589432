import { Campaign, CampaignPost, User } from '../../../monaco-client';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import api from '../../../core/api';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import styles from '../../shared/SummaryResult/CampaignPage.module.scss';
import numeral from 'numeral';
import { isAdmin, isPublisherOrInfluencer } from '../../../core/utils';

type CampaignPostCardProp = {
  toast: (p: any) => void,
  campaign: Campaign,
  user?: User,
}

export const CampaignPostCard = ({ toast, campaign, user }: CampaignPostCardProp) => {
  const [posts, setPosts] = useState<CampaignPost[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    campaign.id && api.briefController.getPosts(campaign.id).then((response) => {
      setPosts(response.data);
    }).catch(console.error);
  }, [campaign.id]);

  const urlTemplate = (rowData:CampaignPost)=>{return (
    <a href={rowData.url} target="_blank" rel="noreferrer">{rowData.identification}</a>
  )}
  return (

    <div className="p-col-12">
      <h1 className="page-title p-mt-5">{t('Campaign Posts')}</h1>
      <div className="p-d-flex p-flex-row p-align-center p-mt-6 p-mb-3">
      </div>
      <Card className={styles.noPaddingCard}>

        <DataTable value={posts} className={'p-datatable-lg p-datatable-gridlines p-datatable-striped'} columnResizeMode="fit" paginator rows={20} scrollable scrollHeight="300px">
        
          <Column field="date" header={t('Publish Date')} headerStyle={{ width: '8em' }} filter sortable />
          
          <Column body={urlTemplate} header={t('identification')} headerStyle={{ width: '11em' }} filter sortable />
          <Column field="profile.name" header={t('Profile Name')} headerStyle={{ width: '9em' }} filter sortable />
          <Column field="profile.social" header={t('Social')} headerStyle={{ width: '8em' }} filter sortable />
          <Column field="profile.type" header={t('Type')} headerStyle={{ width: '8em' }} filter sortable />
          {(isAdmin(user)||isPublisherOrInfluencer(user))&& (  
          <Column body={rowData =>numeral(rowData.reach).format('0.0a')}  header={t('Reach')} headerStyle={{ width: '6em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.engagement).format('0.00%')} header={t('Engagement')} headerStyle={{ width: '7em' }} filter sortable />/* percentuale * 100*/
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.likes).format('0.0a')} header={t('Likes')} headerStyle={{ width: '5em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.comments).format('0.0a')} header={t('Comments')} headerStyle={{ width: '7em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.views.total).format('0.0a')}  header={t('Views')} headerStyle={{ width: '5em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.clicks).format('0.0a')} header={t('Clicks')} headerStyle={{ width: '6em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.impression).format('0.0a')} header={t('Impression')} headerStyle={{ width: '7em' }} filter sortable />
          )}{(isAdmin(user)||isPublisherOrInfluencer(user))&& ( 
          <Column body={rowData =>numeral(rowData.shares).format('0.0a')} header={t('Shares')} headerStyle={{ width: '5em' }} filter sortable />
          )}
        </DataTable>

      </Card>

    </div>
  )
}