import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Category, Country, Interest, Role,Tag } from '../monaco-client/model';

interface DataState {
    roles: Role[],
    //influencers: User[],
    //publishers: User[],
    //advertisers: User[],
    categories: Category[],
    interests: Interest[],
    tags: Tag[],
    countries: Country[]
  }

  const initialState: DataState ={
    roles: [],
    //influencers: [],
    //publishers: [],
    //advertisers: [],
    categories: [],
    interests: [],
    tags: [],
    countries: []
  }

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setRoles: (state:DataState, action:PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    // setInfluencers: (state:DataState, action:PayloadAction<User[]>) => {
    //   state.influencers = action.payload;
    // },
    // setPublishers: (state:DataState, action:PayloadAction<User[]>) => {
    //   state.publishers = action.payload;
    // },
    // setAdvertisers: (state:DataState, action:PayloadAction<User[]>) => {
    //   state.advertisers = action.payload;
    // },
    // connectInfluencer: (state:DataState, action:PayloadAction<User>) => {
    //   const index = state.influencers.findIndex(influencer => influencer.identification === action.payload.identification);

    //   if (index > -1) {
    //     state.influencers[index] = {
    //       ...state.influencers[index],
    //       ...action.payload
    //     };
    //   } else {
    //     state.influencers = [
    //       ...state.influencers,
    //       action.payload
    //     ];
    //   }
    // },
    // connectPublisher: (state:DataState, action:PayloadAction<User>) => {
    //   const index = state.publishers.findIndex(publisher => publisher.identification === action.payload.identification);

    //   if (index > -1) {
    //     state.publishers[index] = {
    //       ...state.publishers[index],
    //       ...action.payload
    //     };
    //   } else {
    //     state.publishers = [
    //       ...state.publishers,
    //       action.payload
    //     ];
    //   }
    // },
    // disconnectInfluencer: (state:DataState, action:PayloadAction<User>) => {
    //   const index = state.influencers.findIndex(influencer => influencer.identification === action.payload.identification);

    //   if (index > -1) {
    //     state.influencers.splice(index, 1);
    //   }
    // },
    // disconnectPublisher: (state:DataState, action:PayloadAction<User>) => {
    //   const index = state.publishers.findIndex(publisher => publisher.identification === action.payload.identification);

    //   if (index > -1) {
    //     state.publishers.splice(index, 1);
    //   }
    // },
    setCategories: (state:DataState, action:PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setInterests: (state:DataState, action:PayloadAction<Interest[]>) => {
      state.interests = action.payload;
    },
    setTags: (state:DataState, action:PayloadAction<Tag[]>) => {
      state.tags = action.payload
    },
    setCountries: (state:DataState, action:PayloadAction<Country[]>) => {
      state.countries = action.payload;
    }
  }
});

export const { setRoles, /*setInfluencers, setPublishers, setAdvertisers, connectInfluencer, connectPublisher, disconnectInfluencer, disconnectPublisher,*/ setCategories, setInterests, setTags, setCountries } = dataSlice.actions;

//export const selectRoles = (state:RootState) => state.data.roles;
// export const selectInfluencers = (state:RootState) => state.data.influencers;
// export const selectPublishers = (state:RootState) => state.data.publishers;
// export const selectAdvertisers = (state:RootState) => state.data.advertisers;


export default dataSlice.reducer;
