import React, { useEffect, useState } from 'react';
import api from '../../../core/api';
import { Button } from 'primereact/button';
import { Campaign, Category } from '../../../monaco-client';


export function Categories({setCurrentCampaign,currentCampaign}:{setCurrentCampaign:React.Dispatch<React.SetStateAction<Campaign>>,currentCampaign:Campaign}) {
    const [categories, setCategories] = useState<Category[]>([]);
  
    const handleCategorySelection = (category: Category) => {
      let newValue: Category[];
      let oldValue: Category[] = [...currentCampaign.categories ?? []];
      if (oldValue.includes(category)) {
        newValue = oldValue.filter(o => o.id !== category.id);
      } else {
        newValue = [...oldValue, category]
      }
      setCurrentCampaign({
        ...currentCampaign,
        categories: newValue
      })
    }
  
    useEffect(() => {
      if (categories.length === 0) {
        api.dataController.getCategories().then(response => {
          setCategories(response.data);
        });
      }
    },[categories.length])
  
    return (<div className="p-grid p-mt-6">
      {[...categories].map(category => (
        <div className="p-md-3" key={category.id}>
          <Button className={(currentCampaign.categories && currentCampaign.categories.findIndex(c => category.id === c.id) >= 0 ? '' : 'p-button-outlined') + ' w-100 p-button-lg'} label={category.name} onClick={() => handleCategorySelection(category)} />
        </div>
      ))}
    </div>);
  }