import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import StandardLayout from '../../wrappers/StandardLayout/StandardLayout';
import {Card} from 'primereact/card';
import styles from '../PeoplePage/PeoplePage.module.scss';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {DEFAULT_SUMMARY_ERROR, DEFAULT_SUMMARY_SUCCESS} from '../../../core/constants';
import api from '../../../core/api';
import {Button} from 'primereact/button';
import {useHistory} from 'react-router-dom';
import EditCampaignDialog from '../../shared/EditCampaign/EditCampaignDialog';
import { Campaign} from '../../../monaco-client';
import { ToastMessageType } from 'primereact/toast';
import { ToastContext } from '../../../App';
import { useAppSelector } from '../../../redux/hooks';
import { isAdmin } from '../../../core/utils';
import { emptyCampaign, statusBodyTemplate } from '../../../core/campaingUtils';


type CampaignsPageProps = {
  toast?:(t:ToastMessageType)=>void
}

export default function CampaignsPage({ toast}:CampaignsPageProps) {
  const { t } = useTranslation();
  
  const user = useAppSelector(state=>state.auth.user)
  const history = useHistory();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [planningDialog, setPlanningDialog] = useState(false);
 
  

  const toastContext = useContext(ToastContext);
  const toastInt = toast??toastContext;
  useEffect(() => {
    document.title = t('Campaigns - NativeJ');

    return fetchData();
  }, [t]);

  

  const fetchData = () => {
    const {source,options} = api.getCancelOption();
    api.briefController.getCampaigns(options).then(response => {
      setCampaigns(response.data);
    });
    return ()=>{
      source.cancel('request cancelled change page before completion')
    }
  };

  

  const showPlanningDialog = () => {
    

    setPlanningDialog(true);
  };

  const hidePlanningDialog = () => {
    setPlanningDialog(false);
    
  };
 
  const handleCampaignView = (campaign:Campaign) => {
    history.push('/campaign/' + campaign.id);
  };

  const actionBodyTemplate = (rowData:Campaign) => {
   
    return (
      <React.Fragment>
        <Button icon="fas fa-eye" className="p-button-rounded p-button-outlined p-mr-2" onClick={() => handleCampaignView(rowData)} />
      </React.Fragment>
    );
  }


  
  const endDialog = (campaign:Campaign,save:boolean) =>{
    save && api.briefController.createCampaign(campaign).then(response => {
      toastInt({
        severity: 'success',
        summary: DEFAULT_SUMMARY_SUCCESS(t),
        detail: t('Campaign was planned and ready to prepare the brief plan')
      });
      fetchData();
    }).catch(e => {
      toastInt({
        severity: 'error',
        summary: DEFAULT_SUMMARY_ERROR(t),
        detail: t('We are sorry but the information you provided is not complete to be able to plan a campaign')
      });
    })
    
    hidePlanningDialog(); 
  }

  return (
    <StandardLayout>
      <div className="p-p-6">
        <h1 className="page-title">{t('All Campaigns')}</h1>
        {isAdmin(user) && (
          <div className="p-d-flex p-flex-row p-align-center p-mt-6">
            <Button label={t('Start Planning')} icon="fas fa-clipboard" className="p-button-lg" onClick={showPlanningDialog} />
          </div>
        )}
        <div className="p-grid p-mt-3">
          <div className="p-col-12">
            <Card className={styles.userCard}>
              <DataTable value={campaigns} className="p-datatable-lg p-datatable-gridlines p-datatable-striped" columnResizeMode="fit" paginator rows={20}>
                <Column field="publicName" header={t('Public')} filter sortable />
                {isAdmin(user) && <Column field="privateName" header={t('Private')} filter sortable />}
                <Column field="status" header={t('Status')} body={c=>statusBodyTemplate(c,t)} filter sortable />
                <Column field="startDate" header={t('Start')} filter sortable />
                <Column field="endDate" header={t('End')} filter sortable />
                <Column field="brand" header={t('Brand')} filter sortable />
                <Column field="mainGoal" header={t('Goal')} filter sortable />
                <Column body={actionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
              </DataTable>
            </Card>
          </div>
        </div>
      </div>
      {isAdmin(user)&& (
      <EditCampaignDialog toast={toastInt} endCallback= {endDialog} show={planningDialog} initialState={emptyCampaign} />
      )}
    </StandardLayout>
  )
}
