import React, { ChangeEvent, useEffect, useState } from 'react';
import api from '../../../core/api';
import { Button } from 'primereact/button';
import { Campaign,Interest } from '../../../monaco-client';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';


export function Interests({setCurrentCampaign,currentCampaign}:{setCurrentCampaign:React.Dispatch<React.SetStateAction<Campaign>>,currentCampaign:Campaign}) {
    const { t } = useTranslation();
    const [interests, setInterests] = useState<Interest[]>([]);
    const [filteredInterests, setFilteredInterests] = useState<Interest[]>([]);
    const [interestSearch, setInterestSearch] = useState<string>("");
    
    
    useEffect(() => {
      if (interests.length === 0) {
        api.dataController.getInterests().then(response => {
          setInterests(response.data);
        });
      }
    }, [interests.length])

    const handleInterestSelection = (interest: Interest) => {
      let newValue: Interest[];
      let oldValue: Interest[] = [...currentCampaign.interests ?? []];
      if (oldValue.includes(interest)) {
        newValue = oldValue.filter(o => o.id !== interest.id);
      } else {
        newValue = [...oldValue, interest]
      }
      setCurrentCampaign({
        ...currentCampaign,
        interests: newValue
      })
    }
  
  
    const handleInterestSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInterestSearch(value);
      if (value === null || value === undefined || (typeof value === 'string' && value.trim().length === 0)) {
        setFilteredInterests(interests);
        return;
      }
      setFilteredInterests(interests.filter(interest => interest.name?.startsWith(value.toUpperCase())));
    }

    
  
    return (
    <>
    <div className="p-grid p-mt-6">
          <div className="p-md-12 p-mb-5 p-text-center">
            <span className="p-input-icon-right">
              <InputText placeholder={t('Search')} style={{ width: '300px' }} value={interestSearch} onChange={handleInterestSearch} />
              <i className="pi pi-search" />
            </span>
          </div>
          {filteredInterests.map(interest => (
            <div className="p-md-3" key={interest.id}>
              <Button className={(currentCampaign.interests && currentCampaign.interests.findIndex(i => i.id === interest.id) >= 0 ? '' : 'p-button-outlined') + ' w-100 p-button-lg'} label={interest.name} onClick={() => handleInterestSelection(interest)} />
            </div>
          ))}
        </div>
  </>
  );
  }