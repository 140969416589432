import React from 'react';
import { Campaign, Dashboard } from '../../../monaco-client';
import { Card } from 'primereact/card';
import styles from './CampaignPage.module.scss';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import _ from 'lodash';


export const SummaryResult = ({ campaign }: { campaign: Campaign | Dashboard }) => {

    const { t } = useTranslation();


    const calculateSentiment = (data: number) => {
        const sentiment: {
            positive?:number,
            neutral?:number,
            negative?:number
        } = campaign?.statistics ? campaign.statistics['sentiment'] : {};
        if (sentiment) {
            const { positive, neutral, negative } = sentiment;

            const total:number = (+(positive  || 0)) + (+(neutral as number|| 0)) + (+(negative as number|| 0));

            if (total === 0 || data === 0) {
                return 0;
            }

            return (data * 100) / total;
        } else return 0
    }

    return (<>
        <div className="p-col-4 p-d-flex p-flex-column">
            <Card className={styles.sentimentCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="far fa-grin-beam" />
                    <span className="p-text-primary p-mt-3">{Number(calculateSentiment(_.get(campaign, 'statistics.sentiment.positive', 0))).toFixed(0)}% {t('of people reached had a positive interaction')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
            <Card className={styles.sentimentCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="far fa-smile p-text-secondary" />
                    <span className="p-text-secondary p-mt-3">{Number(calculateSentiment(_.get(campaign, 'statistics.sentiment.neutral', 0))).toFixed(0)}% {t('were neutral with the content')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-4 p-d-flex p-flex-column">
            <Card className={styles.sentimentCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="far fa-frown-open" />
                    <span className="p-text-error p-mt-3">{Number(calculateSentiment(_.get(campaign, 'statistics.sentiment.negative', 0))).toFixed(0)}% {t('did not like the content')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-users" />
                    <span className="p-mt-3">{numeral(campaign.reach).format('0.0a')} {t('Reach')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-icons" />
                    <span className="p-mt-3">{numeral(campaign.engagement).format('0.00%')} {t('Engagement')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-thumbs-up" />
                    <span className="p-mt-3">{numeral(campaign.likes).format('0.0a')} {t('Likes')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-comments" />
                    <span className="p-mt-3">{numeral(campaign.comments).format('0.0a')} {t('Comments')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-eye" />
                    <span className="p-mt-3">{numeral(campaign.views).format('0.0a')} {t('Views')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-mouse-pointer" />
                    <span className="p-mt-3">{numeral(campaign.clicks).format('0.0a')} {t('Clicks')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-icons" />
                    <span className="p-mt-3">{numeral(campaign?.impression).format('0.0a')}  {t('Impressions')}</span>
                </div>
            </Card>
        </div>
        <div className="p-col-3 p-d-flex p-flex-column">
            <Card className={styles.kpiCard + ' p-d-flex p-flex-column p-justify-center'} style={{ flexGrow: 1 }}>
                <div className="p-d-flex p-flex-column p-align-center p-justify-center">
                    <i className="fas fa-share" />
                    <span className="p-mt-3">{numeral(campaign?.shares).format('0.0a')}  {t('Shares')}</span>
                </div>
            </Card>
        </div>

    </>)
}