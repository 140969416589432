import React, { useRef, useState } from 'react';
import { isAdmin } from '../../../core/utils';
import { Campaign, Profile, User } from '../../../monaco-client';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import styles from '../../shared/SummaryResult/CampaignPage.module.scss';
import { useTranslation } from 'react-i18next';
import api from '../../../core/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import ProfilePicker, { ProfilePickerRef } from '../../shared/ProfilePicker/ProfilePicker';
import { DEFAULT_SUMMARY_SUCCESS } from '../../../core/constants';
import CampaignContentPlanning from '../../shared/CampaignProfileChat/CampaignContentPlanning';


type BriefPlanPropertiesProp = {
    toast: (p: any) => void,
    campaign: Campaign,
    user?: User,
    setCampaign: React.Dispatch<React.SetStateAction<Campaign>>
}

export const BriefPlanProperties = ({ toast, campaign, user, setCampaign }: BriefPlanPropertiesProp) => {
    const { t } = useTranslation();
    const profilePickerRef = useRef<ProfilePickerRef>(null);
    const [campaignChatProfile, setCampaignChatProfile] = useState<Profile | null>(null);
    const [profilePicker, setProfilePicker] = useState(false);

    const briefActionBodyTemplate = (rowData: Profile) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-calendar" className="p-button-rounded p-button-outlined p-mr-2" onClick={() => setCampaignChatProfile(rowData)} />
            </React.Fragment>
        );
    }
    const deleteActionBodyTemplate = (rowData: Profile) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-mr-2" onClick={()=>handleProfileDeletion(rowData)} />
            </React.Fragment>
        );
    }

    const handleProfileDeletion = (profile:Profile) => {
        const updatedCampaign = {
            ...campaign,
            profiles: campaign.profiles.filter(cp=>cp.id !== profile.id)
        };

        api.briefController.updateCampaign(updatedCampaign).then(response => {
            setCampaign(response.data);
            toast({
                severity: 'success',
                summary: DEFAULT_SUMMARY_SUCCESS(t),
                detail: t('Selected profiles have been updated and added/removed to the campaign')
            })
        })
        setProfilePicker(false);
    }

    const handleProfileSelection = () => {
        const updatedCampaign = {
            ...campaign,
            profiles: [...profilePickerRef.current?.getSelectedProfiles() ?? []]
        };

        api.briefController.updateCampaign(updatedCampaign).then(response => {
            setCampaign(response.data);
            toast({
                severity: 'success',
                summary: DEFAULT_SUMMARY_SUCCESS(t),
                detail: t('Selected profiles have been updated and added/removed to the campaign')
            })
        })
        setProfilePicker(false);
    }


    return (<>

        <div className="p-col-12">
            <h1 className="page-title p-mt-5">{t('Brief Plan - Properties')}</h1>
            {isAdmin(user) && <div className="p-d-flex p-flex-row p-align-center p-mt-6 p-mb-3">
                <Button label={t('Search Profile')} icon="fas fa-search" className="p-button-secondary" onClick={() => setProfilePicker(true)} />
            </div>}

            <Card className={styles.noPaddingCard}>
                <DataTable value={campaign?.profiles?.filter(profile => isAdmin(user) || user?.id === profile?.user?.id)} className={'p-datatable-lg p-datatable-gridlines p-datatable-striped'} columnResizeMode="fit" paginator rows={20} scrollable scrollHeight="300px">
                    <Column field="name" header={t('Name')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="followers" header={t('Followers')} headerStyle={{ width: '150px' }} filter sortable />
                    <Column field="likes" header={t('Likes')} headerStyle={{ width: '150px' }} filter sortable />
                    <Column field="views" header={t('Views')} headerStyle={{ width: '150px' }} filter sortable />
                    <Column field="reach" header={t('Reach')} headerStyle={{ width: '150px' }} filter sortable />
                    <Column field="identification" header={t('Identification')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="social" header={t('Social')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column field="type" header={t('Type')} headerStyle={{ width: '200px' }} filter sortable />
                    <Column body={briefActionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />
                    {isAdmin(user) && ( <Column body={deleteActionBodyTemplate} style={{ width: '80px', textAlign: 'center' }} />)}
                </DataTable>
            </Card>
        </div>



        {isAdmin(user) && (
            <Dialog header={t('Campaign Planner')} onHide={() => setProfilePicker(false)} visible={profilePicker} modal style={{ width: '80vw' }} focusOnShow={false} footer={(
                <div className="p-d-flex p-flex-row p-align-center p-justify-end p-pt-4">
                    <Button label={t('Confirm')} icon="pi pi-check" onClick={handleProfileSelection} />
                </div>
            )}>
                <small>{t('Use the filters and the table to pick a publisher and connect it to the brief plan')}</small>
                <ProfilePicker ref={profilePickerRef} className="p-mt-5" defaultProfiles={campaign.profiles} />
            </Dialog>
        )}
        <Dialog header={t('Content Planning with') + ' ' + (campaignChatProfile && campaignChatProfile.name)} onHide={() => setCampaignChatProfile(null)} visible={campaignChatProfile !== null} modal style={{ width: '80vw' }} focusOnShow={false}>
            <CampaignContentPlanning toast={toast} campaign={campaign} profile={campaignChatProfile} />
        </Dialog>

    </>

    )
}