import React from "react";
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";
  
const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Container>
        <Row>
          Copyright NativeJ 
          <a href="https://www.nativej.com/privacy-policy/#terms" target="_blank">{t(' Terms & conditions ')}</a> / 
          <a href="https://www.iubenda.com/privacy-policy/8051423" target="_blank">{t(' Privacy Policy ')}</a> / 
          <a href="http://www.nativej.com/privacy-policy/#cookies" target="_blank">{t(' Cookie Policy ')}</a>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;