const config =  {
  apiURL: 'https://app.nativej.com/api',
  homepage: '/dashboard'
}

export default config




