import React, {useEffect, useRef} from 'react';
import MinimalLayout from '../../wrappers/MinimalLayout/MinimalLayout';
import {Card} from 'primereact/card';
import Logo from '../../shared/Logo/Logo';
import styles from './LoginPage.module.scss';
import {useTranslation} from 'react-i18next';
import SmartForm from '../../shared/SmartForm/SmartForm';
import * as Yup from 'yup';
import {showResponseToast, validationEmailRequired, validationStringRequired} from '../../../core/utils';
import {Button} from 'primereact/button';
import {Link, useHistory} from 'react-router-dom';
import api, { showResponseToastAxiosFailure, showResponseToastAxiosSuccess } from '../../../core/api';
import { useAppDispatch } from "../../../redux/hooks";
import {setToken, setUser} from '../../../redux/authSlice';
import { AuthRequest } from '../../../monaco-client';
import { ToastMessageType } from 'primereact/toast';
import { FormikProps } from 'formik';

type LoginPageProps= {
  toast:(t:ToastMessageType)=>void
}

export default function LoginPage({ toast }:LoginPageProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const smartForm = useRef<FormikProps<any>>(null);
  const history = useHistory();

  useEffect(() => {
    document.title = t('Login - NativeJ');
  }, [t]);

  const handleLogin = (values:AuthRequest, { setSubmitting}:{setSubmitting:(b:boolean)=>void}) => {
    setSubmitting(true);
    let prom = api.authController.login(values);
   
    prom.then(response => {
      dispatch(setToken(response.data.data?.token));
      dispatch(setUser(response.data.data?.user));
      //setSubmitting(false);
      showResponseToastAxiosSuccess(toast, response, t);
    },error => {
      showResponseToastAxiosFailure(toast, error, t,t('Login Failed'));
    });
  };

  return (
    <MinimalLayout>
      <Card style={{ width: '100%', maxWidth: '500px' }}>
        <div className={`${styles.formTitle} p-py-3 p-pl-3`}>
          <div className={`p-d-flex p-flex-row p-align-center`}>
            <h2 className="p-m-0 p-p-0 p-text-normal">{t('Welcome to')}</h2>
            <Logo className="p-ml-3" style={{ height: '30px' }} />
          </div>
          <small className="p-opacity-75 p-fs-4 p-d-block p-mt-1">{t('Start using our platform as a Publisher, Influencer or Advertiser')}</small>
        </div>

        <SmartForm
          ref={smartForm}
          className="p-mt-5"
          fields={[
            { key: 'email', label: t('Email address') },
            { key: 'password', label: t('Password'), inputType: 'password' }
          ]}
          validationSchema={Yup.object().shape({
            email: validationEmailRequired(t),
            password: validationStringRequired(t)
          })}
          initialValues = {{
            email:'',
            password:''
          }}
          onSubmit={handleLogin}
        />

        <div className="p-grid">
          <div className="p-md-6">
            <Button label={t('Sign In')} className="w-100 p-button-lg p-mt-5" onClick={() => smartForm?.current?.submitForm()} />
          </div>
          <div className="p-md-6">
            <Link to="/register">
              <Button label={t('Create Account')} className="w-100 p-button-lg p-button-outlined p-mt-5" />
            </Link>
          </div>
        </div>
      </Card>
    </MinimalLayout>
  )
}
