import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Campaign, EGoal } from '../../../monaco-client';
import {  getGoalTargetValue, withGoalTargetValue, withMainGoal, withSecondaryGoal } from "../../../core/campaingUtils";
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';

export function TargetGoal ({setCurrentCampaign,currentCampaign}:{setCurrentCampaign:React.Dispatch<React.SetStateAction<Campaign>>,currentCampaign:Campaign}) {
    const { t } = useTranslation();
    const handleMainGoalSelection = (selection: EGoal) => {
      setCurrentCampaign(withMainGoal(currentCampaign, selection));
    };
  
    const handleMainGoalValue = (e: InputNumberChangeParams) => {
      currentCampaign.mainGoal && setCurrentCampaign(withGoalTargetValue(currentCampaign, currentCampaign.mainGoal, e.value));
    };
  
    const handleSecondaryGoalSelection = (selection: EGoal) => {
      setCurrentCampaign(withSecondaryGoal(currentCampaign, selection));
    };
  
    const handleSecondaryGoalValue = (e: InputNumberChangeParams) => {
      currentCampaign.secondaryGoal && setCurrentCampaign(withGoalTargetValue(currentCampaign, currentCampaign.secondaryGoal, e.value));
    };
  
    function mainGoalButton(campaign: Campaign, label: string, key: EGoal) {
      return (<div className="p-md-4">
        <Button
          label={t(label)}
          className={(campaign.mainGoal === key ? '' : 'p-button-outlined' )+ ' p-button-lg'}
          style={{ width: '100%' }}
          onClick={() => handleMainGoalSelection(key)}
          disabled={("" + campaign.secondaryGoal) === ("" + key)} />
      </div>)
    }
    function secondaryGoalButton(campaign: Campaign, label: string, key: EGoal) {
      return (<div className="p-md-4">
        <Button
          label={t(label)}
          className={((campaign.secondaryGoal) === key ? '' : 'p-button-outlined') + ' p-button-lg'}
          style={{ width: '100%' }}
          onClick={() => handleSecondaryGoalSelection(key)}
          disabled={("" + campaign.mainGoal) === ("" + key)} />
      </div>)
    }
    return (<div>
      <h4 className="p-p-0 p-mt-6 p-mb-5 p-opacity-50 p-text-normal p-text-uppercase">{t('Main Goal')}</h4>
      <div className="p-grid">
        <div className="p-md-4">
          <label htmlFor='mainTargetValue' className="p-d-block">{t('Main Target Value')}</label>
          <InputNumber id='mainTargetValue' value={currentCampaign.mainGoal ? getGoalTargetValue(currentCampaign, currentCampaign.mainGoal) : 0} disabled={currentCampaign.mainGoal === null} style={{
            width: '100%'
          }} onChange={handleMainGoalValue} />
        </div>
      </div>
      <div className="p-grid">
        {mainGoalButton(currentCampaign, 'Likes', EGoal.Likes)}
        {mainGoalButton(currentCampaign, 'Comments', EGoal.Comments)}
        {mainGoalButton(currentCampaign, 'Views', EGoal.Views)}
        {mainGoalButton(currentCampaign, 'Reach', EGoal.Reach)}
        {mainGoalButton(currentCampaign, 'Engagement', EGoal.Engagement)}
        {mainGoalButton(currentCampaign, 'Clicks', EGoal.Clicks)}
        {mainGoalButton(currentCampaign, 'Impressions', EGoal.Impressions)}
        {mainGoalButton(currentCampaign, 'Shares', EGoal.Shares)}
      </div>
      <h4 className="p-p-0 p-mt-6 p-mb-5 p-opacity-50 p-text-normal p-text-uppercase">{t('Secondary Goal')}</h4>
      <div className="p-grid">
        <div className="p-md-4">
          <label htmlFor='secondaryTargetValue' className="p-d-block">{t('Secondary Target Value')}</label>
          <InputNumber id='secondaryTargetValue' disabled={currentCampaign.secondaryGoal === null} value={currentCampaign.secondaryGoal ? getGoalTargetValue(currentCampaign, currentCampaign.secondaryGoal) : 0} style={{
            width: '100%'
          }} onChange={handleSecondaryGoalValue} />
        </div>
      </div>
      <div className="p-grid">
        {secondaryGoalButton(currentCampaign, 'Likes', EGoal.Likes)}
        {secondaryGoalButton(currentCampaign, 'Comments', EGoal.Comments)}
        {secondaryGoalButton(currentCampaign, 'Views', EGoal.Views)}
        {secondaryGoalButton(currentCampaign, 'Reach', EGoal.Reach)}
        {secondaryGoalButton(currentCampaign, 'Engagement', EGoal.Engagement)}
        {secondaryGoalButton(currentCampaign, 'Clicks', EGoal.Clicks)}
        {secondaryGoalButton(currentCampaign, 'Impressions', EGoal.Impressions)}
        {secondaryGoalButton(currentCampaign, 'Shares', EGoal.Shares)}
      </div>
    </div>);
  }